import _ from 'lodash';
import appStore from '@stores/app';
import { deleteDocument, newTempDocID, setDocument } from '@lib/firebaseHandler';

const collection = 'storiesTags';

export const deleteStoriesTags = async params => {
    const joins = appStore.joins.relationships || [];

    const updatedStoriesTags = joins.storiesTags.filter(storyTag => {
        return storyTag.storiesTagsId !== params.id;
    });

    const results = { ...joins, storiesTags: updatedStoriesTags };

    const data = {
        id: params.id
    };
    await deleteDocument(data, collection);
    await setDocument(results, 'joins', 'relationships');
};

export const addStoriesTags = async params => {
    const tempId = newTempDocID(collection);

    if (_.isNil(params.id)) {
        params.id = tempId.id;
    }

    const data = {
        name: params.name,
        id: params.id
    };
    await setDocument(data, collection);
};

export const updateStoriesTags = async params => {
    const joins = appStore.joins.relationships || [];

    const updatedStoriesTags = joins.storiesTags.map(storyTag => {
        if (storyTag.storiesTagsId === params.id) {
            return {
                ...storyTag,
                storiesTagsName: params.name
            };
        } else {
            return storyTag;
        }
    });

    const results = { ...joins, storiesTags: updatedStoriesTags };

    await setDocument(params, collection, params.id);
    await setDocument(results, 'joins', 'relationships');
};
