import axios from 'axios';
import firebase from 'firebase';
import notify from '@lib/notifications';
import React, { useState } from 'react';
import { Button } from 'antd';

//TODO create a reusable function to call Firebase Functions from app and move this there

const functions = firebase.app().functions('europe-west1');
// functions.useFunctionsEmulator('http://localhost:5001'); // make sure port is the right one

const DownloadDataButton = ({ id = null, functionID, dataName }) => {
    const [loading, setLoading] = useState(false);

    const getUrl = async () => {
        const callFunction = functions.httpsCallable(functionID, { timeout: 600000 });

        const filename = `${dataName.replace(/ /g, '_')}_${Date.now()}`;

        try {
            setLoading(true);
            const response = await callFunction({ testId: id });
            axios.get(response.data).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${filename}.csv`);
                document.body.appendChild(link);
                link.click();
            });
            setLoading(false);
        } catch (e) {
            notify('error', 'surveyDownload');
            setLoading(false);
        }
    };

    return (
        <Button type="primary" loading={loading} onClick={getUrl} style={{ margin: '20px auto', width: 300 }}>
            Generate {dataName} Data {id && `(${id})`}
        </Button>
    );
};

export default DownloadDataButton;
