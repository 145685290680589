import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { makeObservable, observable } from 'mobx';

import { observer } from 'mobx-react';

const EditIconButton = props => {
    return (
        <Button
            icon={<EditOutlined />}
            ghost
            type="normal"
            shape="circle"
            style={{ color: 'rgba(0, 0, 0, 0.85)', border: 'none' }}
            onClick={props.onClick}
        />
    );
};

@observer
class DeleteIconButton extends Component {
    @observable onDeleteVisible = false;

    onShowModal = location => {
        if (location === 'delete') {
            this.onDeleteVisible = true;
        }
    };

    onCloseModal = location => {
        if (location === 'delete') {
            this.onDeleteVisible = false;
        }
    };

    onOkModal = location => {
        if (location === 'delete') {
            this.props.onClick();
            this.onDeleteVisible = false;
        }
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <>
                <Button
                    icon={<DeleteOutlined />}
                    ghost
                    onClick={() => (this.props.noModal ? this.props?.onClick?.() : this.onShowModal('delete'))}
                    shape="circle"
                    style={{ color: '#f00', border: 'none' }}
                />
                <Modal
                    visible={this.onDeleteVisible}
                    onOk={() => this.onOkModal('delete')}
                    onCancel={() => this.onCloseModal('delete')}
                    centered={true}
                    width={240}
                    closable={false}
                    destroyOnClose={true}
                >
                    <p>Are you sure?</p>
                </Modal>
            </>
        );
    }
}

export { EditIconButton, DeleteIconButton };
