import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';
import { Layout, Row, Table } from 'antd';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import GoalsEdit from '@screens/Content/Categories/Goals/GoalsEdit';
import GoalsOrder from '@screens/Content/Categories/Goals/GoalsOrder';
import GoalsSessions from '@screens/Content/Categories/Goals/GoalsSessions';
import { deleteGoal } from '@stores/middleware/goals';
import { DeleteIconButton } from '@components/Buttons';
import { DiffOutlined, OrderedListOutlined } from '@ant-design/icons';
import { ViewHeader } from '@components/ViewHeader';

@observer
class GoalsView extends Component {
    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            width: 90,
            key: 'editSessions',
            render: (text, record) => (
                <GoalsSessions
                    goal={record}
                    textButton={'Edit Sessions'}
                    iconButton={<DiffOutlined />}
                    type={'secondary'}
                    title={'Edit Sessions'}
                />
            )
        },
        {
            width: 90,
            key: 'edit',
            render: (text, record) => (
                <Row style={{ display: 'flex' }}>
                    <GoalsEdit content={record} table icon title={'Edit Goal'} />
                    <DeleteIconButton
                        onClick={() => {
                            deleteGoal(record, appStore.goals);
                        }}
                    />
                </Row>
            )
        }
    ];

    render() {
        if (
            !appStore.joins.relationships ||
            !appStore.categories.length ||
            !appStore.sessions.length ||
            !appStore.goals.length
        ) {
            return null;
        }

        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <ViewHeader>
                    <GoalsOrder
                        textButton={'Order Goals'}
                        type={'secondary'}
                        iconButton={<OrderedListOutlined />}
                        title={'Order Goals'}
                    />
                    <GoalsEdit textButton={'New Goal'} title={'Add Goal'} />
                </ViewHeader>
                <Table
                    bordered
                    size="small"
                    dataSource={appStore.goals}
                    rowKey={record => record.id}
                    columns={this.columns}
                />
            </Layout>
        );
    }
}

export default Form.create()(GoalsView);
