import _ from 'lodash';
import { observable } from 'mobx';

import { defaultOptions, definedComponents } from '@lib/widgetsConfig';

import appStore from '../app';
import { collectionStore } from '../collection';
import { sessionStore } from '../session';

// use this to separate defaultOptions (like id, location, etc) from particular data  of a widget
const componentProps = _.chain(defaultOptions).map('key').concat(['componentType', 'order', 'data']).value();

const dataToSelectValues = data => {
    return _.chain(data)
        .map(({ id, name }) => {
            return { value: id, label: name };
        })
        .sortBy('label')
        .value();
};

const widgetsStore = observable({
    inactiveIndexStart: 1000,

    sortedComponents: [],

    get activeComponents() {
        return _.chain(appStore.widgets)
            .filter(item => {
                return item.active && !item.data?.isStatic;
            })
            .sortBy('order')
            .value();
    },
    get staticComponents() {
        return _.chain(appStore.widgets)
            .filter(item => {
                return item.active && item.data?.isStatic;
            })
            .value();
    },
    get inactiveComponents() {
        return _.chain(appStore.widgets)
            .filter(item => {
                return item.active !== true; //case where  active can be missing
            })
            .sortBy('order')
            .value();
    },

    saveWidget(widget) {
        // we separate defaultOptions from the widget's particular props
        const out = {
            ..._.pick(widget, componentProps),
            data: _.omit(widget, componentProps),
            active: widget.active || false
        };

        appStore.updateWidget(out);
    },
    deleteWidget(widget) {
        appStore.deleteWidget(widget);
    },

    updateOrder(data) {
        // save new order for the active one, passed by the sort ui
        _.each(data, (item, idx) => {
            item.order = idx;
            this.saveWidget(item);
        });
        // save new order for inactive ones
        _.each(this.inactiveComponents, (item, idx) => {
            item.order = this.inactiveIndexStart + idx;
            this.saveWidget(item);
        });
    },

    get availableComponents() {
        return _.map(definedComponents, item => {
            _.each(item.options, option => {
                if (option.valuesType === 'allWellbeing') {
                    option.options = dataToSelectValues(sessionStore.allWellbeing);
                }
                if (option.valuesType === 'allStories') {
                    option.options = dataToSelectValues(sessionStore.allStories);
                }

                if (option.valuesType === 'allSessions') {
                    option.options = dataToSelectValues(sessionStore.allSessions);
                }

                if (option.valuesType === 'allCollections') {
                    option.options = dataToSelectValues(collectionStore.allCollections);
                }
            });
            return { name: item.componentType, id: item.componentType, ...item }; // we need to return a temporary id
        });
    },
    get availableComponentsAdvancedFiltered() {
        return appStore.advancedMode
            ? this.availableComponents
            : _.chain(this.availableComponents)
                  .filter(component => !component.advanced)
                  .sortBy('name')
                  .value();
    }
});

export { widgetsStore };
