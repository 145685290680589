import React, { useState } from 'react';

import _ from 'lodash';
import { Radio } from 'antd';

const EmRadio = ({ onSelect, values, style, checked }) => {
    const [selectedValue, setSelectedValue] = useState(checked);

    const onChange = e => {
        setSelectedValue(e.target.value);
        onSelect(_.find(values, v => v.id === e.target.value));
    };

    return (
        <div style={style}>
            <Radio.Group
                onChange={onChange}
                value={selectedValue}
                style={_.size(values) > 3 ? { display: 'flex', flexDirection: 'column' } : {}}
            >
                {values.map(item => (
                    <Radio key={item.id || item.value} value={item.id || item.value}>
                        {item.name || item.label}
                    </Radio>
                ))}
            </Radio.Group>
        </div>
    );
};

export { EmRadio };
