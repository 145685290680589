import React from 'react';
import { Button, Divider, Row } from 'antd';

import ModalWrapper from '@components/ModalWrapper';
import { referralSwitchActive } from '@stores/middleware/referrals';

const ReferralsCodeDetails = ({ record, linkSetupConfig }) => {
    const step = {
        fontWeight: 'bold',
        paddingTop: 5,
        paddingBottom: 10
    };
    const stepInfo = {
        paddingLeft: 20
    };

    return (
        <>
            <div style={step}>Go to:</div>
            <a rel="noopener noreferrer" target="_blank" href={linkSetupConfig.setupLink}>
                {linkSetupConfig.setupLink}
            </a>
            <Divider />
            <div style={step}>Step 1.</div>
            <div style={stepInfo}>SHORT LINK / : {record.code}</div>
            <div style={step}>Step 2.</div>
            <div style={stepInfo}>DEEP LINK : {linkSetupConfig.webLink + record.code}</div>
            <div style={stepInfo}>NAME : {record.code}</div>
            <div style={step}>Step 3.</div>
            <div style={stepInfo}>
                <ul>
                    <ol>Open the deep link in your iOS App</ol>
                    <ol>Select iOS version</ol>
                    <ol>App Store page for your app</ol>
                </ul>
            </div>
            <div style={step}>Step 4.</div>
            <div style={stepInfo}>
                <ul>
                    <ol>Open the deep link in your iOS App</ol>
                    <ol>Select Android version</ol>
                    <ol>App Store page for your app</ol>
                </ul>
            </div>
            <div style={step}>Step 5.</div>
            <div style={stepInfo}>Leave Default Options</div>
            <Divider />
            <div style={step}>Test Button after setting up in firebase</div>
            <Row type="flex" align="middle">
                <Button href={linkSetupConfig.deepLink + record.code} target="_blank" style={{ marginRight: 20 }}>
                    Test
                </Button>
                Test button link should redirect to {linkSetupConfig.webLink + record.code}
            </Row>
            <Divider />
            {!record.active && <div style={step}>If test went well, then Activate</div>}
            <Row type="flex" align="middle">
                {!record.active ? (
                    <>
                        <Button type="primary" onClick={() => referralSwitchActive(record)} style={{ marginRight: 20 }}>
                            Activate
                        </Button>
                        Current Status : Inactive
                    </>
                ) : (
                    <>Current Status : Active</>
                )}
            </Row>
        </>
    );
};

export default ModalWrapper(ReferralsCodeDetails);
