import _ from 'lodash';
import React from 'react';
import { Col, Row } from 'antd';

const ViewHeader = ({ justify, children, input }) => {
    return (
        <Row justify={justify || input ? 'space-between' : 'end'} style={{ padding: '20px 0' }}>
            {input && <Col span={12}>{input}</Col>}
            {children && _.isArray(children) ? (
                <Row flex={'auto'}>
                    {children.map((child, i) => (
                        <Col key={i} style={{ paddingLeft: 16 }}>
                            {child}
                        </Col>
                    ))}
                </Row>
            ) : (
                <Row flex={'auto'}>
                    <Col>{children}</Col>
                </Row>
            )}
        </Row>
    );
};

export { ViewHeader };
