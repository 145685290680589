import React, { Component } from 'react';

import { Button, Layout, Modal } from 'antd';
import { EditIconButton } from './Buttons';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { PlusCircleOutlined } from '@ant-design/icons';

const ModalWrapper = WrappedComponent => {
    @observer
    class ModalWrapperContent extends Component {
        @observable visible = false;

        showModal = () => {
            this.visible = true;
        };

        closeModal = () => {
            this.visible = false;
        };

        openModalButton = () => {
            if (this.props.table) {
                if (this.props.iconButton) {
                    return (
                        <Button
                            icon={this.props.iconButton}
                            shape="circle"
                            style={{ border: 'none' }}
                            onClick={this.showModal}
                        />
                    );
                } else {
                    return <EditIconButton onClick={this.showModal} />;
                }
            } else {
                return (
                    <div style={this.props.style}>
                        <Button
                            type={this.props.type || 'primary'}
                            icon={this.props.iconButton || <PlusCircleOutlined />}
                            onClick={this.showModal}
                        >
                            {this.props.textButton || 'Open Modal'}
                        </Button>
                    </div>
                );
            }
        };

        constructor(props) {
            super(props);
            makeObservable(this);
        }

        render() {
            return (
                <>
                    {this.openModalButton()}
                    <Layout style={{ backgroundColor: '#fff' }}>
                        <Modal
                            title={this.props.title || 'Title'}
                            visible={this.visible}
                            destroyOnClose
                            keyboard={true}
                            closable={true}
                            width={this.props.width || 1000}
                            zIndex={10}
                            okButtonProps={this.props.okButtonProps}
                            okText={this.props.okText || 'Ok'}
                            onCancel={this.closeModal}
                            footer={null}
                        >
                            {this.visible && <WrappedComponent {...this.props} closeModal={this.closeModal} />}
                        </Modal>
                    </Layout>
                </>
            );
        }
    }
    return ModalWrapperContent;
};

export default ModalWrapper;
