import React from 'react';

import { deleteInitialSessions, getInitialSessions, updateInitialSessions } from '@stores/middleware/initialSessions';
import { Layout, Row } from 'antd';
import { observer } from 'mobx-react';

import appStore from '@stores/app';

import { EmAutoComplete } from '@components/EmAutoComplete';
import { EmTable } from '@components/EmTable';
import { ViewHeader } from '@components/ViewHeader';

const InitialSessionsView = observer(() => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        }
    ];

    const onSelect = data => {
        updateInitialSessions(data.id);
    };

    const onDelete = data => {
        deleteInitialSessions(data.id);
    };

    return (
        <Layout style={{ backgroundColor: '#fff' }}>
            <ViewHeader input={<EmAutoComplete values={appStore.sessions} onSelect={onSelect} />} />
            <EmTable data={getInitialSessions()} onDelete={onDelete} columns={columns} />
        </Layout>
    );
});

export { InitialSessionsView };
