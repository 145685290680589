import React from 'react';

import _ from 'lodash';
import { observer, useLocalObservable } from 'mobx-react';

import { EmSelect } from '@components/EmSelect';
import { OrderListItemsModal } from '@components/OrderListItems/OrderListItems';
import { sessionStore } from '@stores/session';

const SessionSelector = observer(props => {
    const { onChange, mode, value, contentType } = props;

    const selectSessions = type => {
        switch (type) {
            case 'allStories':
                return sessionStore.allStories;
            case 'allWellbeing':
                return sessionStore.allWellbeing;
            default:
                return sessionStore.allSessions;
        }
    };

    const state = useLocalObservable(() => {
        return {
            values: selectSessions(contentType),
            selected: value,
            get valuesToOrder() {
                return _.map(this.selected, item => _.find(this.values, { id: item }));
            }
        };
    });

    const onValuesChange = values => {
        state.selected = values;
        onChange(values);
    };

    const valuesToIds = values => {
        const ids = _.map(values, v => v.id);
        onValuesChange(ids);
    };

    return (
        <>
            <EmSelect handleChange={onValuesChange} values={state.values} mode={mode} value={state.selected} />
            {_.size(state.selected) > 1 && (
                <OrderListItemsModal items={state.valuesToOrder} arrayOrder onlyIds onOrderUpdate={valuesToIds} />
            )}
        </>
    );
});

export { SessionSelector };
