import React, { Component } from 'react';

import { Tabs } from 'antd';

import SessionsView from './Sessions/SessionsView';
import StoriesFiltersView from './StoriesFilters/StoriesFiltersView';
import StoriesOrder from '@screens/Content/Sessions/StoriesOrder/StoriesOrder';
import StoriesTagsView from './StoriesTags/StoriesTagsView';
import { InitialSessionsView } from './InitialSessions/InitialSessionsView';

const { TabPane } = Tabs;

class SessionsScreen extends Component {
    render() {
        return (
            <Tabs defaultActiveKey="sessions" animated={false} type="card">
                <TabPane tab="Sessions" key="sessions">
                    <SessionsView />
                </TabPane>
                <TabPane tab="Initial Sessions" key="initialSessions">
                    <InitialSessionsView />
                </TabPane>
                <TabPane tab="Stories Tags" key="storiestags">
                    <StoriesTagsView />
                </TabPane>
                <TabPane tab="Stories Filters" key="storiesfilters">
                    <StoriesFiltersView />
                </TabPane>
                <TabPane tab="Stories Order" key="storiesorder">
                    <StoriesOrder />
                </TabPane>
            </Tabs>
        );
    }
}

export default SessionsScreen;
