import React, { useEffect } from 'react';

import _ from 'lodash';
import move from 'lodash-move';
import { Button, List, Modal } from 'antd';
import { observer, useLocalObservable } from 'mobx-react';
import { OrderedListOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const ItemRow = observer(props => {
    return (
        <List.Item
            style={{
                zIndex: 1000,
                cursor: 'move',
                height: 40
            }}
        >
            {props.value.name}
            {props.isContent &&
                (props.value.isFree ? (
                    <strong>- FREE {props.value.contentType ? props.value.contentType : 'collection'}</strong>
                ) : (
                    <strong>{props.value.contentType ? `- ${props.value.contentType}` : '- collection'}</strong>
                ))}
        </List.Item>
    );
});

// This component receives an array of objects 'props.items' with 2 necessary values: 'id', 'name' [{id, name}]
// 'order' is optionally in the source array, will add it if doesn't exist and respect if exists
// Returns ordered values [{...item, id, name, order: N}] in 'props.onChange'

const SortableItem = SortableElement(props => {
    return <ItemRow value={props.value} isContent={props.isContent} />;
});

const SortableList = SortableContainer(props => {
    const orderedItems = !props.arrayOrder
        ? _.orderBy(props.items, props.orderTag ? props.orderTag : 'order')
        : props.items;

    return (
        <List bordered>
            {orderedItems.map((item, index) => {
                return <SortableItem key={item.id} index={index} value={item} {...props} />;
            })}
        </List>
    );
});

const OrderListItems = observer(props => {
    const { items, onChange, ...rest } = props;

    const state = useLocalObservable(() => ({
        items,
        get sortedItems() {
            return _.map(this.items);
        }
    }));

    useEffect(() => {
        state.items = items;
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        state.items = move(state.sortedItems, oldIndex, newIndex);
        if (!props.arrayOrder) {
            _.each(state.items, (item, idx) => {
                props.orderTag ? (state.items[idx][props.orderTag] = idx + 1) : (state.items[idx].order = idx + 1);
            });
        }
        onChange(state.sortedItems);
    };

    return <SortableList items={state.sortedItems} onSortEnd={onSortEnd} {...rest} />;
});

//TODO move this export to bottom and refactor all the imports
export const OrderListItemsModal = observer(props => {
    const { items, orderName, orderId, onOrderUpdate, ...rest } = props;

    const state = useLocalObservable(() => ({
        showModal: false,
        items
    }));

    const onOpen = () => {
        state.showModal = true;
        state.items = items;
    };

    const onDataChange = changedData => (state.items = changedData);

    return (
        <>
            <Button icon={<OrderedListOutlined />} onClick={onOpen}>
                Order {orderName}
            </Button>
            <Modal
                title={'Order ' + orderName}
                visible={state.showModal}
                onOk={() => {
                    onOrderUpdate(state.items);
                    state.showModal = false;
                }}
                onCancel={() => {
                    state.showModal = false;
                    state.items = items;
                }}
            >
                <OrderListItems items={state.items} onChange={onDataChange} orderTag={orderId} {...rest} />
            </Modal>
        </>
    );
});

export default OrderListItems;
