import { Modal } from 'antd';
import { notifications } from './constants';

const notify = (type, location) => {
    if (type === 'error') {
        Modal.error(notifications[type][location]);
    } else {
        Modal.success(notifications[type][location]);
    }
};

export default notify;
