import React, { Component } from 'react';

import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import UploadFileRow from '@components/ImageUpload/UploadFileRow';

@observer
class ImageUpload extends Component {
    @observable image = this.props.image || null;

    changeSourceImage = image => {
        this.image = image;
        this.props.setImage(image);
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <UploadFileRow onChange={this.changeSourceImage} />
                {this.image && <img src={this.image} style={{ maxWidth: 210, margin: '0 20px' }} />}
            </div>
        );
    }
}

export default ImageUpload;
