import _ from 'lodash';
import appStore from '../app';
import { defaultAgeBands, defaultStoriesGroups, definedGroups } from '@lib/contentConfig';
import { observable } from 'mobx';
import { sessionStore } from '../session';

const path = 'groups';

const dataToSelectValues = (data, addGrouptype) => {
    return _.map(data, ({ id, name, groupType }) => {
        const fullName = addGrouptype ? `${name} (${groupType})` : name;
        return { value: id, name: fullName, key: id, id };
    });
};

const groupsStore = observable({
    advanced: false,
    get allGroupsFormsFields() {
        return _.map(definedGroups, group => {
            _.each(group.options, option => {
                if (option.valuesType === 'allSessions') {
                    option.options = dataToSelectValues(sessionStore.allSessions);
                }
                if (option.valuesType === 'allWellbeing') {
                    option.options = dataToSelectValues(sessionStore.allWellbeing);
                }
                if (option.valuesType === 'allStories') {
                    option.options = dataToSelectValues(sessionStore.allStories);
                }
                if (option.groupType) {
                    option.widgetProps = {
                        ...option.widgetProps,
                        options: dataToSelectValues(_.filter(this.allGroupsData, { groupType: option.groupType }))
                    };
                }
                if (option.id === 'id') {
                    option.widgetProps.disabled = !appStore.advancedMode;
                }
            });

            return { name: group.componentType, id: group.componentType, ...group };
        });
    },
    get allGroupsFormsFieldsIds() {
        return _.map(definedGroups, group => group.groupType);
    },
    getGroupOptions(id) {
        // TODO ugly fix, use case when we need to rename the variable (store collections as relatedCollections)
        if (id === 'relatedCollections') {
            return this.getByType('collections');
        } else if (id === 'allGroups') {
            const data = _.chain(this.allGroupsData)
                .filter(group => group.name)
                .filter(
                    group =>
                        !_.includes(
                            ['wellbeingNavigationTags', 'storiesNavigationTags', 'collections'],
                            group.groupType
                        )
                )
                .value();
            return dataToSelectValues(data, true);
        } else {
            return this.getByType(id);
        }
    },
    get allGroupsData() {
        return _.orderBy(appStore.groups, [group => group.name?.toLowerCase()], ['asc']);
    },
    getByType(groupType) {
        if (groupType === 'allGroups') {
            return this.allGroupsData;
        } else {
            return _.filter(this.allGroupsData, { groupType });
        }
    },
    getById(groupId) {
        return _.find(this.allGroupsData, { id: groupId });
    },
    getByContainSessionId(sessionId) {
        return _.filter(this.allGroupsData, group => _.includes(group.sessions, sessionId));
    },
    getForSelectForm(groupId) {
        const data = this.getByType(groupId);
        return dataToSelectValues(data);
    },
    filterGroupByTypeValue(groupType, type, value) {
        const groups = this.getAllGroupsByType(groupType);
        return _.filter(groups, g => {
            return _.includes(g.groups?.[type], value);
        });
    },
    get allGroupsByGroupType() {
        return _.groupBy(definedGroups, 'groupType');
    },
    get groupTypes() {
        return _.keys(this.allGroupsByGroupType);
    },
    addSessionToGroup(sessionId, groupId) {
        const groupData = this.getById(groupId);
        if (groupData) {
            this.saveGroup({
                ...groupData,
                sessions: groupData.sessions ? _.uniq([...groupData.sessions, sessionId]) : [sessionId]
            });
        }
    },
    removeSessionFromSameGroupType(sessionId, groupId) {
        const groupData = this.getById(groupId);
        if (groupData) {
            const groupsWithSession = _.chain(this.allGroupsData)
                .filter({ groupType: groupData.groupType })
                .filter(group => _.includes(group.sessions, sessionId))
                .value();

            _.forEach(groupsWithSession, group => {
                this.saveGroup({
                    ...group,
                    sessions: _.filter(group.sessions, session => session !== sessionId)
                });
            });
        }
    },
    async saveGroup(group) {
        return await appStore.updateDocument(path, group);
    },
    deleteGroup(group) {
        _.forEach(this.allGroupsData, groupData => {
            const currentGroup = groupData.groups?.[group.groupType];
            if (currentGroup) {
                if (_.includes(currentGroup, group.id)) {
                    const removeGroupId = _.filter(currentGroup, g => g !== group.id);
                    this.saveGroup({ ...groupData, groups: { ...group.groups, [group.groupType]: removeGroupId } });
                }
            } else if (_.isArray(groupData.groups)) {
                if (_.includes(groupData.groups, group.id)) {
                    const removeGroupId = _.filter(groupData.groups, g => g !== group.id);
                    this.saveGroup({ ...groupData, groups: removeGroupId });
                }
            }
        });
        appStore.deleteDocument(path, group);
    },
    deleteSessionFromGroups(sessionId) {
        const groupsWithSession = _.filter(this.allGroupsData, group => _.includes(group.sessions, sessionId));
        _.forEach(groupsWithSession, group => {
            this.saveGroup({ ...group, sessions: _.filter(group.sessions, session => session !== sessionId) });
        });
    },
    //CLEAN AFTER MIGRATION FROM HERE TO BOTTOM
    importFromMain({ groupType, databaseName }) {
        const data = appStore[databaseName];

        if (data) {
            _.forEach(data, item => {
                _.mapKeys(item, (value, key) => {
                    const isGroup = !!_.includes(this.groupTypes, key);
                    if (isGroup && item[key]) {
                        if (!item.groups) {
                            item.groups = {};
                        }
                        item.groups[key] = value;
                        delete item[key];
                    }
                });
                this.saveGroup({ groupType, ...item });
            });
        }
    },
    addSessionsToGroup(sessionsIds, groupId) {
        const groupData = this.getById(groupId);
        if (groupData) {
            this.saveGroup({
                ...groupData,
                sessions: sessionsIds
            });
        }
    },
    addDefaultGroups() {
        _.forEach(defaultStoriesGroups, defaultGroup => {
            if (_.size(defaultGroup[2]) > 1) {
                const groupData = this.getById(defaultGroup[3]);
                if (!groupData) {
                    this.saveGroup({ groupType: defaultGroup[0], name: defaultGroup[1], id: defaultGroup[2] });
                }
            } else {
                const groupData = _.find(this.allGroupsData, { groupType: defaultGroup[0], name: defaultGroup[1] });
                if (!groupData) {
                    this.saveGroup({ groupType: defaultGroup[0], name: defaultGroup[1] });
                }
            }
        });
    },
    addDefaultAgeBands() {
        _.forEach(defaultAgeBands, ageBand => {
            const groupData = _.find(this.allGroupsData, { groupType: 'ageBands', name: ageBand[0] });
            if (!groupData) {
                this.saveGroup({ groupType: 'ageBands', name: ageBand[0], startAge: ageBand[1], endAge: ageBand[2] });
            }
        });
    }
});

export { groupsStore };
