import { callFirebaseFunction } from './firebaseFunctions';

const getRevenueCatUser = async ({ userId }) => {
    try {
        const { data } = await callFirebaseFunction('revenueCatGetUser', { userId });
        return data;
    } catch (error) {
        return error;
    }
};

const setRevenueCatFreeTrial = async ({ userId, duration }) => {
    try {
        const { data } = await callFirebaseFunction('revenueCatGrantFreeTrial', { userId, duration });
        return data;
    } catch (error) {
        return error;
    }
};

export { getRevenueCatUser, setRevenueCatFreeTrial };
