import React, { Component } from 'react';

import { Layout } from 'antd';
import { observer } from 'mobx-react';

import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './App.css';

import Login from './Screens/Login';
import Main from './Screens/Main';

import appStore from './Stores/app';

function NoMatch() {
    return (
        <div>
            <h3>Not found</h3>
        </div>
    );
}

@observer
class App extends Component {
    // initialize firestorter variables so we can use loaded store
    // (needed to get data from store outside render)
    // added StoriesFilters bc had this issue that had to reload to have them
    // TODO solve this
    init = () => {
        const initStoriesTags = appStore.storiesTags;
        const initJoins = appStore.joins;
        const initGoals = appStore.goals;
        const initStoriesFilters = appStore.storiesFilters;
    };

    render() {
        if (appStore.loading) {
            return null;
        }

        this.init();
        return (
            <Router>
                <Layout>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            component={props => (
                                <Redirect
                                    to={{
                                        pathname: appStore.user ? '/main' : '/login',
                                        state: { from: props.location }
                                    }}
                                />
                            )}
                        />
                        <Route
                            path="/login"
                            component={props => {
                                return appStore.user ? (
                                    <Redirect
                                        to={{
                                            pathname: '/main',
                                            state: { from: props.location }
                                        }}
                                    />
                                ) : (
                                    <Login />
                                );
                            }}
                        />
                        <Route
                            exact
                            path="/main"
                            component={props => (
                                <Redirect
                                    to={{
                                        pathname: '/main/wellbeing',
                                        state: { from: props.location }
                                    }}
                                />
                            )}
                        />
                        {appStore.user && <Route path="/main/:sub" component={Main} />}
                        {!appStore.user && (
                            <Route
                                path="/main/:sub"
                                component={props => (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: props.location }
                                        }}
                                    />
                                )}
                            />
                        )}

                        <Route component={NoMatch} />
                    </Switch>
                </Layout>
            </Router>
        );
    }
}

export default App;
