import React from 'react';

import { Tabs } from 'antd';

import { ReferralCampaignsView } from './ReferralCampaigns/ReferralCampaignsView';
import { ReferralCodesView } from './ReferralCodes/ReferralCodesView';
import { ReferralEventsView } from './ReferralEvents/ReferralEventsView';
import { ReferralMigration } from './ReferralMigration/ReferralMigration';
import { ReferralProductsView } from './ReferralProducts/ReferralProductsView';

const { TabPane } = Tabs;

const ReferralsScreen = () => {
    return (
        <Tabs defaultActiveKey="campaigns" animated={false} type="card">
            <TabPane tab="Campaigns" key="campaigns">
                <ReferralCampaignsView />
            </TabPane>
            <TabPane tab="Codes" key="codes">
                <ReferralCodesView />
            </TabPane>
            <TabPane tab="Products" key="products">
                <ReferralProductsView />
            </TabPane>
            <TabPane tab="Events" key="events">
                <ReferralEventsView />
            </TabPane>
            {/*
            Migrated on 2.10, should not be used again, delete when is safe
            <TabPane tab="Migration" key="migration">
                <ReferralMigration />
            </TabPane> */}
        </Tabs>
    );
};

export { ReferralsScreen };
