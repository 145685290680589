import _ from 'lodash';
import React from 'react';

import appStore from '@stores/app';
import ModalWrapper from '@components/ModalWrapper';

// first simple implementation, this will be imporoved based in needs in future versions

function ReferralsCodeStats({ record }) {
    const codeDetails = _.find(appStore.referralCodesActivity, { code: record.code });

    if (codeDetails?.referredTo) {
        return (
            <ul>
                {codeDetails.referredTo.map(ref => (
                    <li key={ref.uid}>
                        Type: <strong>{ref.type}</strong>
                        <br />
                        uid: {ref.uid}
                        {ref.history && (
                            <>
                                <br />
                                {ref.history.map((refHist, i) => (
                                    <span key={refHist.timestamp + i}>{refHist.type} </span>
                                ))}
                            </>
                        )}
                    </li>
                ))}
            </ul>
        );
    } else {
        return <>No stats</>;
    }
}

export default ModalWrapper(ReferralsCodeStats);
