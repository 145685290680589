import { fbase } from '@lib/firebase';

const firestore = fbase.app.firestore();
const getTimestamp = fbase.app.firestore.Timestamp.now().toMillis();

firestore.settings({ ignoreUndefinedProperties: true });

const newTempDocID = collection => {
    return firestore.collection(collection).doc();
};

const getDocument = async (collection, doc) => {
    try {
        const document = await firestore.collection(collection).doc(doc).get();

        return document.data();
    } catch (error) {
        throw new Error(error);
    }
};

const setDocument = async (params, collection, id = params.id) => {
    try {
        params.timestamp = getTimestamp;
        const doc = firestore.doc(`${collection}/${id}`);
        await doc.set(params);
    } catch (e) {
        //TODO need to ue notifications module with params
        alert(e.message);
    }
};

const updateDocument = async (params, collection, id = params.id) => {
    try {
        params.timestamp = getTimestamp;
        const doc = firestore.doc(`${collection}/${id}`);

        await doc.update(params);
    } catch (e) {
        alert(e.message);
    }
};

const deleteDocument = async (params, collection, id = params.id) => {
    try {
        const doc = firestore.doc(`${collection}/${id}`);
        await doc.delete();
    } catch (e) {
        alert(e.message);
    }
};

const saveImageToStorage = async (params, collection) => {
    try {
        const ref = fbase.storage.ref().child(`${collection}/${params.id}/${params.name}`);
        await ref.putString(params.image, 'data_url');
        return await ref.getDownloadURL();
    } catch (e) {
        alert(e.message);
    }
};

const deleteFileStorage = async (params, collection) => {
    try {
        const ref = fbase.storage.ref().child(`${collection}/${params.id}/${params.name}`);
        await ref.delete();
    } catch (e) {
        alert(e.message);
    }
};

const saveImageToStorageById = async (params, collection) => {
    try {
        const ref = fbase.storage.ref().child(`${collection}/${params.id}/${params.id}`);
        await ref.putString(params.image, 'data_url');
        return await ref.getDownloadURL();
    } catch (e) {
        alert(e.message);
    }
};

const deleteFileStorageById = async (params, collection) => {
    try {
        const ref = fbase.storage.ref().child(`${collection}/${params.id}/${params.id}`);
        await ref.delete();
    } catch (e) {
        alert(e.message);
    }
};

export {
    newTempDocID,
    getDocument,
    setDocument,
    updateDocument,
    deleteDocument,
    saveImageToStorage,
    saveImageToStorageById,
    deleteFileStorageById,
    deleteFileStorage
};
