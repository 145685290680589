import _ from 'lodash';
import { customAlphabet } from 'nanoid';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

const base64toBlob = (str, fileName) => {
    var arr = str.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    let blob = new Blob([u8arr], { type: mime });
    blob.name = fileName;
    return blob;
};

// Price 19,99€ = price_1999
const transformToID = value => {
    return _.chain(value)
        .replace(/\s+/g, '_')
        .replace(/[^a-zA-Z0-9_]/g, '')
        .toLower()
        .value();
};

const generateUniqueID = () => {
    const nanoid = customAlphabet(alphabet, 20);
    return nanoid(); //=> "8iuBtUip7nfOY6rqboCWd6vYtdW6" like firebase ones
};

export { base64toBlob, transformToID, generateUniqueID };
