import React, { Component } from 'react';

import _ from 'lodash';
import move from 'lodash-move';
import { Avatar, Button, Input, Layout, List, message, Row, Table, Tag } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { CopyOutlined, QuestionOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import CategoriesEdit from './CategoriesEdit';
import notify from '@lib/notifications';
import { DeleteIconButton } from '@components/Buttons';
import { ViewHeader } from '@components/ViewHeader';

@observer
class SessionRow extends Component {
    render() {
        return (
            <List.Item
                style={{
                    zIndex: 11,
                    cursor: 'move',
                    height: 40
                }}
            >
                ({this.props.value.sessionOrder}) {this.props.value.sessionName}
            </List.Item>
        );
    }
}

@observer
class CategoriesView extends Component {
    @observable busy = false;
    @observable sortedSessions = [];
    @observable searchText = '';

    @observable authors = appStore.authors; //init variable
    @observable tags = appStore.tags; //init variable

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get filteredData() {
        return this.searchText
            ? _.filter(appStore.categories, item => {
                  return item.name.match(new RegExp(this.searchText, 'gi'));
              })
            : appStore.categories;
    }

    @computed get orderArray() {
        return _.fill(Array(appStore.categories.length), '');
    }

    onDeleteCategory = async record => {
        try {
            await appStore.deleteCategory(record.id);
            notify('success', 'deleteCategory');
        } catch (error) {
            notify('error', 'deleteCategory');
        }
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.sortedSessions = move(this.sortedSessions, oldIndex, newIndex);
        _.each(this.sortedSessions, (item, idx) => {
            this.sortedSessions[idx].sessionOrder = idx + 1;
        });
    };

    onCopy = () => {
        message.success('Link successfully copied to clipboard');
    };

    renderTagColumn = (text, record) => {
        if (record.tags) {
            const tags = _.uniqBy(record.tags, 'tagId');
            return tags.map(item => {
                return item.tagId !== 'All' ? <Tag key={item.tagId}>{item.tagName}</Tag> : null;
            });
        }
    };

    renderAuthorsColumn = (text, record) => {
        if (record.authors) {
            return record.authors.map(authorId => {
                const author = appStore.authors.find(item => {
                    return item.id === authorId;
                });
                return author ? <Tag key={author.id}>{author.name}</Tag> : null;
            });
        }
    };

    renderDeepLinks = (text, record) => {
        return _.map(appStore.deepLinks, item => {
            if (item.id === 'collections') {
                const deepLinktoString = `${item.link}?deeplink=category/${record.id}`.toString();
                return (
                    <div
                        key={item.key}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 5
                        }}
                    >
                        <div style={{ textAlign: 'center' }}>{deepLinktoString}</div>

                        <CopyToClipboard onCopy={this.onCopy} text={deepLinktoString}>
                            <Button type="normal" size="small" icon={<CopyOutlined />}>
                                Copy
                            </Button>
                        </CopyToClipboard>
                    </div>
                );
            }
        });
    };

    handleChangeFilter = e => {
        this.searchText = e.target.value;
    };

    columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 100,
            render: (text, record) => {
                return (
                    <Avatar
                        size={50}
                        shape="square"
                        icon={<QuestionOutlined />}
                        style={{ marginRight: 10 }}
                        src={record.thumbnailImageUrl}
                    />
                );
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Tag',
            dataIndex: 'parent',
            key: 'parent',
            render: this.renderTagColumn
        },
        {
            title: 'Authors',
            dataIndex: 'authors',
            key: 'authors',
            render: this.renderAuthorsColumn
        },
        {
            title: 'Deep link',
            dataIndex: 'link',
            key: 'link',
            render: this.renderDeepLinks
        },
        {
            width: 90,
            key: 'edit',
            render: (text, record) => (
                <Row style={{ display: 'flex' }}>
                    <CategoriesEdit table edit category={record} title={'Add Category'} />
                    <DeleteIconButton
                        onClick={() => {
                            this.onDeleteCategory(record);
                        }}
                    />
                </Row>
            )
        }
    ];

    render() {
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <ViewHeader
                    input={
                        <Input
                            type="text"
                            style={{ width: '44%' }}
                            allowClear
                            name="filter"
                            onChange={this.handleChangeFilter}
                            value={this.searchText}
                            placeholder="Filter by name..."
                        />
                    }
                >
                    <CategoriesEdit title={'Add Category'} textButton={'Add Category'} />
                </ViewHeader>

                <Table
                    bordered
                    size="small"
                    dataSource={this.filteredData}
                    rowKey={record => record.id}
                    columns={this.columns}
                />
            </Layout>
        );
    }
}

export default CategoriesView;
