import React, { useEffect, useRef } from 'react';

import _ from 'lodash';
import { Button, DatePicker, Form, Input, InputNumber, Select, Switch } from 'antd';
import { observer } from 'mobx-react';

import { datePickerToFirebase, firebaseToDatePicker } from '@lib/firebaseDates';

const { Option } = Select;

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 }
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 12 }
};

const EmForm = observer(({ fields, onSubmit, onValuesChange, initialValues, bottomComponent, ctaTitle }) => {
    const form = useRef(null);

    // const state = useLocalObservable(() => {});

    useEffect(() => form.current.resetFields(), [initialValues]); //we need to reset initialValue if they change

    const showItem = field => {
        const inputProps = {
            ...field,
            id: field.id ? field.id : field.name,
            name: field.name ? field.name : field.id
        };

        switch (field.item) {
            case 'textArea':
                return <Input.TextArea {...inputProps} />;
            case 'switch':
                return (
                    <Switch
                        defaultChecked={initialValues?.[field.name] || field.defaultChecked || false}
                        {...inputProps}
                    />
                );
            case 'number':
                return <InputNumber {...inputProps} />;
            case 'title':
                return field.value;
            case 'date':
                return (
                    <DatePicker
                        {...inputProps}
                        showTime
                        format="DD-MM-YYYY HH[:00]"
                        //TODO console will complain defaultValue is not optim, use initialValues instead
                        defaultValue={
                            initialValues?.[inputProps.id] ? firebaseToDatePicker(initialValues[inputProps.id]) : null
                        }
                    />
                );
            case 'select':
                return (
                    <Select allowClear={field.allowClear} mode={field.mode} defaultValue={field.selected}>
                        {field.values.map(value => (
                            <Option key={value.id} value={value.id}>
                                {value.name}
                            </Option>
                        ))}
                    </Select>
                );

            case 'multilineList':
                // state[inputProps.id].values = [];
                return <Input.TextArea {...inputProps} />;

            default:
                return <Input {...inputProps} />;
        }
    };

    // To store timestamps from Antd component to firebase we need to transform them first,
    // add here the fields ids with dates in forms
    // TODO make this better
    const onFinishForm = fieldsValue => {
        const values = {
            ...fieldsValue,
            ...(fieldsValue.startDate && { startDate: datePickerToFirebase(fieldsValue.startDate) }),
            ...(fieldsValue.endDate && { endDate: datePickerToFirebase(fieldsValue.endDate) }),
            ...(fieldsValue.validUntil && { validUntil: datePickerToFirebase(fieldsValue.validUntil) })
        };
        onSubmit(_.omitBy(values, _.isUndefined), fields);
    };

    // If we pass 'value' to datepicker or switch explodes
    const getValuePropName = field => (field.item === 'date' || field.item === 'switch' ? 'checked' : 'value');

    return (
        <Form
            ref={form}
            {...layout}
            name="basic"
            initialValues={{ ...initialValues, remember: true }}
            onFinish={onFinishForm}
            onValuesChange={onValuesChange}
            preserve={true}
        >
            {fields &&
                fields.map(field => (
                    <Form.Item
                        key={field.id ? field.id : field.name}
                        {...field}
                        valuePropName={getValuePropName(field)}
                        hasFeedback={field.validateStatus}
                    >
                        {showItem(field)}
                    </Form.Item>
                ))}
            {bottomComponent}
            <Form.Item {...tailLayout} style={{ marginTop: 10 }}>
                <Button type="primary" htmlType="submit" block>
                    {ctaTitle || 'Submit'}
                </Button>
            </Form.Item>
        </Form>
    );
});

export { EmForm };
