import React from 'react';

import _ from 'lodash';
import AntdFormBuilder from 'antd-form-builder';
import { Button } from 'antd';
import { Form } from 'antd';

import { FormImageUpload } from './EmFormBuilderComponents/FormImageUpload';
import { GroupSelector } from './EmFormBuilderComponents/GroupSelector';
import { MultiLine } from './EmFormBuilderComponents/Multiline';
import { SessionSelector } from './EmFormBuilderComponents/SessionSelector';
import { TextAreaFormatted } from './EmFormBuilderComponents/TextAreaFormatted';

AntdFormBuilder.defineWidget('multilineList', MultiLine);
AntdFormBuilder.defineWidget('groupsSelect', GroupSelector);
AntdFormBuilder.defineWidget('sessionSelect', SessionSelector);
AntdFormBuilder.defineWidget('textAreaFormatted', TextAreaFormatted);
AntdFormBuilder.defineWidget('imageUpload', FormImageUpload);

const EmFormBuilder = ({ onSubmit, meta, saveText, initialValues, bottomComponent, disabled, loading }) => {
    const forceUpdate = AntdFormBuilder.useForceUpdate();

    const [form] = Form.useForm();

    const staticFields = _.filter(meta.fields, field => {
        return !field.dependsOn;
    });

    const dynamicFields = _.chain(meta.fields)
        .filter(item => {
            if (item.dependsOn) {
                const { key, value } = item.dependsOn;
                if ((form.getFieldValue(key) || initialValues.data?.[key]) === value) {
                    return item;
                }
            }
        })
        .value();

    const formFields = _.map([...staticFields, ...dynamicFields], item => {
        return {
            ...item,
            onChange: item.onChange
                ? evt => {
                      item.onChange(evt?.target?.value, form);
                  }
                : null
        };
    });

    return (
        <Form onFinish={onSubmit} form={form} onValuesChange={forceUpdate}>
            <AntdFormBuilder meta={{ ...meta, fields: formFields }} initialValues={initialValues} form={form} />
            <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
                <Button type="primary" htmlType="submit" disabled={disabled} loading={loading}>
                    {saveText || 'Save'}
                </Button>
            </Form.Item>
            {/*TODO do we want to keep it as bottom component or should we create a definedWidget*/}
            {bottomComponent}
        </Form>
    );
};

export { EmFormBuilder };
