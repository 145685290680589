import React, { Component } from 'react';

import axios from 'axios';
import moment from 'moment';
import { Button, Layout, Modal, Row } from 'antd';
import { CheckOutlined, RocketOutlined, SyncOutlined } from '@ant-design/icons';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import config from '@lib/config.json';
import notify from '@lib/notifications';

import { callFirebaseFunction } from '@lib/firebaseFunctions';

const {
    apiBuildkiteMonorepo,
    buildKiteBranch,
    buildKiteCommit,
    buildKiteEnv,
    buildKiteMessage,
    buildKiteToken,
    authorsUpdateThumbs,
    collectionUpdateThumbs,
    episodesUpdateThumbs,
    goalsUpdateThumbs,
    sessionUpdateThumbs,
    apiPublishEndpoint,
    target
} = config;

@observer
class PublishScreen extends Component {
    @observable busy = false;
    @observable backingUp = false;
    @observable publishing = false;
    @observable onPublishVisible = false;
    @observable refreshingImagesCache = {
        collections: false,
        sessions: false,
        authors: false,
        episodes: false,
        goals: false
    };
    @observable sessionPreviewBuild = false;

    refreshImagesCacheCollections = async () => {
        this.refreshingImagesCache.collections = true;
        await axios.get(collectionUpdateThumbs);
        this.refreshingImagesCache.collections = false;
    };

    refreshImagesCacheSessions = async () => {
        this.refreshingImagesCache.sessions = true;
        await axios.get(sessionUpdateThumbs);
        this.refreshingImagesCache.sessions = false;
    };

    refreshImagesCacheAuthors = async () => {
        this.refreshingImagesCache.authors = true;
        await axios.get(authorsUpdateThumbs);
        this.refreshingImagesCache.authors = false;
    };

    refreshImagesCacheEpisodes = async () => {
        this.refreshingImagesCache.episodes = true;
        await axios.get(episodesUpdateThumbs);
        this.refreshingImagesCache.episodes = false;
    };

    refreshImagesCacheGoals = async () => {
        this.refreshingImagesCache.goals = true;
        await axios.get(goalsUpdateThumbs);
        this.refreshingImagesCache.goals = false;
    };

    buildKitePreviewHook = async () => {
        const url = apiBuildkiteMonorepo;
        const data = {
            commit: buildKiteCommit,
            branch: buildKiteBranch,
            message: buildKiteMessage,
            env: { BUILD_HOOK: buildKiteEnv }
        };
        const config = {
            headers: { Authorization: `Bearer ${buildKiteToken}` }
        };

        this.sessionPreviewBuild = true;
        const buildKiteRes = await axios.post(url, data, config);
        if (buildKiteRes.status === 201) {
            this.sessionPreviewBuild = false;
        }
    };

    onPublish = async () => {
        try {
            this.busy = true;
            // TODO disabled backup
            // this.backingUp = true;
            // const backupRes = await axios.get(apiBackupEndpoint);

            // if (backupRes.status == 200) {
            //     try {
            //         this.backingUp = false;
            //         this.publishing = true;
            //         const publishRes = await axios.get(apiPublishEndpoint);
            //         if (publishRes.status == 200) {
            //             this.publishing = false;
            //         }
            //         notify('success', 'apiPublishEndpoint');
            //     } catch (error) {
            //         notify('error', 'apiPublishEndpoint');
            //     }
            // }

            this.publishing = true;
            const publishRes = await axios.get(apiPublishEndpoint);
            if (publishRes.status === 200) {
                this.publishing = false;
            }
            notify('success', 'apiPublishEndpoint');

            this.refreshImagesCacheCollections();
            this.refreshImagesCacheSessions();
            this.refreshImagesCacheEpisodes();
            this.refreshImagesCacheAuthors();
            this.refreshImagesCacheGoals();
            this.buildKitePreviewHook();

            // TODO disabled backup
            //this.backingUp = false;
            this.publishing = false;

            this.busy = false;
            // TODO disabled backup
            //notify('success', 'apiBackupEndpoint');
        } catch (error) {
            this.publishing = false;
            notify('error', 'apiPublishEndpoint');
            // TODO disabled backup
            //notify('error', 'apiBackupEndpoint');
        }
    };

    onShowModal = location => {
        if (location === 'publish') {
            this.onPublishVisible = true;
        }
    };

    onCloseModal = location => {
        if (location === 'publish') {
            this.onPublishVisible = false;
        }
    };

    onOkModal = location => {
        if (location === 'publish') {
            this.onPublish();
            this.onPublishVisible = false;
        }
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        const publishTime = appStore.controlFeed.publish
            ? moment(appStore.controlFeed.publish.lastPublished.timestamp).format('DD MMMM YYYY hh:mm a')
            : null;

        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <Row type="flex" justify="end" style={{ padding: 20 }}>
                    <Button
                        type="primary"
                        icon={<RocketOutlined />}
                        onClick={() => this.onShowModal('publish')}
                        loading={this.busy}
                    >
                        {'Publish to: '}
                        <b> {target}</b>
                    </Button>
                    <Modal
                        visible={this.onPublishVisible}
                        onOk={() => this.onOkModal('publish')}
                        onCancel={() => this.onCloseModal('publish')}
                        centered={true}
                        width={240}
                        closable={false}
                        destroyOnClose={true}
                    >
                        <p>Are you sure?</p>
                    </Modal>
                </Row>

                <Row>
                    {this.backingUp && <p>Backing up, please wait ..</p>}
                    {this.publishing && <p>Publishing, please wait ..</p>}

                    {!this.backingUp && !this.publishing && publishTime && (
                        <p>
                            Last time published: <b>{publishTime}</b>
                        </p>
                    )}
                </Row>
                <Row>
                    {this.refreshingImagesCache.collections ? (
                        <p>
                            Images Cache Collections: <SyncOutlined spin /> (It's safe to close the browser)
                        </p>
                    ) : (
                        <p>
                            Images Cache Collections: <CheckOutlined />
                        </p>
                    )}
                </Row>
                <Row>
                    {this.refreshingImagesCache.sessions ? (
                        <p>
                            Images Cache Sessions: <SyncOutlined spin /> (It's safe to close the browser)
                        </p>
                    ) : (
                        <p>
                            Images Cache Sessions: <CheckOutlined />
                        </p>
                    )}
                </Row>
                <Row>
                    {this.refreshingImagesCache.authors ? (
                        <p>
                            Images Cache Authors: <SyncOutlined spin /> (It's safe to close the browser)
                        </p>
                    ) : (
                        <p>
                            Images Cache Authors: <CheckOutlined />
                        </p>
                    )}
                </Row>
                <Row>
                    {this.refreshingImagesCache.episodes ? (
                        <p>
                            Images Cache Episodes: <SyncOutlined spin /> (It's safe to close the browser)
                        </p>
                    ) : (
                        <p>
                            Images Cache Episodes: <CheckOutlined />
                        </p>
                    )}
                </Row>
                <Row>
                    {this.refreshingImagesCache.goals ? (
                        <p>
                            Images Cache Goals: <SyncOutlined spin /> (It's safe to close the browser)
                        </p>
                    ) : (
                        <p>
                            Images Cache Goals: <CheckOutlined />
                        </p>
                    )}
                </Row>
                <Row>
                    {this.refreshingImagesCache.sessions ? (
                        <p>
                            Session Preview Build: <SyncOutlined spin /> (It's safe to close the browser)
                        </p>
                    ) : (
                        <p>
                            Session Preview Build: <CheckOutlined />
                        </p>
                    )}
                </Row>
                {target !== 'release' && (
                    <>
                        <Row style={{ marginTop: 20 }}>
                            <Button
                                type="secondary"
                                icon={<SyncOutlined />}
                                onClick={() => callFirebaseFunction('syncDataProdTest')}
                            >
                                Sync Data with Production
                            </Button>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <a
                                target="_blank"
                                href="https://console.cloud.google.com/transfer/cloud/jobs/transferJobs%2F11619281409828538798/runs?project=emjoyproduction"
                            >
                                To Sync Audios Go Here
                            </a>
                        </Row>
                    </>
                )}
            </Layout>
        );
    }
}

export default PublishScreen;
