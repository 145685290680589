import React, { Component } from 'react';

import { Tabs } from 'antd';

import appStore from '@stores/app';

import AppSettings from './appSettings';
import AutomatedOfferView from '../App/DynamicContent/AutomatedOfferView';
import ExtraInfoScreen from '../ExtraInfo';

const { TabPane } = Tabs;

class AdminScreen extends Component {
    render() {
        return (
            <Tabs defaultActiveKey="appSettings" animated={false} type="card">
                <TabPane tab="Extra Pages" key="extraPages">
                    <ExtraInfoScreen />
                </TabPane>
                {appStore.advancedMode && [
                    <TabPane tab="Default Offer" key="automatedOffer">
                        <AutomatedOfferView />
                    </TabPane>,
                    ,
                    <TabPane tab="Settings" key="appSettings">
                        <AppSettings />
                    </TabPane>
                ]}
            </Tabs>
        );
    }
}

export default AdminScreen;
