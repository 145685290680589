import '@ant-design/compatible/assets/index.css';
import React, { Component } from 'react';
import { computed, makeObservable, observable } from 'mobx';
import { Form } from '@ant-design/compatible';
import { Input } from 'antd';
import { observer } from 'mobx-react';

const xhr = async ({ url, mode, onLoad }) => {
    if (mode === 'image') {
        const request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'blob';
        request.onload = () => {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload = function () {
                onLoad(reader.result);
            };
        };
        request.send();
    }
    if (mode === 'head') {
        const request = new XMLHttpRequest();
        request.open('HEAD', url, true);
        request.onload = () => {
            onLoad(request.responseURL);
        };
        request.send();
    }
};

const formItemLayout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 21
    }
};

@observer
class UploadUnsplash extends Component {
    @observable urlInvalid = null;
    @observable unsplashUrl = null;
    @observable url = null;
    @observable i;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get dld() {
        return this.unsplashUrl.replace('unsplash.com/photos', 'source.unsplash.com') + '/';
    }

    onUrlChange = e => {
        this.url = '';
        this.unsplashUrl = e.target.value;
        this.imageRef = null;
        this.crop = this.initialCrop;

        xhr({
            mode: 'head',
            url: this.dld,
            onLoad: newUrl => {
                xhr({
                    mode: 'image',
                    url: newUrl.replace(/\?(.*)/, '?q=75&fm=jpg'),
                    onLoad: data => {
                        this.url = data;
                        this.props.onChange(data);
                    }
                });
            }
        });
    };

    render() {
        return (
            <Form>
                <Form.Item
                    {...formItemLayout}
                    label="Unsplash: "
                    validateStatus={this.urlInvalid ? 'error' : null}
                    help={this.urlInvalid ? 'Empty value' : null}
                >
                    <Input placeholder="Unsplash url" onChange={this.onUrlChange} value={this.unsplashUrl} />
                </Form.Item>
            </Form>
        );
    }
}

export default UploadUnsplash;
