import React from 'react';

import _ from 'lodash';
import { observer, useLocalObservable } from 'mobx-react';

import appStore from '@stores/app';
import ImageCropUpload from '@components/ImageUpload/ImageCropUpload';
import ModalWrapper from '@components/ModalWrapper';
import notify from '@lib/notifications';
import { Button, Tabs } from 'antd';
import { EmFormBuilder } from '@components/EmFormBuilder';
import { groupsStore } from '@stores/middleware/groups';
import { newTempDocID, saveImageToStorageById } from '@lib/firebaseHandler';

const { TabPane } = Tabs;

const GroupsEdit = observer(({ groupType, record = {}, onSave, closeModal }) => {
    const groupTypesWithOneImage = ['goals'];

    const state = useLocalObservable(() => ({
        busy: false,
        get initialValues() {
            _.mapKeys(record?.groups, (recordValue, recordKey) => {
                record[recordKey] = recordValue; // TODO this might be dangeorus if we have same data as the groupType outside 'groups'
            });
            return record;
        },
        get groupData() {
            return _.find(groupsStore.allGroupsFormsFields, { groupType });
        },
        get fields() {
            return _.map(this.groupData.options, option => {
                if (option.id === 'id') {
                    return {
                        ...option,
                        widgetProps: { ...option.widgetProps, disabled: !!record.id || !appStore.advancedMode }
                    };
                } else {
                    return option;
                }
            });
        },
        get tabs() {
            const tabs = ['data'];
            if (this.groupData.images) {
                tabs.push('images');
            }
            return tabs;
        }
    }));

    const onPressButton = () => onSubmit();

    const onSubmit = async (values = record) => {
        try {
            state.busy = true;

            if (!values.id) {
                const newGroup = newTempDocID('groups');
                values.id = newGroup.id;
            }

            // save groups inside groups object to separate them
            _.mapKeys(values, (value, key) => {
                const isGroup = !!_.size(groupsStore.getByType(key));
                if (isGroup && values[key]) {
                    if (!values.groups) {
                        values.groups = {};
                    }
                    values.groups[key] = values[key];
                    delete values[key];
                }
            });

            if (_.includes(values.image, 'base64')) {
                const imageUrl = await saveImageToStorageById(values, 'groups');
                values.image = imageUrl;
                if (groupTypesWithOneImage.includes(groupType)) {
                    values.imageProcessed = values.image === record.image;
                }
            }
            const groupData = await groupsStore.saveGroup({ ...values, groupType });

            // add collection to 'All' Theme if is a new collection
            if (groupType === 'collections' && !values.id) {
                const allTag = _.find(groupsStore.allGroupsData, { id: 'wellbeingThemeAll' });
                allTag.groups.collections = [...allTag.groups.collections, groupData.id];

                await groupsStore.saveGroup(allTag);
            }

            notify('success', values.id ? 'addGroup' : 'updateGroup');
            state.busy = false;

            onSave && onSave();
            closeModal();
        } catch (error) {
            state.busy = false;
            notify('error', error);
        }
    };

    const form = (
        <EmFormBuilder
            meta={{
                initialValues: state.initialValues,
                fields: state.fields
            }}
            initialValues={record}
            onSubmit={onSubmit}
            disabled={state.busy}
        />
    );

    const setContent = content => {
        _.assign(record, content);
    };

    const buildTabs = tab => {
        switch (tab) {
            case 'images':
                const images = state.groupData.images;

                return (
                    <TabPane tab={'Images'} key={'images'}>
                        {state.groupData.imagesMultiple ? (
                            _.map(images, (img, i) => {
                                return (
                                    <div style={{ marginTop: 50, marginRight: 30 }}>
                                        {i === 1 && (
                                            <span style={{ opacity: '0.5', marginBottom: 20 }}>
                                                Here we need to upload the bigger image size we have, 2700 pixels at
                                                least
                                            </span>
                                        )}
                                        <ImageCropUpload imagesFormat={img} content={record} onSave={setContent} />
                                    </div>
                                );
                            })
                        ) : (
                            <ImageCropUpload imagesFormat={images} content={record} onSave={setContent} />
                        )}
                        <Button onClick={onPressButton}>Save</Button>
                    </TabPane>
                );
            default:
                return (
                    <TabPane tab={'Data'} key={'data'}>
                        {form}
                    </TabPane>
                );
        }
    };

    if (_.size(state.tabs) > 1) {
        return (
            <Tabs defaultActiveKey={'data'} animated={false} type="card">
                {_.map(state.tabs, buildTabs)}
            </Tabs>
        );
    } else {
        return form;
    }
});

export default ModalWrapper(GroupsEdit);
