import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import _ from 'lodash';
import move from 'lodash-move';
import { Button, Empty, Input, Layout, List, Modal, Row, Select, Table, Tabs } from 'antd';
import { computed, makeObservable, observable, reaction } from 'mobx';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import appStore from '@stores/app';
import FormBuilder from '@lib/FormBuilder';
import notify from '@lib/notifications';
import { DeleteIconButton, EditIconButton } from '@components/Buttons';
import { ViewHeader } from '@components/ViewHeader';

const { TabPane } = Tabs;

@observer
class TagRow extends Component {
    render() {
        return (
            <List.Item
                style={{
                    zIndex: 11,
                    cursor: 'move',
                    height: 40
                }}
            >
                ({this.props.value.categoryOrder}) {this.props.value.categoryName}
            </List.Item>
        );
    }
}

const SortableCategory = SortableElement(({ value }) => {
    return <TagRow value={value} />;
});

const SortableList = SortableContainer(({ items }) => {
    return (
        <List bordered>
            {items.map((item, index) => {
                return <SortableCategory key={item.categoryId} index={index} value={item} />;
            })}
        </List>
    );
});

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 20
    }
};
@observer
class TagsView extends Component {
    @observable adding = false;
    @observable editing = false;

    @observable currentTab = 'tag';

    @observable tag = {};
    @observable sortedCategories = [];

    constructor(props) {
        super(props);
        makeObservable(this);

        reaction(
            () => `${this.tag.id}__${appStore.categories.length}`,
            () => {
                this.currentTab = this.tag.readOnly ? 'categories_order' : 'tag';
                this.sortedCategories = _.chain(appStore.joins.relationships.orderedCategoriesTag)
                    .filter({ tagId: this.tag.id })
                    .sortBy('categoryOrder')
                    .value();
            }
        );
    }

    @computed get currentTagOrder() {
        const index = _.findIndex(appStore.tags, { order: this.tag.order });
        if (index > -1) {
            return index + 1;
        }
        return appStore.tags.length + 1;
    }

    @computed get orderArray() {
        return _.fill(Array(appStore.tags.length), '');
    }

    @computed get tagMeta() {
        return {
            formItemLayout,

            elements: [
                {
                    key: 'name',
                    label: 'Name',
                    widget: Input,
                    required: true,
                    initialValue: this.tag.name,
                    rules: [
                        {
                            validator: (r, value, cb) => {
                                const nodupe = _.isEmpty(
                                    _.pickBy(appStore.tags, item => {
                                        return item.name == value && this.tag.id != item.id;
                                    })
                                );

                                cb(nodupe ? undefined : false);
                            },
                            message: 'Duplicate category'
                        }
                    ]
                },

                {
                    key: 'order',
                    label: 'Order',
                    widget: Select,
                    widgetProps: {},
                    initialValue: this.currentTagOrder,

                    children: this.orderArray.map((item, idx) => {
                        const order = idx + 1;
                        return (
                            <Option key={order} value={order}>
                                {order}
                            </Option>
                        );
                    })
                },
                {
                    key: 'relatedCategories',
                    label: 'Related',
                    widget: Select,
                    widgetProps: { mode: 'multiple' },
                    initialValue: this.tag.relatedCategories || [],
                    children: _.compact(
                        appStore.categories.map(item => {
                            if (!_.find(item.tags, { tagId: this.tag.id })) {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            }
                        })
                    )
                }
            ]
        };
    }

    onAddTag = () => {
        this.adding = true;
    };

    onModalOk = async () => {
        if (this.currentTab === 'categories_order') {
            try {
                this.busy = true;
                await appStore.updateCategorySorting(this.tag.id, this.sortedCategories);
                this.busy = false;
                this.currentTab = 'tag';
                notify('success', 'sortCategories');
            } catch (error) {
                this.busy = false;
                notify('error', 'sortCategories');
            }
            this.adding = false;
            this.editing = false;
        } else {
            this.props.form.validateFieldsAndScroll(async (errors, values) => {
                if (errors) {
                    return false;
                }

                _.assign(this.tag, values);

                if (this.adding) {
                    try {
                        this.adding = false;
                        await appStore.addTag(this.tag);
                        this.resetTag();
                        notify('success', 'addTag');
                    } catch (error) {
                        this.adding = false;
                        notify('error', 'addTag');
                    }
                }
                if (this.editing) {
                    try {
                        this.editing = false;
                        await appStore.updateTag(this.tag);
                        this.resetTag();
                        notify('success', 'updateTag');
                    } catch (error) {
                        this.editing = false;
                        notify('error', 'updateTag');
                    }
                }
            });
        }
    };

    onModalCancel = () => {
        this.adding = false;
        this.editing = false;
        this.resetTag();
    };

    onDeleteTag = async record => {
        try {
            await appStore.deleteTag(record.id);
            notify('success', 'deleteTag');
        } catch (error) {
            notify('error', 'deleteTag');
        }
    };

    onEditTag = record => {
        this.editing = true;
        this.tag = record;
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.sortedCategories = move(this.sortedCategories, oldIndex, newIndex);
        _.each(this.sortedCategories, (category, idx) => {
            this.sortedCategories[idx].categoryOrder = idx + 1;
        });
    };

    resetTag = () => {
        this.tag = {};
        this.currentTab = 'tag';
    };

    columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        {
            width: 90,
            key: 'edit',
            render: (text, record) => {
                return (
                    <Row>
                        <EditIconButton
                            onClick={() => {
                                this.onEditTag(record);
                            }}
                        />
                        {!record.readOnly && (
                            <DeleteIconButton
                                onClick={() => {
                                    this.onDeleteTag(record);
                                }}
                            />
                        )}
                    </Row>
                );
            }
        }
    ];

    render() {
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <ViewHeader>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={this.onAddTag}>
                        New Tag
                    </Button>
                </ViewHeader>

                <Table
                    bordered
                    size="small"
                    dataSource={appStore.tags}
                    rowKey={record => record.id}
                    columns={this.columns}
                />

                <Modal
                    destroyOnClose
                    maskClosable={false}
                    closable={false}
                    zIndex={10}
                    title={this.adding ? 'Add a new tag' : 'Edit tag'}
                    visible={this.adding || this.editing}
                    onOk={this.onModalOk}
                    onCancel={this.onModalCancel}
                >
                    <Tabs
                        defaultActiveKey={this.currentTab}
                        activeKey={this.currentTab}
                        animated={false}
                        type="card"
                        onChange={tab => (this.currentTab = tab)}
                    >
                        {!this.tag.readOnly && (
                            <TabPane tab="Tag" key="tag">
                                <Form>
                                    <FormBuilder meta={this.tagMeta} form={this.props.form} />
                                </Form>
                            </TabPane>
                        )}

                        {this.editing && (
                            <TabPane tab="Collections order" key="categories_order">
                                {!this.sortedCategories?.length && <Empty description="This tag has no categories" />}

                                {!!this.sortedCategories?.length && (
                                    <SortableList items={this.sortedCategories} onSortEnd={this.onSortEnd} />
                                )}
                            </TabPane>
                        )}
                    </Tabs>
                </Modal>
            </Layout>
        );
    }
}

export default Form.create()(TagsView);
