import React from 'react';

import { observer } from 'mobx-react';

import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';

import { EmFormBuilder } from '@components/EmFormBuilder';

import appStore from '@stores/app';

import notify from '@lib/notifications';

const availableOffers = [
    'yearly3499intro1749',
    'yearly3499intro2449',
    'yearly3499intro2799',
    'yearly3499intro2949',
    'yearly3499intro3149'
];

const AutomatedOfferView = observer(props => {
    const onSubmit = async values => {
        try {
            await appStore.updateAppConfig({
                automatedOffer: { ...values }
            });

            notify('success', 'automatedOffer');
        } catch (error) {
            notify('error', 'automatedOffer');
        }
    };

    const fields = [
        {
            name: 'active',
            label: 'Show D1D8 automatically',
            widget: 'switch'
        },
        {
            name: 'currentOfferSubscriptionId',
            label: 'Offer Subscription Id:',
            widget: 'select',
            rules: [{ required: true, message: 'Please select a subscription' }],
            options: availableOffers
        }
    ];

    return (
        <EmFormBuilder
            meta={{
                fields
            }}
            initialValues={{
                active: appStore.controlFeed?.appConfiguration?.automatedOffer?.active || false,
                currentOfferSubscriptionId:
                    appStore.controlFeed?.appConfiguration?.automatedOffer?.currentOfferSubscriptionId || ''
            }}
            onSubmit={onSubmit}
        />
    );
});

export default Form.create()(AutomatedOfferView);
