import React, { useState } from 'react';

import _ from 'lodash';
import { CheckCircleTwoTone, CloseCircleTwoTone, CodeTwoTone, DashboardTwoTone, EditTwoTone } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import ReferralCodesEdit from './ReferralCodesEdit';
import ReferralsCodeDetails from './ReferralCodeDetails';
import ReferralsCodeStats from './ReferralCodeStats';
import { EmSelect } from '@components/EmSelect';
import { EmTable } from '@components/EmTable';
import { fbaseEnvName } from '@lib/firebase';
import { Layout } from 'antd';
import { ViewHeader } from '@components/ViewHeader';

const { Content } = Layout;

const linkSetupConfig =
    fbaseEnvName === 'emjoy-test'
        ? {
              setupLink:
                  'https://console.firebase.google.com/u/0/project/emjoy-test/durablelinks/links/https:~2F~2Femjoy.rborn.info~2Fref',
              webLink: 'https://letsemjoy.com/referral?code=',
              deepLink: 'https://emjoy.rborn.info/ref/'
          }
        : {
              setupLink: 'https://console.firebase.google.com/project/emjoyproduction/durablelinks/links',
              webLink: 'https://letsemjoy.com/referral?code=',
              deepLink: 'https://links.letsemjoy.com/ref/'
          };

const ReferralCodesView = observer(() => {
    const [referralCodesView, setReferralCodesView] = useState([]);

    const handleCampaignChange = referralType => {
        setReferralCodesView(_.filter(appStore.referralCodes, code => code.referralType === referralType));
    };

    const columns = [
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            // eslint-disable-next-line react/display-name
            render: (text, record) =>
                record.active ? (
                    <Row type="flex" align="middle">
                        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ paddingRight: 5 }} />
                        {linkSetupConfig.deepLink + record.code}
                    </Row>
                ) : (
                    <Row type="flex" align="middle">
                        <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ paddingRight: 5 }} />
                        Activation Pending
                    </Row>
                )
        },
        {
            title: 'INFO',
            dataIndex: 'info',
            key: 'info',
            render: (text, record) => {
                return (
                    <>
                        <strong>Code:</strong> {record.code} <br />
                        <strong>Type:</strong> {record.referralType}
                    </>
                );
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            // eslint-disable-next-line react/display-name
            render: (text, record) => (
                <Row>
                    <Col span={6}>
                        <ReferralsCodeStats
                            title={'Dashboard for code usage stats'}
                            table
                            record={record}
                            iconButton={<DashboardTwoTone style={{ fontSize: 20 }} />}
                        />
                    </Col>
                    <Col span={6}>
                        <ReferralsCodeDetails
                            title={'How to implement in Firebase'}
                            table
                            record={record}
                            iconButton={<CodeTwoTone style={{ fontSize: 20 }} twoToneColor="#53c41a" />}
                            linkSetupConfig={linkSetupConfig}
                        />
                    </Col>
                    <Col span={6}>
                        <ReferralCodesEdit
                            title={'Edit code details'}
                            table
                            record={record}
                            iconButton={<EditTwoTone style={{ fontSize: 20 }} twoToneColor="#eb2f96" />}
                        />
                    </Col>
                </Row>
            )
        }
    ];

    const campaigns = _.filter(appStore.referralCampaings, campaign => campaign.name);

    return (
        <Layout style={{ backgroundColor: '#fff' }}>
            <ViewHeader>
                <EmSelect values={campaigns} placeholder={'Select campaign'} handleChange={handleCampaignChange} />
                <ReferralCodesEdit title={'Add new Custom Code'} textButton={'Add Custom Code'} />
            </ViewHeader>
            <Content>
                <EmTable data={referralCodesView} columns={columns} />
            </Content>
        </Layout>
    );
});

export { ReferralCodesView };
