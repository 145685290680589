import React from 'react';

import _ from 'lodash';
import { Button, Input, Layout, message, Row, Tag, Tooltip } from 'antd';
import { CopyOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { observer, useLocalObservable } from 'mobx-react';

import NewSessionsEdit from './NewSessionsEdit';
import { DeleteIconButton } from '@components/Buttons';
import { EmTable } from '@components/EmTable';
import { groupsStore } from '@stores/middleware/groups';
import { sessionStore } from '@stores/session';
import { ViewHeader } from '@components/ViewHeader';

//TODO rename when cleaning to SessionsView
const NewSessionsView = observer(props => {
    const selectSessions = type => {
        switch (type) {
            case 'story':
                return sessionStore.allStories;
            case 'wellbeing':
                return sessionStore.allWellbeing;
            default:
                return sessionStore.allSessions;
        }
    };

    const state = useLocalObservable(() => ({
        searchText: null,
        get filteredData() {
            const sessions = selectSessions(props.contentType);

            return this.searchText
                ? _.chain(sessions)
                      .filter(item => {
                          return item.name.match(new RegExp(this.searchText, 'gi'));
                      })
                      .sortBy('name')
                      .value()
                : _.chain(sessions).sortBy('name').value();
        }
    }));

    const onCopy = () => {
        message.success('Id successfully copied to clipboard');
    };

    const handleChangeFilter = e => {
        state.searchText = e.target.value;
    };

    const groupType = props.contentType === 'wellbeing' ? 'collections' : 'episodes';
    const buildColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                const hasAudio = !!record.mediaFileName && !!record.playUrl;
                return (
                    <>
                        {record.converting && (
                            <Tooltip title="Converting...">
                                <SyncOutlined spin style={{ color: 'orange', marginRight: 10 }} />
                            </Tooltip>
                        )}
                        {!hasAudio && (
                            <Tooltip title="Missing audio">
                                <ExclamationCircleOutlined style={{ color: '#f00', marginRight: 10 }} />
                            </Tooltip>
                        )}
                        {record.name}
                    </>
                );
            }
        },
        ..._.map(props.columns, column => {
            return {
                title: column.title,
                key: column.key,
                render: (text, record) => {
                    if (column.key === 'image') {
                        const imageUrl = record.images ? record.images.square.original : record.thumbnailImageUrl;
                        return imageUrl && <img src={imageUrl} style={{ width: 75, height: 75 }} />;
                    } else {
                        return record[column.id];
                    }
                }
            };
        }),
        {
            title: props.contentType === 'wellbeing' ? 'Collection' : 'Episodes',
            key: groupType,
            render: (text, record) => {
                const collections = _.filter(
                    groupsStore.getByContainSessionId(record.id),
                    group => group.groupType === groupType
                );
                if (collections) {
                    return collections.map(item => {
                        return <Tag key={item.categoryId}>{item.name}</Tag>;
                    });
                }
            }
        },
        {
            width: 360,
            title: 'Id',
            key: 'id',
            render: (text, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 5
                        }}
                    >
                        <div style={{ textAlign: 'center' }}>{record.id}</div>

                        <CopyToClipboard onCopy={onCopy} text={record.id}>
                            <Button type="normal" size="small" icon={<CopyOutlined />}>
                                Copy
                            </Button>
                        </CopyToClipboard>
                    </div>
                );
            }
        },
        {
            width: 90,
            title: 'Options',
            key: 'edit',
            render: (text, record) => {
                //TODO delete from groups

                const options = (
                    <>
                        <NewSessionsEdit table edit record={record} {...props} title={'Edit Session'} />
                        <DeleteIconButton onClick={() => sessionStore.deleteSession(record.id)} />
                    </>
                );
                return (
                    <Row style={{ display: 'flex' }} justify={'end'}>
                        {options}
                    </Row>
                );
            }
        }
    ];

    return (
        <Layout style={{ backgroundColor: '#fff' }}>
            <ViewHeader
                input={
                    <Input
                        type="text"
                        style={{ width: '44%' }}
                        allowClear
                        name="filter"
                        onChange={handleChangeFilter}
                        placeholder="Filter by name..."
                        value={state.searchText}
                    />
                }
            >
                <NewSessionsEdit {...props} textButton={'New Session'} title={'New Session'} />
            </ViewHeader>
            <EmTable
                bordered
                pagination={false}
                size="small"
                data={state.filteredData}
                rowKey={record => record.id}
                columns={buildColumns}
            />

            {/* {appStore.advancedMode && ( //this is commented for now, till we're sure the new admin works properly, to be removed after
                <Row style={{ display: 'flex', justifyContent: 'space-between', padding: 50 }}>
                    <Row>Check CONSOLE for results</Row>

                    <Button danger onClick={() => sessionStore.fixContentType()}>
                        Fix Content Type
                    </Button>
                    <Button danger onClick={() => sessionStore.fixSessionsInsideCollections()}>
                        Fix Sessions In Collections
                    </Button>
                    <Button danger onClick={() => sessionStore.fixSpicyness()}>
                        Fix Spicyness
                    </Button>
                    <Button danger onClick={() => sessionStore.fixSessionType()}>
                        Fix SessionType
                    </Button>
                </Row>
            )} */}
        </Layout>
    );
});

export default NewSessionsView;
