import React, { Component } from 'react';

import _ from 'lodash';
import moment from 'moment';
import ReactPlayer from 'react-player';
import { Button, Col, Layout, Row, Upload } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { UploadOutlined } from '@ant-design/icons';

// eslint-disable-next-line
import momentDurationFormatSetup from 'moment-duration-format';

import { base64toBlob } from '@lib/utils';
import { fbase } from '../lib/firebase';

@observer
class MediaUpload extends Component {
    @observable adding = false;
    @observable mediaData = {
        name: this.props.session.mediaFileName
    };
    @observable url = '';

    @computed get durationDisplay() {
        return this.mediaData.duration ? moment.duration(this.mediaData.duration, 'seconds').format('mm:ss') : '--:--';
    }

    prepareFile = file => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.url = reader.result;
            this.mediaData.name = file.name;
            this.mediaData.type = file.type;
            this.mediaData.size = file.size;
            this.mediaData.blob = base64toBlob(reader.result, file.name);
        });
        reader.readAsDataURL(file);
    };

    onModalOk = async () => {
        if (this.adding) {
            this.adding = false;
        }
    };
    onModalCancel = () => {
        this.reset();
    };

    reset = () => {
        this.url = '';
        this.mediaData = {};
        this.adding = false;
        this.editing = false;
        this.playerRef = null;
    };

    getDownloadURL = async () => {
        const unescapedplayUrl = _.get(unescape(this.props.session.playUrl).match(/(\/media\/.*)\?/), '[1]');
        const ref = fbase.storage.ref(unescapedplayUrl);

        this.url = await ref.getDownloadURL();
    };

    constructor(props) {
        super(props);
        makeObservable(this);
        this.getDownloadURL();
    }

    render() {
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <Row>
                    <Col span={18} style={{ padding: 24 }} align="middle">
                        {this.props.browse && (
                            <Row type="flex">
                                <Upload
                                    accept=".mp3, .wav" // add video formats
                                    customRequest={() => {}}
                                    showUploadList={false}
                                    beforeUpload={this.prepareFile}
                                >
                                    <Button>
                                        <UploadOutlined /> Upload media
                                    </Button>
                                </Upload>
                                <ReactPlayer
                                    ref={ref => (this.playerRef = ref)}
                                    url={this.url}
                                    playing={false}
                                    controls
                                    onReady={() => {
                                        this.mediaData.duration = this.playerRef.getDuration();
                                    }}
                                />
                            </Row>
                        )}
                    </Col>
                    {this.url && (
                        <Col span={6}>
                            <Row>
                                <b>{`${this.mediaData.name}`}</b>
                            </Row>
                            {/* <Row>{`Type: ${this.mediaData.type}`}</Row>
                            <Row>{`Size: ${(this.mediaData.size / 1000000).toFixed(2)} MB`}</Row> */}

                            <Row>{`Duration: ${this.durationDisplay}`}</Row>
                            {this.props.uploadProgress > 0 && this.props.uploadProgress < 100 && (
                                <Row>{`Uploading: ${this.props.uploadProgress.toFixed(2)} %`}</Row>
                            )}
                        </Col>
                    )}
                </Row>
            </Layout>
        );
    }
}

export default MediaUpload;
