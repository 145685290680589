import React from 'react';

import _ from 'lodash';
import { Button, Divider, Image, Layout, Popover, Row } from 'antd';
import { EyeOutlined, OrderedListOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';

import { widgetsStore } from '@stores/middleware/widgets';

import { DeleteIconButton } from '@components/Buttons';
import { EmSwitch } from '@components/EmSwitch';
import { EmTable } from '@components/EmTable';
import { ViewHeader } from '@components/ViewHeader';

import WidgetsEdit from './WidgetsEdit';
import WidgetsOrder from './WidgetsOrder';

import * as screenshots from './screenshots';

const WidgetsView = observer(({ location }) => {
    const columns = [
        {
            dataIndex: 'component',
            key: 'preview',
            width: 40,
            render: (text, record) => {
                const { componentType } = record;
                return (
                    <Popover content={<Image width={200} src={screenshots[componentType]} />} placement={'topRight'}>
                        <Button icon={<EyeOutlined />} ghost shape="circle" style={{ color: '#000', border: 'none' }} />
                    </Popover>
                );
            }
        },
        {
            title: 'Name & Component',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <Row style={{ display: 'flex' }}>
                        {record.name} - {record.componentType}
                    </Row>
                );
            }
        },
        {
            width: 90,
            title: 'Options',
            key: 'edit',
            render: (text, record) => {
                const componentType =
                    _.find(widgetsStore.availableComponents, { componentType: record.componentType }) || {};

                if (record.id === '8gxKYc65JrU5fvf4pmL3') {
                    // Hot Right Now Collections
                    componentType.advanced = true; // We do this as a temp block as this widget won't be editable anymore, but generated random in the app
                }

                const options = (
                    <>
                        <WidgetsEdit table edit record={record} title={'Edit Widget'} location={location} />
                        <DeleteIconButton onClick={() => widgetsStore.deleteWidget(record)} />
                    </>
                );
                return (
                    <Row style={{ display: 'flex' }} justify={'end'}>
                        {widgetsStore.advanced ? options : componentType.advanced ? null : options}
                    </Row>
                );
            }
        }
    ];

    return (
        <Layout style={{ backgroundColor: '#fff' }}>
            <ViewHeader>
                <WidgetsOrder
                    widgets={widgetsStore.activeComponents}
                    textButton={'Order Widgets'}
                    type={'secondary'}
                    iconButton={<OrderedListOutlined />}
                    title={'Widgets Order'}
                />
                <WidgetsEdit textButton={'New Widget'} title={'New Widget'} location={location} />
            </ViewHeader>
            <Divider orientation="left" style={{ marginTop: 60 }}>
                Active widgets
            </Divider>

            <EmTable
                bordered
                pagination={false}
                size="small"
                data={widgetsStore.activeComponents}
                rowKey={record => record.id}
                columns={columns}
            />
            {widgetsStore.staticComponents.length > 0 && (
                <>
                    <Divider orientation="left" style={{ marginTop: 60 }}>
                        Static widgets (will show statically on Today screen, at the bottom)
                    </Divider>

                    <EmTable
                        bordered
                        pagination={false}
                        size="small"
                        data={widgetsStore.staticComponents}
                        rowKey={record => record.id}
                        columns={columns}
                    />
                </>
            )}
            <Divider orientation="left" style={{ marginTop: 60 }}>
                Inactive widgets (saved for reuse)
            </Divider>
            <EmTable
                bordered
                pagination={false}
                size="small"
                data={widgetsStore.inactiveComponents}
                rowKey={record => record.id}
                columns={columns}
            />
        </Layout>
    );
});

export default WidgetsView;
