import ModalWrapper from '@components/ModalWrapper';
import React from 'react';
import { createFeatureFlag, editFeatureFlag } from '@stores/middleware/featureFlags';
import { EmForm } from '@components/EmForm';

const FeatureFlagsEdit = ({ closeModal, record }) => {
    const onSubmit = values => {
        if (record) {
            editFeatureFlag(values, record);
        } else {
            createFeatureFlag(values);
        }
        closeModal();
    };

    const fields = [
        {
            label: 'ID',
            name: 'id',
            placeholder: 'Use camelCase for ids',
            rules: [{ required: true, message: 'We need ID' }],
            disabled: record
        },
        {
            label: 'Name',
            name: 'name',
            placeholder: 'Name to display for UX',
            rules: [{ required: true, message: 'We need a name for the feature' }]
        },
        {
            label: 'Active',
            name: 'active',
            item: 'switch'
        },
        {
            label: 'Team Members',
            name: 'teamMembers',
            item: 'switch'
        },
        {
            label: 'Notes',
            name: 'notes',
            id: 'notes',
            item: 'textArea',
            placeholder: 'Additional feature Info'
        },
        {
            type: 'input',
            label: 'Deactivate Reason',
            name: 'deactivateReason',
            item: 'textArea',
            placeholder: 'Include reason why we deactivate this feature'
        },
        {
            label: 'webQuizId (Web quiz Only)',
            name: 'webQuizId'
        }
    ];

    return <EmForm fields={fields} onSubmit={onSubmit} initialValues={record} />;
};

export default ModalWrapper(FeatureFlagsEdit);
