import React from 'react';

import _ from 'lodash';
import { Button, Tabs } from 'antd';
import { observer, useLocalObservable } from 'mobx-react';

import ImageCropUpload from '@components/ImageUpload/ImageCropUpload';
import ModalWrapper from '@components/ModalWrapper';
import NewMediaUpload from '@components/NewMediaUpload';
import { EmFormBuilder } from '@components/EmFormBuilder';

import notify from '@lib/notifications';
import { defaultOptions } from '@lib/widgetsConfig';
import { newTempDocID } from '@lib/firebaseHandler';

import appStore from '@stores/app';
import { groupsStore } from '@stores/middleware/groups';

const { TabPane } = Tabs;

//TODO rename when cleaning to SessionsEdit
const NewSessionsEdit = observer(props => {
    const { record = {}, closeModal, options, images, contentType } = props;

    const state = useLocalObservable(() => ({
        get initialValues() {
            const data = record;
            _.each(defaultOptions, ({ key }) => {
                data[key] = record[key];
            });

            const inculudedGroups = groupsStore.getByContainSessionId(record.id);
            // Some values are legacy (ex. spicyness) so we need to clean them first or they will conflict with new ones Groups
            _.forEach(inculudedGroups, group => {
                delete data[group.groupType];
            });

            _.forEach(inculudedGroups, group => {
                data[group.groupType] = data[group.groupType] ? [...data[group.groupType], group.id] : [group.id];
            });
            return data;
        },
        mediaUpload: {},
        busy: false,
        uploadProgress: null,
        get processOptions() {
            return _.map(options, option => {
                if (option.id === 'id') {
                    option.widgetProps.disabled = !!record.id || !appStore.advancedMode;
                    //option.widgetProps.hidden = !appStore.advancedMode;
                }
                return option;
            });
        }
    }));

    const onPressButton = () => onSubmit();

    const onMediaChange = mediaUpload => (state.mediaUpload = mediaUpload);

    const onSubmit = async session => {
        const result = { ...record, ...session };

        state.busy = true;

        if (!result.id) {
            const sessionId = newTempDocID('sessions');
            result.id = sessionId.id;
        }

        _.mapKeys(result, (value, key) => {
            const isGroup = !!_.size(groupsStore.getByType(key));

            if (isGroup) {
                if (record[key]) {
                    if (_.isArray(record[key])) {
                        _.forEach(record[key], groupId => {
                            groupsStore.removeSessionFromSameGroupType(result.id, groupId);
                        });
                    } else {
                        groupsStore.removeSessionFromSameGroupType(result.id, record[key]);
                    }
                }
                if (result[key]) {
                    if (_.isArray(result[key])) {
                        _.forEach(result[key], groupId => {
                            groupsStore.addSessionToGroup(result.id, groupId);
                        });
                    } else {
                        groupsStore.addSessionToGroup(result.id, result[key]);
                    }
                }
                delete result[key];
            }
        });

        result.contentType = contentType === 'wellbeing' ? 'session' : contentType; // we need to keep this for legacy compatibility

        if (_.size(state.mediaUpload)) {
            result.converting = true;

            result.mediaUrl = await appStore.uploadSessionMedia({
                onProgress: progress => {
                    state.uploadProgress = progress;
                },
                name: state.mediaUpload.mediaData.name,
                blob: state.mediaUpload.mediaData.blob,
                id: result.id
            });
            result.mediaFileName = state.mediaUpload.mediaData.name;
            result.mediaDuration = state.mediaUpload.mediaData.duration;
        }

        // TODO temp fix for groups migration, can be delted after. categories are added inside session when they shouldnt because they belong to joins
        if (result.categories) {
            delete result.categories;
        }

        try {
            await appStore.newUpdateSession(result);

            const contentTypeGroup = _.find(groupsStore.allGroupsData, { groupType: 'contentType', id: contentType });
            const sessions = _.union(contentTypeGroup.sessions, [result.id]);

            await groupsStore.saveGroup({
                ...contentTypeGroup,
                sessions
            });

            notify('success', 'updateSession');
        } catch (error) {
            notify('error', 'updateSession');
        }

        state.busy = false;
        closeModal();
    };

    const form = (
        <EmFormBuilder
            meta={{
                initialValues: state.initialValues,
                fields: state.processOptions
            }}
            initialValues={record}
            loading={state.busy}
            onSubmit={onSubmit}
        />
    );

    const setContent = content => {
        _.assign(record, content);
    };

    const buildTabs = tab => {
        switch (tab) {
            case 'images':
                return (
                    <TabPane tab={'Images'} key={'images'}>
                        <div style={{ margin: 50 }}>
                            {_.isArray(images) ? (
                                _.map(images, (img, i) => {
                                    return (
                                        <div style={{ marginTop: 50, marginRight: 30 }} key={i}>
                                            {i === 1 && (
                                                <span style={{ opacity: '0.5', marginBottom: 20 }}>
                                                    Here we need to upload the bigger image size we have, 2700 pixels at
                                                    least
                                                </span>
                                            )}
                                            <ImageCropUpload imagesFormat={img} content={record} onSave={setContent} />
                                        </div>
                                    );
                                })
                            ) : (
                                <ImageCropUpload imagesFormat={images} content={record} onSave={setContent} />
                            )}
                        </div>
                        <Button loading={state.busy} onClick={onPressButton}>
                            Save
                        </Button>
                    </TabPane>
                );
            case 'media':
                return (
                    <TabPane tab={'Media'} key={'media'}>
                        <NewMediaUpload
                            browse
                            onMediaChange={onMediaChange}
                            session={record}
                            uploadProgress={state.uploadProgress}
                        />
                        <Button loading={state.busy} onClick={onPressButton}>
                            Save
                        </Button>
                    </TabPane>
                );
            default:
                return (
                    <TabPane tab={'Data'} key={'data'}>
                        {form}
                    </TabPane>
                );
        }
    };

    return (
        <Tabs defaultActiveKey={'data'} animated={false} type="card">
            {_.map(['data', 'images', 'media'], buildTabs)}
        </Tabs>
    );
});

export default ModalWrapper(NewSessionsEdit);
