import React, { Component } from 'react';

import { Button, message } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import _ from 'lodash';
import appStore from '@stores/app';
import OrderListItems from '@components/OrderListItems/OrderListItems';
import { updateOrderSessions } from '@stores/middleware/sessions';
import { ViewHeader } from '@components/ViewHeader';

@observer
class StoriesOrder extends Component {
    @observable stories = null;
    @observable busy = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get filteredData() {
        return _.chain(appStore.sessions)
            .filter(session => {
                return session.contentType === 'story';
            })
            .sortBy('order')
            .value();
    }

    onChange = result => {
        this.stories = result;
    };

    onSave = async () => {
        this.busy = true;
        await updateOrderSessions(this.stories);
        message.success('Story order updated!');
        this.busy = false;
    };

    render() {
        return (
            <>
                <ViewHeader>
                    <Button onClick={this.onSave} type="primary" loading={this.busy}>
                        Update Order
                    </Button>
                </ViewHeader>
                <OrderListItems isContent items={this.stories || this.filteredData} onChange={this.onChange} />
            </>
        );
    }
}

export default StoriesOrder;
