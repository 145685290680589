import React, { Component } from 'react';

import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';

import { Button, Card, Input, Layout, Row } from 'antd';

import appStore from '../Stores/app';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

@observer
class HorizontalLoginForm extends Component {
    @observable error;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const loginRes = await appStore.logIn(values);
                if (loginRes.error) {
                    this.error = loginRes.error?.message;
                }
            }
        });
    };

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        // Only show error after a field is touched.
        const emailError = isFieldTouched('email') && getFieldError('email');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        return (
            <Layout style={{ height: '100vh' }}>
                <Row type="flex" justify="center" align="middle" style={{ height: '100%' }}>
                    <Card style={{ width: 400 }}>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
                                {getFieldDecorator('email', {
                                    rules: [{ required: true, message: 'Please input your email!' }]
                                })(
                                    <Input
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="email"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please input your Password!' }]
                                })(
                                    <Input
                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                        <h3>{this.error}</h3>
                    </Card>
                </Row>
            </Layout>
        );
    }
}

const WrappedHorizontalLoginForm = Form.create({ name: 'horizontal_login' })(HorizontalLoginForm);

export default WrappedHorizontalLoginForm;
