import React, { Component } from 'react';
import { Button, Row, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

class UploadFileRow extends Component {
    beforeUpload = file => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.props.onChange(reader.result);
        });
        reader.readAsDataURL(file);
    };

    render() {
        return (
            <Row type="flex" style={this.props.style || { justifyContent: 'center' }}>
                <Upload showUploadList={false} beforeUpload={this.beforeUpload}>
                    <Button>
                        <UploadOutlined /> Upload image
                    </Button>
                </Upload>
            </Row>
        );
    }
}

export default UploadFileRow;
