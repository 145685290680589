import React from 'react';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Row, Table } from 'antd';

import _ from 'lodash';
import { observer } from 'mobx-react';

const EmTable = observer(props => {
    const { data = [], columns, onDelete, onEdit } = props;
    const createColumns = [...columns];

    if (onEdit) {
        createColumns.push({
            title: 'Edit',
            width: 90,
            key: 'edit',
            // eslint-disable-next-line react/display-name
            render: (text, record) => {
                return (
                    <Row>
                        <EditTwoTone
                            onClick={() => {
                                onEdit(record);
                            }}
                        />
                    </Row>
                );
            }
        });
    }

    if (onDelete) {
        createColumns.push({
            title: 'Delete',
            width: 90,
            key: 'delete',
            // eslint-disable-next-line react/display-name
            render: (text, record) => {
                return (
                    <Row>
                        <DeleteTwoTone
                            twoToneColor="#eb2f96"
                            onClick={e => {
                                e.preventDefault();
                                onDelete(record);
                            }}
                        />
                    </Row>
                );
            }
        });
    }
    return (
        <Table
            {...props}
            dataSource={[...data]} //without this the table won't re-render on prop change 🤦‍♂️
            columns={createColumns}
            style={{ backgroundColor: '#fff' }}
            pagination={{ position: 'bottomRight' }}
            rowKey={record =>
                //record.product + record.userType fix for referralCampaignReward so doenst complain about unqiue ids on table
                record.id || record.code || record.name || record.product + record.userType || record.dataIndex
            }
        />
    );
});

export { EmTable };
