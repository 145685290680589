import _ from 'lodash';
import React, { useState } from 'react';
import { AutoComplete } from 'antd';

const { Option } = AutoComplete;

const EmAutoComplete = ({ values, onSelect, placeholder }) => {
    const [options, setOptions] = useState(values);
    const [selectedValue, setSelectedValue] = useState('');

    const onSearch = searchText => {
        setOptions(_.filter(values, value => _.includes(value.name.toLowerCase(), searchText.toLowerCase())));
    };

    const onChange = value => {
        setSelectedValue(value);
    };

    const onSelectItem = id => {
        const selectedItem = _.find(values, { id });
        onSelect(selectedItem);
        setSelectedValue('');
        setOptions(values);
    };

    const children = options.map(value => (
        <Option key={value.id} value={value.id}>
            {value.name}
        </Option>
    ));

    return (
        <AutoComplete
            style={{ width: 300 }}
            placeholder={placeholder || 'Type to search...'}
            onSelect={onSelectItem}
            onSearch={onSearch}
            onChange={onChange}
            value={selectedValue}
        >
            {children}
        </AutoComplete>
    );
};

export { EmAutoComplete };
