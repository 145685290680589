import React, { Component } from 'react';

import _ from 'lodash';
import { Button, Col, Divider, Layout, Modal, Radio, Row } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import notify from '@lib/notifications';
import ReactCropWrapper from './ReactCropWrapper';
import UploadFileRow from '@components/ImageUpload/UploadFileRow';
import UploadUnsplash from '@components/ImageUpload/UploadUnsplash';

@observer
class ImageCropUpload extends Component {
    @observable sourceImage = null;
    @observable currentCrop = this.props.imagesFormat[0];
    @observable images = {};
    @observable imagesUrls = {};
    @observable visible = false;
    @observable busy = false;

    changeSourceImage = image => {
        this.sourceImage = image;
    };

    clearImages = () => {
        this.sourceImage = null;
        this.images = {};
    };

    changeCroppedImage = image => {
        this.images = { ...this.images, [image.id]: image };
    };

    changeCurrentCrop = e => {
        this.currentCrop = _.find(this.props.imagesFormat, { id: e.target.value });
    };

    saveImages = async () => {
        this.busy = true;
        await this.saveImagesToStorage();
        this.props.onSave({
            ...this.imagesUrls,
            imageProcessed: false
        });

        this.busy = false;
        this.visible = false;
    };

    showModal = () => {
        this.visible = true;
    };
    closeModal = () => {
        this.visible = false;
    };

    saveImagesToStorage = async () => {
        try {
            const keys = Object.keys(this.images);

            for (let i = 0; i < keys.length; i++) {
                const image = keys[i];
                const url = await appStore.uploadImage({
                    blob: this.images[image].blob,
                    path: this.images[image].path,
                    name: `${this.images[image].name}.webp`,
                    id: this.props.content.id
                });

                this.imagesUrls = { ...this.imagesUrls, [image]: url };
            }
        } catch (error) {
            //console.error('uploadImage An error occured while uploading author image', error);
            notify('error', 'uploadImage');
        }
    };

    showComponents = () => {
        if (this.sourceImage) {
            return (
                <ReactCropWrapper
                    useNaturalHeight={
                        this.props.content?.groupType === 'businessPartners' && this.props.content?.type === 'brand'
                        // TODO this should not be here, the cropper should be able to handle any image height
                    }
                    src={this.sourceImage}
                    onChange={this.changeCroppedImage}
                    currentCrop={this.currentCrop}
                />
            );
        } else {
            return (
                <>
                    <UploadFileRow onChange={this.changeSourceImage} />
                    {this.props.unsplash && (
                        <>
                            <Divider>OR</Divider>
                            <UploadUnsplash onChange={this.changeSourceImage} />
                        </>
                    )}
                </>
            );
        }
    };

    getContentImage = id => {
        // TODO all this is pretty bad, we should make a service/method that returns the image url and not all this
        if (this.props.content?.[id]) {
            return this.props.content[id];
        } else if (this.props.content?.data?.[id]) {
            return this.props.content?.data?.[id];
        } else if (this.props.content?.images?.[id]?.original) {
            return this.props.content?.images?.[id]?.original;
        } else {
            return null;
        }
    };

    getContextImage = id => {
        return this.images[id] ? this.images[id].src : null;
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <Row type="flex" style={{ alignItems: 'center' }}>
                    <Button onClick={this.showModal}>Set Images</Button>
                    {this.props.imagesFormat.map(image => {
                        return (
                            <img
                                key={image.id}
                                src={this.getContentImage(image.id)}
                                style={{
                                    width: image.thumb[0],
                                    height: image.thumb[1],
                                    backgroundColor: 'grey',
                                    margin: '0 10px'
                                }}
                            />
                        );
                    })}

                    <Modal
                        title="Add / Edit Images"
                        destroyOnClose
                        maskClosable={false}
                        width={950}
                        style={{ top: 20 }}
                        visible={this.visible}
                        onCancel={() => this.closeModal()}
                        footer={[
                            <Button key="clear" style={{ float: 'left' }} type="danger" onClick={this.clearImages}>
                                CLEAR
                            </Button>,
                            <Button key="cancel" onClick={this.closeModal}>
                                Cancel
                            </Button>,
                            <Button key="save" type="primary" loading={this.busy} onClick={this.saveImages}>
                                Save Images
                            </Button>
                        ]}
                    >
                        <Row>
                            <Col span={20} style={{ padding: 24 }} align="middle">
                                {this.showComponents()}
                            </Col>
                            <Col span={4}>
                                <Radio.Group
                                    value={this.currentCrop.id}
                                    buttonStyle="solid"
                                    onChange={this.changeCurrentCrop}
                                >
                                    {this.props.imagesFormat.map(image => {
                                        return (
                                            <Row type="flex" justify="center" style={{ margin: 24 }} key={image.id}>
                                                <Radio value={image.id} style={{ padding: '10px 0' }}>
                                                    {image.name}
                                                </Radio>
                                                <img
                                                    src={this.getContextImage(image.id)}
                                                    style={{
                                                        width: image.thumb[0],
                                                        height: image.thumb[1],
                                                        backgroundColor: 'grey'
                                                    }}
                                                />
                                            </Row>
                                        );
                                    })}
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Modal>
                </Row>
            </Layout>
        );
    }
}

export default ImageCropUpload;
