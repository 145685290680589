import firebase from 'firebase';

const functions = firebase.app().functions('europe-west1');

// Use a local emulator in development, uncomment
//functions.useEmulator('localhost', 5001); // make sure port is the right one

const callFirebaseFunction = async (functionName, data) => {
    try {
        const response = await functions.httpsCallable(functionName)(data);
        return response;
    } catch (error) {
        return { error };
    }
};

export { callFirebaseFunction };
