import React from 'react';

import _ from 'lodash';
import { observer, useLocalObservable } from 'mobx-react';

import appStore from '@stores/app';
import ReferralCampaignsEdit from './ReferralCampaignsEdit';
import { Col, Input, Row } from 'antd';
import { deleteCampaign } from '@stores/middleware/referrals';
import { EditTwoTone } from '@ant-design/icons';
import { EmTable } from '@components/EmTable';
import { Layout } from 'antd';
import { ViewHeader } from '@components/ViewHeader';

const { Content } = Layout;

const ReferralCampaignsView = observer(() => {
    const state = useLocalObservable(() => {
        return {
            searchText: null,
            get campaigns() {
                return _.filter(appStore.referralCampaings, campaign => campaign.name);
            },

            get filteredData() {
                return this.searchText
                    ? _.filter(this.campaigns, item => {
                          return item?.name.match(new RegExp(this.searchText, 'gi'));
                      })
                    : this.campaigns;
            }
        };
    });

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            // eslint-disable-next-line react/display-name
            render: (text, record) => (
                <Row>
                    <Col span={6}>
                        <ReferralCampaignsEdit
                            title={'Edit campaign details'}
                            table
                            record={record}
                            iconButton={<EditTwoTone style={{ fontSize: 20 }} twoToneColor="#eb2f96" />}
                        />
                    </Col>
                </Row>
            )
        }
    ];

    // disabled delete to avoid errors, we can edit direct in DB in case is needed
    // eslint-disable-next-line no-unused-vars
    const onDelete = value => deleteCampaign(value);

    const handleChangeFilter = e => {
        state.searchText = e.target.value;
    };

    return (
        <Layout style={{ backgroundColor: '#fff' }}>
            <ViewHeader
                input={
                    <Input
                        type="text"
                        style={{ width: '44%' }}
                        allowClear
                        name="filter"
                        onChange={handleChangeFilter}
                        value={state.searchText}
                        placeholder="Filter by name..."
                    />
                }
            >
                <ReferralCampaignsEdit title={'Add new Campaign'} textButton={'Add Campaign'} />
            </ViewHeader>
            <Content>
                <EmTable data={state.filteredData} columns={columns} /*onDelete={onDelete}*/ />
            </Content>
        </Layout>
    );
});

export { ReferralCampaignsView };
