import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import 'react-quill/dist/quill.snow.css';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import { Button, Checkbox, Input, Layout, Modal, Row, Select, Table } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';
import { PlusCircleOutlined } from '@ant-design/icons';

import appStore from '@stores/app';
import FormBuilder from '@lib/FormBuilder';
import notify from '@lib/notifications';
import { DeleteIconButton, EditIconButton } from '@components/Buttons';
import { newTempDocID } from '@lib/firebaseHandler';
import { ViewHeader } from '@components/ViewHeader';

const { Option } = Select;

const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], ['link'], ['clean']]
};

// We are leaving this commented as template in case we need to add something to modules constant.
// const modules = {
//     toolbar: [
//         [{ header: [1, 2, false] }],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
//         ['link', 'image'],
//         ['clean']
//     ]
// };

const formItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 20
    }
};

@observer
class LegalView extends Component {
    @observable adding = false;
    @observable editing = false;

    @observable page = { type: 'text', openInApp: false };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get currentPageOrder() {
        return this.page.order || appStore.pages.length + 1;
    }

    @computed get orderArray() {
        return _.fill(Array(appStore.pages.length), '');
    }

    @computed get pageMeta() {
        const { type } = this.page;

        const textItem = {
            key: 'text',
            label: 'Text',
            initialValue: this.page.description,
            render: args => {
                return (
                    <Form.Item {...args.formItemProps}>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            defaultValue={this.page.text}
                            onChange={this.updateText}
                            style={{ height: 250, marginBottom: 50 }}
                        />
                    </Form.Item>
                );
            }
        };

        const linkItem = {
            key: 'link',
            label: 'Link',
            widget: Input,
            initialValue: this.page.link
        };

        const openInAppItem = {
            key: 'openInApp',
            label: 'Try to open in app',
            widget: Checkbox,
            widgetProps: {
                checked: this.page.openInApp,
                onChange: e => {
                    this.page.openInApp = e.target.checked;
                }
            }
        };

        const elements = [
            {
                key: 'name',
                label: 'Name',
                widget: Input,
                required: true,
                initialValue: this.page.name,
                rules: [
                    {
                        validator: (r, value, cb) => {
                            const nodupe = _.isEmpty(
                                _.pickBy(appStore.pages, item => {
                                    return item.name == value && this.page.id != item.id;
                                })
                            );

                            cb(nodupe ? undefined : false);
                        },
                        message: 'Duplicate page'
                    }
                ]
            },

            {
                key: 'type',
                label: 'Type',
                widget: Select,
                widgetProps: {
                    onChange: type => {
                        this.page.type = type;
                    }
                },
                initialValue: this.page.type,

                children: [
                    <Option key="link" value="link">
                        Link
                    </Option>,
                    <Option key="text" value="text">
                        Text
                    </Option>
                ]
            },
            {
                key: 'order',
                label: 'Order',
                widget: Select,
                widgetProps: {},
                initialValue: this.currentPageOrder,

                children: this.orderArray.map((item, idx) => {
                    const order = idx + 1;

                    return (
                        <Option key={order} value={order}>
                            {idx + 1}
                        </Option>
                    );
                })
            }
        ];

        if (type === 'link') {
            elements.splice(2, 0, linkItem, openInAppItem);
        }
        if (type === 'text') {
            elements.splice(2, 0, textItem);
        }

        return {
            formItemLayout,
            elements
        };
    }

    updateText = text => {
        this.page.text = text;
    };

    onAddPage = () => {
        const newPage = newTempDocID('pages');
        this.page.id = newPage.id;
        this.adding = true;
    };

    onModalOk = async () => {
        this.props.form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return false;
            }

            _.assign(this.page, _.pickBy(values));

            if (this.adding) {
                try {
                    this.adding = false;
                    await appStore.addPage(this.page);
                    this.resetPage();
                    notify('success', 'addPage');
                } catch (error) {
                    notify('error', 'addPage');
                }
            }
            if (this.editing) {
                try {
                    this.editing = false;
                    await appStore.updatePage(this.page);
                    this.resetPage();
                    notify('success', 'updatePage');
                } catch (error) {
                    notify('error', 'updatePage');
                }
            }
        });
    };

    onModalCancel = () => {
        this.adding = false;
        this.editing = false;
        this.resetPage();
    };

    onDeletePage = async record => {
        try {
            await appStore.deletePage(record.id);
            notify('success', 'deletePage');
        } catch (error) {
            notify('error', 'deletePage');
        }
    };

    onEditPage = record => {
        this.editing = true;
        this.page = record;
    };

    resetPage = () => {
        this.page = { type: 'text', openInApp: false };
    };

    columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },

        {
            width: 90,
            key: 'edit',
            render: (text, record) => {
                return (
                    <Row>
                        <EditIconButton
                            onClick={() => {
                                this.onEditPage(record);
                            }}
                        />
                        {!record.readOnly && (
                            <DeleteIconButton
                                onClick={() => {
                                    this.onDeletePage(record);
                                }}
                            />
                        )}
                    </Row>
                );
            }
        }
    ];

    render() {
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <ViewHeader>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={this.onAddPage}>
                        New Page
                    </Button>
                </ViewHeader>

                <Table
                    bordered
                    size="small"
                    dataSource={appStore.pages}
                    rowKey={record => record.id}
                    columns={this.columns}
                />

                <Modal
                    destroyOnClose
                    maskClosable={false}
                    closable={false}
                    width={1000}
                    zIndex={10}
                    title={this.adding ? 'Add a new page' : 'Edit page'}
                    visible={this.adding || this.editing}
                    onOk={this.onModalOk}
                    onCancel={this.onModalCancel}
                >
                    <Form>
                        <FormBuilder meta={this.pageMeta} form={this.props.form} />
                    </Form>
                </Modal>
            </Layout>
        );
    }
}

export default Form.create()(LegalView);
