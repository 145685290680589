import _ from 'lodash';
import { observable } from 'mobx';

import appStore from './app';
import { groupsStore } from './middleware/groups';

const sessionStore = observable({
    get allContent() {
        return appStore.sessions;
    },
    getSingleById(id) {
        return _.find(this.allContent, { id });
    },
    getMultipleByIds(ids) {
        return _.chain(ids)
            .map(id => this.getSingleById(id))
            .compact()
            .value();
    },
    get allWellbeing() {
        const wellbeingGroup = _.find(groupsStore.allGroupsData, { groupType: 'contentType', id: 'wellbeing' });
        return _.chain(this.getMultipleByIds(wellbeingGroup?.sessions)).sortBy('name').value();
    },
    get allStories() {
        const storyGroup = _.find(groupsStore.allGroupsData, { groupType: 'contentType', id: 'story' });
        return _.chain(this.getMultipleByIds(storyGroup?.sessions)).sortBy('name').value();
    },
    get allSessions() {
        return _.chain([...this.allStories, ...this.allWellbeing])
            .sortBy('name')
            .value();
    },
    deleteSession(sessionId) {
        groupsStore.deleteSessionFromGroups(sessionId);
        appStore.deleteDocument('sessions', { id: sessionId });
    },

    // TODO CLEAN AFTER MIGRATION FROM HERE TO BOTTOM
    async fixContentType() {
        const wellbeingGroup = _.find(groupsStore.allGroupsData, { groupType: 'contentType', id: 'wellbeing' });
        const oldWellbeingSessions = _.filter(this.allContent, session => session.contentType !== 'story');
        const fixedSessions = _.map(oldWellbeingSessions, session => session.id);
        await groupsStore.saveGroup({
            ...wellbeingGroup,
            sessions: fixedSessions
        });

        // eslint-disable-next-line no-console
        console.log('fixContentType', oldWellbeingSessions.length, 'wellbeing', wellbeingGroup, fixedSessions);

        const storiesGroup = _.find(groupsStore.allGroupsData, { groupType: 'contentType', id: 'story' });
        const oldStoriesSessions = _.filter(this.allContent, session => session.contentType === 'story');
        const fixedStories = _.map(oldStoriesSessions, session => session.id);
        await groupsStore.saveGroup({
            ...storiesGroup,
            sessions: fixedStories
        });

        // eslint-disable-next-line no-console
        console.log('fixContentType', oldStoriesSessions.length, 'story', storiesGroup, fixedStories);
    },
    fixSessionsInsideCollections() {
        const data = _.orderBy(appStore.joins.relationships?.orderedSessionsCategory, 'sessionOrder');
        const result = {};
        _.forEach(data, session =>
            result[session.categoryId]
                ? result[session.categoryId].push(session.sessionId)
                : (result[session.categoryId] = [session.sessionId])
        );
        _.mapKeys(result, (value, key) => {
            groupsStore.addSessionsToGroup(value, key);
        });

        // eslint-disable-next-line no-console
        console.log('fixSessionsInsideCollections', _.size(result), 'categories');
    },
    async fixSpicyness() {
        _.forEach([1, 2, 3], async value => {
            const groupData = _.find(groupsStore.allGroupsData, { spicynessNumber: value });
            const sessions = _.chain(this.allStories).filter({ spicyness: value }).map('id').value();
            await groupsStore.saveGroup({ ...groupData, sessions });

            // eslint-disable-next-line no-console
            console.log('fixSpicyness', sessions.length, 'sessions');
        });
    },
    async fixSessionType() {
        // this is the only place we keep stories as session type, to be legacy compatible
        _.forEach(['guided', 'foodforthought', 'theory', 'stories'], async value => {
            const groupData = _.find(groupsStore.allGroupsData, { id: value });
            const sessions = _.chain(this.allSessions).filter({ sessionType: value }).map('id').value();
            await groupsStore.saveGroup({ ...groupData, sessions });

            // eslint-disable-next-line no-console
            console.log('fixSessionType', sessions.length, 'sessions');
        });
    }
});

export { sessionStore };
