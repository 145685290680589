import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import app from 'firebase/app';
import config from './config';

const fbConfig = {
    apiKey: config.apiKey,
    authDomain: config.authDomain,
    databaseURL: config.databaseURL,
    projectId: config.projectId,
    storageBucket: config.storageBucket,
    messagingSenderId: config.messagingSenderId,
    appId: config.appId
};

class Firebase {
    constructor() {
        app.initializeApp(fbConfig);
        this.auth = app.auth();
        this.storage = app.storage();
        this.app = app;
    }

    signInWithEmailAndPassword = async ({ email, password }) => {
        return await this.auth.signInWithEmailAndPassword(email, password);
    };

    signOut = () => this.auth.signOut();
}

const fbase = new Firebase();

const fbaseEnvName = app.apps[0].options.projectId;

export { fbase, fbaseEnvName };
