import React from 'react';

import { Col, Row } from 'antd';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import ReferralEventsEdit from './ReferralEventsEdit';
import { deleteEvent } from '@stores/middleware/referrals';
import { EditTwoTone } from '@ant-design/icons';
import { EmTable } from '@components/EmTable';
import { Layout } from 'antd';
import { ViewHeader } from '@components/ViewHeader';

const { Content } = Layout;

const ReferralEventsView = observer(() => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Event',
            dataIndex: 'event'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            // eslint-disable-next-line react/display-name
            render: (text, record) => (
                <Row>
                    <Col span={6}>
                        <ReferralEventsEdit
                            title={'Edit event details'}
                            table
                            record={record}
                            iconButton={<EditTwoTone style={{ fontSize: 20 }} twoToneColor="#eb2f96" />}
                        />
                    </Col>
                </Row>
            )
        }
    ];

    // disabled delete to avoid errors, we can edit direct in DB in case is needed
    // eslint-disable-next-line no-unused-vars
    const onDelete = value => deleteEvent(value);

    return (
        <Layout style={{ backgroundColor: '#fff' }}>
            <ViewHeader>
                <ReferralEventsEdit title={'Add a new event'} textButton={'Add Event'} />
            </ViewHeader>
            <div style={{ color: 'red', padding: 10 }}>This will be edited by TECH team only</div>
            <Content>
                <EmTable data={appStore.referralEvents} columns={columns} /*onDelete={onDelete}*/ />
            </Content>
        </Layout>
    );
});

export { ReferralEventsView };
