import React from 'react';

import UploadFileRow from '@components/ImageUpload/UploadFileRow';

const FormImageUpload = ({ value = null, onChange }) => {
    return (
        <div style={{ display: 'flex' }}>
            <UploadFileRow onChange={onChange} />
            {value && <img src={value} style={{ maxWidth: 210, margin: '0 20px' }} />}
        </div>
    );
};

export { FormImageUpload };
