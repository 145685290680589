import _ from 'lodash';
import { Collection, Document, initFirestorter } from 'firestorter';
import { computed, makeObservable, observable, toJS, when } from 'mobx';
import { fbase } from '../lib/firebase';

import { callFirebaseFunction } from '@lib/firebaseFunctions';
import { newTempDocID } from '@lib/firebaseHandler';

const cleanEmpty = params => {
    return _.pickBy(toJS({ ...params }), item => {
        return !_.isNil(item);
    });
};

class Store {
    @observable loading = true;
    @observable user = null;
    @observable advancedMode = false;

    switchAdvancedMode = () => (this.advancedMode = !this.advancedMode);

    //REUSABLES

    updateDocument = async (path, params, merge) => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();

        if (!params.id) {
            const newGroupId = newTempDocID(path);
            params.id = newGroupId.id;
        }

        const doc = new Document(`${path}/${params.id}`);
        await doc.set(params, { merge: merge || true });
        return params;
    };

    deleteDocument = async (path, params) => {
        const doc = new Document(`${path}/${params.id}`);
        await doc.delete();
    };

    //

    addCategory = async params => {
        const newCategory = newTempDocID('categories');

        if (_.isNil(params.order)) {
            params.order = this.categories.length + 1;
        }
        if (_.isNil(params.id)) {
            params.id = newCategory.id;
        }

        await this.updateCategory(cleanEmpty(params));
    };

    addTag = async params => {
        const newTag = newTempDocID('tags');

        if (_.isNil(params.id)) {
            params.id = newTag.id;
        }

        if (_.isNil(params.order)) {
            params.order = this.tags.length + 1;
        }

        await this.updateTag(cleanEmpty(params));
    };

    addAuthor = async params => {
        const newAuthor = newTempDocID('authors');

        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();
        params.id = newAuthor.id;

        await this.updateAuthor(cleanEmpty(params));
    };

    addSession = async params => {
        const newSession = newTempDocID('sessions');

        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();
        params.id = newSession.id;

        await this.updateSession(cleanEmpty(params));
    };

    addPage = async params => {
        const newPage = newTempDocID('pages');

        if (_.isNil(params.id)) {
            params.id = newPage.id;
        }

        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();
        await this.updatePage(cleanEmpty(params));
    };

    deleteCategory = async id => {
        const doc = new Document(`categories/${id}`);
        const writeBatch = fbase.app.firestore().batch();
        const sortRef = fbase.app.firestore().doc('joins/relationships');

        const tempTags = _.filter(this.joins.relationships.orderedCategoriesTag, { categoryId: id });

        _.pullAllBy(this.joins.relationships.orderedCategoriesTag, [{ categoryId: id }], 'categoryId');
        _.pullAllBy(this.joins.relationships.orderedSessionsCategory, [{ categoryId: id }], 'categoryId');

        _.each(tempTags, tempItem => {
            let newTags = _.filter(this.joins.relationships.orderedCategoriesTag, { tagId: tempItem.tagId });
            _.pullAllBy(this.joins.relationships.orderedCategoriesTag, [{ tagId: tempItem.tagId }], 'tagId');

            _.each(newTags, (newItem, idx) => {
                newTags[idx].categoryOrder = idx + 1;
            });

            this.joins.relationships.orderedCategoriesTag = _.concat(
                this.joins.relationships.orderedCategoriesTag,
                newTags
            );
        });

        writeBatch.set(sortRef, this.joins.relationships);
        await writeBatch.commit();

        await doc.delete();
    };

    deleteTag = async id => {
        const doc = new Document(`tags/${id}`);

        const writeBatch = fbase.app.firestore().batch();
        const sortRef = fbase.app.firestore().doc('joins/relationships');

        _.pullAllBy(this.joins.relationships.orderedCategoriesTag, [{ tagId: id }], 'tagId');

        writeBatch.set(sortRef, this.joins.relationships);
        await writeBatch.commit();

        await doc.delete();
    };

    deleteAuthor = async id => {
        const doc = new Document(`authors/${id}`);
        await doc.delete();
    };

    deleteSession = async id => {
        const doc = new Document(`sessions/${id}`);
        const writeBatch = fbase.app.firestore().batch();
        const sortRef = fbase.app.firestore().doc('joins/relationships');

        _.pullAllBy(this.joins.relationships.orderedSessionsCategory, [{ sessionId: id }], 'sessionId');

        writeBatch.set(sortRef, this.joins.relationships);
        await writeBatch.commit();

        await doc.delete();
    };

    deletePage = async id => {
        const doc = new Document(`pages/${id}`);
        await doc.delete();
    };

    updateCategory = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();

        const writeBatch = fbase.app.firestore().batch();
        const ref = fbase.app.firestore().doc(`categories/${params.id}`);
        const sortRef = fbase.app.firestore().doc('joins/relationships');
        let tempTags = [];
        params.tags.push('All');

        params.tags.forEach(tagId => {
            const tag = _.find(this.tags, { id: tagId });

            if (tag) {
                const length = _.filter(this.joins.relationships.orderedCategoriesTag, { tagId: tagId }).length;

                tempTags.push({
                    tagId: tagId,
                    tagName: tag.name,
                    categoryId: params.id,
                    categoryName: params.name,
                    categoryOrder: length + 1
                });
            }
        });

        delete params.tags;

        const existing = _.filter(this.joins.relationships.orderedCategoriesTag, { categoryId: params.id }).map(item =>
            toJS(item)
        );

        tempTags = _.uniqBy(tempTags, 'tagId');

        const added = _.differenceBy(tempTags, existing, 'tagId');
        const removed = _.differenceBy(existing, tempTags, 'tagId');

        _.pullAllWith(this.joins.relationships.orderedCategoriesTag, removed, (f, s) => {
            return f.tagId == s.tagId && f.categoryId == s.categoryId;
        });

        let renew = [];

        _.each(removed, ({ tagId }) => {
            const leftPerTag = _.chain(this.joins.relationships.orderedCategoriesTag)
                .filter({ tagId: tagId })
                .sortBy('categoryOrder')
                .value();

            _.each(leftPerTag, (item, idx) => {
                item.categoryOrder = idx + 1;
            });

            renew = _.concat(renew, leftPerTag);

            _.pullAllBy(this.joins.relationships.orderedCategoriesTag, [{ tagId }], 'tagId');
        });

        _.each(added, (item, idx) => {
            item.categoryOrder =
                _.filter(this.joins.relationships.orderedCategoriesTag, { tagId: item.tagId }).length + idx + 1;
        });

        this.joins.relationships.orderedCategoriesTag = _.concat(
            this.joins.relationships.orderedCategoriesTag,
            renew,
            added
        );

        _.each(this.joins.relationships.orderedSessionsCategory, item => {
            if (item.categoryId == params.id) {
                item.categoryName = params.name;
            }
        });

        _.each(this.joins.relationships.orderedCategoriesTag, item => {
            if (item.categoryId == params.id) {
                item.categoryName = params.name;
            }
        });

        writeBatch.set(sortRef, this.joins.relationships);

        writeBatch.set(ref, params);

        await writeBatch.commit();
    };

    updateTag = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();

        const writeBatch = fbase.app.firestore().batch();
        const jsonTags = toJS(this.tags);

        const sortRef = fbase.app.firestore().doc('joins/relationships');

        if (jsonTags.length) {
            _.remove(jsonTags, { id: params.id });
            jsonTags.splice(params.order - 1, 0, toJS(params));
        } else {
            jsonTags.push(params);
        }

        for (let i = 0; i < jsonTags.length; i++) {
            const ref = fbase.app.firestore().doc(`tags/${jsonTags[i].id}`);
            if (jsonTags[i].id == 'All') {
                jsonTags[i].order = -1000000;
            } else {
                jsonTags[i].order = i + 1;
            }
            writeBatch.set(ref, jsonTags[i]);
        }

        const renamedTags = _.filter(this.joins.relationships.orderedCategoriesTag, { tagId: params.id });

        _.pullAllBy(this.joins.relationships.orderedCategoriesTag, [{ tagId: params.id }], 'tagId');

        _.each(renamedTags, item => {
            item.tagName = params.name;
        });

        this.joins.relationships.orderedCategoriesTag = _.concat(
            this.joins.relationships.orderedCategoriesTag,
            toJS(renamedTags)
        );

        writeBatch.set(sortRef, this.joins.relationships);
        await writeBatch.commit();
    };

    updateSession = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();

        const writeBatch = fbase.app.firestore().batch();
        const ref = fbase.app.firestore().doc(`sessions/${params.id}`);
        const sortRef = fbase.app.firestore().doc('joins/relationships');
        const tempCategs = [];

        params.categories &&
            params.categories.forEach(categoryId => {
                const category = _.find(this.categories, { id: categoryId });
                if (category) {
                    const length = _.filter(this.joins.relationships.orderedSessionsCategory, {
                        sessionId: categoryId
                    }).length;

                    tempCategs.push({
                        categoryId: categoryId,
                        categoryName: category.name,
                        sessionId: params.id,
                        sessionName: params.name,
                        sessionOrder: length + 1
                    });
                }
            });

        delete params.categories;

        const existing = _.filter(this.joins.relationships.orderedSessionsCategory, {
            sessionId: params.id
        }).map(item => toJS(item));

        const added = _.differenceBy(tempCategs, existing, 'categoryId');
        const removed = _.differenceBy(existing, tempCategs, 'categoryId');

        _.pullAllWith(this.joins.relationships.orderedSessionsCategory, removed, (f, s) => {
            return f.categoryId == s.categoryId && f.sessionId == s.sessionId;
        });

        let renew = [];

        _.each(removed, ({ categoryId }) => {
            const leftPerCategory = _.chain(this.joins.relationships.orderedSessionsCategory)
                .filter({ categoryId: categoryId })
                .sortBy('sessionOrder')
                .value();

            _.each(leftPerCategory, (item, idx) => {
                item.sessionOrder = idx + 1;
            });

            renew = _.concat(renew, leftPerCategory);

            _.pullAllBy(this.joins.relationships.orderedSessionsCategory, [{ categoryId }], 'categoryId');
        });

        _.each(added, (item, idx) => {
            item.sessionOrder =
                _.filter(this.joins.relationships.orderedSessionsCategory, { categoryId: item.categoryId }).length +
                idx +
                1;
        });

        this.joins.relationships.orderedSessionsCategory = _.concat(
            this.joins.relationships.orderedSessionsCategory,
            renew,
            added
        );

        _.each(this.joins.relationships.orderedSessionsCategory, item => {
            if (item.sessionId == params.id) {
                item.sessionName = params.name;
            }
        });

        writeBatch.set(sortRef, this.joins.relationships);
        writeBatch.set(ref, params);

        await writeBatch.commit();
    };

    newUpdateSession = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();

        const doc = new Document(`sessions/${params.id}`);
        await doc.set({ ...cleanEmpty(params) }, { merge: true });
    };

    updateAuthor = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();

        const doc = new Document(`authors/${params.id}`);
        await doc.set({ ...cleanEmpty(params) });
    };

    updateSessionsSorting = async (categoryId, params) => {
        _.pullAllBy(this.joins.relationships.orderedSessionsCategory, [{ categoryId: categoryId }], 'categoryId');
        this.joins.relationships.orderedSessionsCategory = _.concat(
            this.joins.relationships.orderedSessionsCategory,
            params
        );

        const doc = new Document('joins/relationships');
        await doc.set(this.joins.relationships, { merge: true });
    };

    updateCategorySorting = async (tagId, params) => {
        _.pullAllBy(this.joins.relationships.orderedCategoriesTag, [{ tagId: tagId }], 'tagId');
        this.joins.relationships.orderedCategoriesTag = _.concat(this.joins.relationships.orderedCategoriesTag, params);

        const doc = new Document('joins/relationships');
        await doc.set(this.joins.relationships, { merge: true });
    };

    uploadCategoryImage = async params => {
        const ref = fbase.storage.ref().child(`categories/${params.id}/${params.name}`);
        await ref.put(params.blob);
        return await ref.getDownloadURL();
    };

    uploadImage = async params => {
        const ref = fbase.storage.ref().child(`${params.path}/${params.id}/${params.name}`);
        params.file ? await ref.putString(params.file, 'data_url') : await ref.put(params.blob);
        return await ref.getDownloadURL();
    };

    uploadSessionMedia = async params => {
        const ref = fbase.storage.ref().child(`media/${params.id}/${params.name}`);
        const uploadTask = ref.put(params.blob);

        uploadTask.on('state_changed', function (snapshot) {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            params.onProgress && params.onProgress(progress);
        });
        const url = await uploadTask.then(async () => {
            return await ref.getDownloadURL();
        });

        return url;
    };

    updatePage = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();

        const writeBatch = fbase.app.firestore().batch();
        const jsonPages = toJS(this.pages);

        if (jsonPages.length) {
            _.remove(jsonPages, { id: params.id });
            jsonPages.splice(params.order - 1, 0, toJS(params));
        } else {
            jsonPages.push(params);
        }

        for (let i = 0; i < jsonPages.length; i++) {
            const ref = fbase.app.firestore().doc(`pages/${jsonPages[i].id}`);
            jsonPages[i].order = i + 1;
            writeBatch.set(ref, jsonPages[i]);
        }

        await writeBatch.commit();
    };

    updateGoal = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();
        const doc = new Document(`goals/${params.id}`);
        await doc.set({ ...cleanEmpty(params) });
    };

    updateAppConfig = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();
        const doc = new Document('controlFeed/appConfiguration');
        await doc.set({ ...cleanEmpty(params) }, { merge: true });
    };

    updateAppSettings = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();
        const doc = new Document('controlFeed/appSettings');
        await doc.set({ ...cleanEmpty(params) }, { merge: true });
    };

    updateWidget = async params => {
        params.timestamp = fbase.app.firestore.Timestamp.now().toMillis();
        //const { history = [] } = this.controlFeed.todayScreen;
        //history.push({ ...params, timestamp: params.timestamp });

        //params.history = history;

        if (params.id) {
            const doc = new Document(`widgets/${params.id}`);
            await doc.set(params, { merge: true });
        } else {
            const col = new Collection('widgets');
            await col.add(params);
        }
    };

    deleteWidget = async params => {
        const doc = new Document(`widgets/${params.id}`);
        await doc.delete();
    };

    constructor() {
        makeObservable(this);
        this.fbase = fbase;

        // TODO this WIP is for download url not to depend on the bucket
        // console.log(
        //     this.fbase.storage.ref().child('authors/3W33vtMcfFzHp9Gtvw7x/avatar.jpg')
        //     // .getDownloadURL()

        //     // "https://firebasestorage.googleapis.com/v0/b/emjoy-test.appspot.com/o/authors%2F3W33vtMcfFzHp9Gtvw7x%2Favatar.jpg?alt=media&token=5f7f2d9d-4155-4afd-8b6f-9441dd4621f1"
        // );

        initFirestorter({ firebase: fbase.app });

        when(
            () => {
                return this.user?.uid;
            },
            async () => {
                this.sortingCollection.mode = 'off';
                await this.sortingCollection.fetch();
                this.sortingCollection.mode = 'on';

                this.storiesTagsCollection.mode = 'off';
                await this.storiesTagsCollection.fetch();
                this.storiesTagsCollection.query = ref => ref.orderBy('name', 'asc');
                this.storiesTagsCollection.mode = 'on';

                this.goalsCollection.mode = 'off';
                await this.goalsCollection.fetch();
                this.goalsCollection.query = ref => ref.orderBy('order', 'asc');
                this.goalsCollection.mode = 'on';

                this.storiesFiltersCollection.mode = 'off';
                await this.storiesFiltersCollection.fetch();
                this.storiesFiltersCollection.query = ref => ref.orderBy('name', 'asc');
                this.storiesFiltersCollection.mode = 'on';
            }
        );

        this.categoriesCollection = new Collection(
            'categories',
            { query: ref => ref.orderBy('orderName', 'asc') },
            { mode: 'on' }
        );
        this.tagsCollection = new Collection('tags', { query: ref => ref.orderBy('order', 'asc') }, { mode: 'on' });
        this.goalsCollection = new Collection('goals', { query: ref => ref.orderBy('order', 'asc') }, { mode: 'on' });

        this.authorsCollection = new Collection(
            'authors',
            //TODO do we need this?
            //{ query: ref => ref.orderBy('name', 'asc') },
            { mode: 'on' }
        );

        this.sessionsCollection = new Collection(
            'sessions',
            //TODO do we need this?
            //{ query: ref => ref.orderBy('orderName', 'asc') },
            { mode: 'on' }
        );

        this.sortingCollection = new Collection('joins', { mode: 'on' });

        this.pagesCollection = new Collection('pages', { query: ref => ref.orderBy('order', 'asc') }, { mode: 'on' });

        this.controlFeedCollection = new Collection('controlFeed', { mode: 'on' });
        this.featureFlagsCollection = new Collection('controlFeed/appSettings/featureFlags', { mode: 'on' });

        this.referralProductsCollection = new Collection('referralProducts', { mode: 'on' });
        this.referralEventsCollection = new Collection('referralEvents', { mode: 'on' });

        this.vipUsersCollection = new Collection(
            'users',
            { query: ref => ref.where('vipReason', '>=', '') },
            { mode: 'on' }
        );

        this.deletedUsersCollection = new Collection(
            'users',
            { query: ref => ref.where('isDeleted', '==', true) },
            { mode: 'on' }
        );

        this.deepLinksCollection = new Collection('growth', { mode: 'on' });

        this.storiesTagsCollection = new Collection(
            'storiesTags',
            { query: ref => ref.orderBy('name', 'asc') },
            { mode: 'on' }
        );

        this.storiesFiltersCollection = new Collection(
            'storiesFilters',
            { query: ref => ref.orderBy('name', 'asc') },
            { mode: 'on' }
        );
        fbase.auth.onAuthStateChanged(user => {
            this.user = user;
            this.loading = false;
        });

        this.referralCodesActivityCollection = new Collection('referralCodesActivity', { mode: 'on' });
        this.referralCodesCollection = new Collection('referralCodes', { mode: 'on' });
        this.referralCampaingsCollection = new Collection('referralCampaings', { mode: 'on' });
        this.widgetsCollection = new Collection('widgets', { mode: 'on' });
        this.groupsCollection = new Collection('groups', { mode: 'on' });
    }

    @computed get categories() {
        const out = this.categoriesCollection.docs.map(item => {
            const tags = _.filter(
                _.find(this.sortingCollection.docs, { id: 'relationships' })?.data?.orderedCategoriesTag,
                {
                    categoryId: item.id
                }
            );

            return {
                id: item.id,
                ...toJS(item.data),
                tags: tags
            };
        });

        return toJS(out);
    }

    @computed get tags() {
        const tagsData = this.tagsCollection.docs.map(item => {
            return {
                id: item.id,
                ...toJS(item.data)
            };
        });

        const out = toJS(tagsData);

        return out;
    }

    @computed get authors() {
        return collectionWithIds(this.authorsCollection);
    }

    @computed get sessions() {
        const out = this.sessionsCollection.docs.map(item => {
            const categories = _.filter(
                _.find(this.sortingCollection.docs, { id: 'relationships' })?.data?.orderedSessionsCategory,
                {
                    sessionId: item.id
                }
            );

            return {
                id: item.id,
                ...toJS(item.data),
                categories: categories
            };
        });
        return toJS(out);
    }

    @computed get goals() {
        const out = this.goalsCollection.docs.map(item => {
            return {
                id: item.id,
                ...toJS(item.data)
            };
        });
        return toJS(out);
    }

    @computed get storiesTags() {
        const out = this.storiesTagsCollection.docs.map(item => {
            return {
                id: item.id,
                ...toJS(item.data)
            };
        });

        return toJS(out);
    }

    @computed get storiesFilters() {
        const out = this.storiesFiltersCollection.docs.map(item => {
            return {
                id: item.id,
                ...toJS(item.data)
            };
        });

        return toJS(out);
    }

    @computed get joins() {
        const { docs } = this.sortingCollection;

        const out = {
            relationships: _.find(docs, { id: 'relationships' })?.data
        };

        return out;
    }

    @computed get controlFeed() {
        const { docs } = this.controlFeedCollection;

        const out = {
            publish: _.find(docs, { id: 'publish' })?.data,
            appConfiguration: _.find(docs, { id: 'appConfiguration' })?.data,
            appSettings: _.find(docs, { id: 'appSettings' })?.data
        };

        return out;
    }

    @computed get featureFlags() {
        return collectionWithIds(this.featureFlagsCollection);
    }

    @computed get referralProducts() {
        return collectionWithIds(this.referralProductsCollection);
    }

    @computed get referralEvents() {
        return collectionWithIds(this.referralEventsCollection);
    }

    @computed get pages() {
        const { docs } = this.pagesCollection;
        return _.map(docs, 'data');
    }

    @computed get referralCodes() {
        return collectionWithIds(this.referralCodesCollection);
    }

    @computed get widgets() {
        return collectionWithIds(this.widgetsCollection);
    }

    @computed get groups() {
        return collectionWithIds(this.groupsCollection);
    }

    @computed get referralCodesActivity() {
        return collectionWithIds(this.referralCodesActivityCollection);
    }

    @computed get referralCampaings() {
        return _.chain(this.referralCampaingsCollection.docs)
            .map(item => ({ ...item.data, id: item.id }))
            .value();
    }

    @computed get vipUsers() {
        const out = this.vipUsersCollection.docs.map(item => {
            return {
                id: item.id,
                ...toJS(item.data)
            };
        });

        return toJS(out);
    }

    @computed get deletedUsers() {
        const out = this.deletedUsersCollection.docs.map(item => {
            return {
                id: item.id,
                ...toJS(item.data)
            };
        });

        return toJS(out);
    }

    @computed get deepLinks() {
        let out = [];

        _.each(this.deepLinksCollection.docs, item => {
            _.keys(item.data).forEach(key => {
                item.data[key].forEach(link => {
                    out.push({ id: key, link, key: out.length });
                });
            });
        });

        let outSorted = _.sortBy(out, ['link']);

        return toJS(outSorted);
    }

    logIn = async ({ email, password }) => {
        try {
            const checkWhiteList = await callFirebaseFunction('checkAdminUser', { email });
            const userIsWhitelisted = checkWhiteList?.data;

            if (userIsWhitelisted) {
                const userReply = await fbase.signInWithEmailAndPassword({ email, password });
                this.user = userReply?.user;
                return userReply;
            }
            return { error: { message: 'User is not whitelisted.' } };
        } catch (error) {
            return { error };
        }
    };

    logOut = () => {
        fbase.signOut();
    };
}

const collectionWithIds = collection => {
    return collection.docs.map(item => {
        return {
            id: item.id,
            ...item.data
        };
    });
};

export default new Store();
