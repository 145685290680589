import React from 'react';

import _ from 'lodash';
import { Button, Col, Input, Layout, message, Row, Tag } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { observer, useLocalObservable } from 'mobx-react';

import appStore from '@stores/app';
import GroupsEdit from './GroupsEdit';
import GroupSelectSessions from '@screens/Content/Categories/GroupSelectSessions/GroupSelectSessions';
import { CopyOutlined, DiffOutlined } from '@ant-design/icons';
import { DeleteIconButton } from '@components/Buttons';
import { EmTable } from '@components/EmTable';
import { groupsStore } from '@stores/middleware/groups';
import { OrderListItemsModal } from '@components/OrderListItems/OrderListItems';
import { ViewHeader } from '@components/ViewHeader';

const GroupsView = observer(
    ({ name, groupType, singleName, oldDatabaseName, columns = [], order, searchable, selectSessions }) => {
        const state = useLocalObservable(() => ({
            get groupData() {
                const data = groupsStore.getByType(groupType);
                return order ? _.orderBy(data, order.orderId) : data;
            },
            searchText: null,
            get filteredData() {
                return this.searchText
                    ? _.filter(this.groupData, item => item.name.match(new RegExp(this.searchText, 'gi')))
                    : this.groupData;
            }
        }));

        const handleChangeFilter = e => {
            state.searchText = e.target.value;
        };

        const onCopy = () => {
            message.success('Id successfully copied to clipboard');
        };

        const buildColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            ..._.map(columns, column => {
                return {
                    title: column.title,
                    key: column.key,
                    render: (text, record) => {
                        if (column.groupId) {
                            return (
                                <ul>
                                    {record.groups &&
                                        _.map(record.groups[column.groupId], id => (
                                            <Tag key={id}>{groupsStore.getById(id)?.name}</Tag>
                                        ))}
                                </ul>
                            );
                        } else if (column.key === 'image') {
                            const imageUrl = record.images ? record.images?.square?.original : record.thumbnailImageUrl;
                            return imageUrl && <img src={imageUrl} style={{ width: 50, height: 50 }} />;
                        } else if (column.key === 'id') {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: 5
                                    }}
                                >
                                    <div style={{ textAlign: 'center' }}>{record.id}</div>

                                    <CopyToClipboard onCopy={onCopy} text={record.id}>
                                        <Button type="normal" size="small" icon={<CopyOutlined />}>
                                            Copy
                                        </Button>
                                    </CopyToClipboard>
                                </div>
                            );
                        } else {
                            return record[column.id];
                        }
                    }
                };
            }),
            selectSessions && {
                width: 90,
                key: 'editSessions',
                render: (text, record) => (
                    <GroupSelectSessions
                        group={record}
                        textButton={`Edit ${selectSessions} Sessions`}
                        iconButton={<DiffOutlined />}
                        type={'secondary'}
                        title={`Edit ${selectSessions} Sessions`}
                        selectSessions={selectSessions}
                    />
                )
            },
            {
                width: 90,
                title: 'Options',
                key: 'edit',
                render: (text, record) => {
                    const groupTypeData =
                        _.find(groupType.availableComponents, { componentType: record.groupType }) || {};

                    const options = (
                        <>
                            <GroupsEdit
                                groupType={groupType}
                                table
                                edit
                                record={record}
                                title={`Edit: ${record.name || record.id}`}
                            />
                            <DeleteIconButton onClick={() => groupsStore.deleteGroup(record)} />
                        </>
                    );
                    return (
                        <Row style={{ display: 'flex' }} justify={'end'}>
                            {groupsStore.advanced ? options : groupTypeData.advanced ? null : options}
                        </Row>
                    );
                }
            }
        ];

        const onOrderUpdate = async groups => {
            await Promise.all(_.map(groups, group => groupsStore.saveGroup(group)));
        };

        const buttonName = _.isUndefined(singleName) ? name : singleName;

        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <ViewHeader
                    input={
                        _.size(state.groupData) > 10 && (
                            <Input
                                type="text"
                                style={{ width: '44%' }}
                                allowClear
                                name="filter"
                                onChange={handleChangeFilter}
                                placeholder="Filter by name..."
                                value={state.searchText}
                            />
                        )
                    }
                >
                    {order && <OrderListItemsModal {...order} items={state.groupData} onOrderUpdate={onOrderUpdate} />}
                    <GroupsEdit groupType={groupType} textButton={'New ' + buttonName} title={'New ' + buttonName} />
                </ViewHeader>
                <EmTable
                    bordered
                    pagination={false}
                    size="small"
                    data={state.filteredData}
                    rowKey={record => record.id}
                    columns={_.compact(buildColumns)}
                />
                {appStore.advancedMode && (
                    <Row style={{ display: 'flex', justifyContent: 'space-between', padding: 50 }}>
                        {oldDatabaseName && (
                            <Button
                                onClick={() => groupsStore.importFromMain({ groupType, databaseName: oldDatabaseName })}
                                danger
                            >
                                Migrate
                            </Button>
                        )}
                        {/* <Button onClick={() => groupsStore.addDefaultGroups()} danger> //this is commented for now, but is not used as it was only for the initial migration
                            Add Default Groups
                        </Button>
                        <Button onClick={() => groupsStore.addDefaultAgeBands()} danger>
                            Add Default AgeBands
                        </Button> */}
                    </Row>
                )}
            </Layout>
        );
    }
);

export default GroupsView;
