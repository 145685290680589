import _ from 'lodash';
import { observable } from 'mobx';

import appStore from './app';

const authorStore = observable({
    get allAuthors() {
        return appStore.authors;
    },
    getSingleById(id) {
        return this.allAuthors[id];
    },
    getMultipleByIds(ids) {
        return _.map(ids, id => this.getSingleById(id));
    }
});

export { authorStore };
