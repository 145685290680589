import React, { Component } from 'react';

import { Button, Divider, Input } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import _ from 'lodash';
import appStore from '@stores/app';
import ModalWrapper from '@components/ModalWrapper';
import { addStoriesFilters } from '@stores/middleware/storiesFilters';
import { DeleteIconButton } from '@components/Buttons';
import { EmAutoComplete } from '@components/EmAutoComplete';

@observer
class StoriesFiltersEdit extends Component {
    @observable storyFilter = {
        contentType: 'story',
        name: null,
        sessions: [],
        order: this.props.defaultOrder + 1,
        ...this.props.content
    };

    onModalOk = () => {
        addStoriesFilters(this.storyFilter);
        this.props.closeModal();
    };

    handleChange = e => {
        this.storyFilter.name = e.target.value;
    };

    onSelect = value => {
        this.storyFilter.sessions = _.uniq([...this.storyFilter.sessions, value.id]);
    };

    onRemove = value => {
        this.storyFilter.sessions = _.filter(this.storyFilter.sessions, id => id !== value);
    };

    //TODO when deleting stories they are not deleted from the filter, ftm we return string NOT FOUND as name so we can manually remove
    // https://trello.com/c/JuJH7jBL
    getName = id => {
        if (appStore.sessions.length > 0) {
            const session = _.find(appStore.sessions, { id });
            if (session) {
                return session.name;
            } else {
                return '(NOT FOUND)';
            }
        }
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        const stories = _.filter(appStore.sessions, session => session.contentType === 'story');
        return (
            <div>
                <h3>Filter Name</h3>
                <Input
                    size="large"
                    value={this.storyFilter.name}
                    onChange={this.handleChange}
                    style={{ maxWidth: 400 }}
                />
                <Divider />
                <h4>Stories assigned to filter</h4>
                <EmAutoComplete values={stories} onSelect={this.onSelect} />
                <ul>
                    {this.storyFilter.sessions.map(id => (
                        <li key={id}>
                            {this.getName(id)}{' '}
                            <DeleteIconButton onClick={() => this.onRemove(id)}>del</DeleteIconButton>
                        </li>
                    ))}
                </ul>

                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }
}

export default ModalWrapper(StoriesFiltersEdit);
