import React, { useState } from 'react';

import ModalWrapper from '@components/ModalWrapper';
import { createProduct, editProduct, referralSubscriptionCategories } from '@stores/middleware/referrals';
import { EmForm } from '@components/EmForm';
import { EmRadio } from '@components/EmRadio';
import { transformToID } from '@lib/utils';

import { collectionStore } from '@stores/collection';

const productTypes = [
    {
        id: 'feature',
        name: 'Feature'
    },
    {
        id: 'subscription',
        name: 'Subscription'
    },
    {
        id: 'unlockUnhideCollections',
        name: 'Partner Collections'
    }
];

const ReferralProductsEdit = ({ closeModal, record }) => {
    const [productType, setProductType] = useState(record?.productType || 'feature');
    const [id, setId] = useState(record?.id);

    const onSubmit = values => {
        const data = {
            ...values,
            productType,
            id
        };
        if (record) {
            editProduct(data);
        } else {
            createProduct(data);
        }
        closeModal();
    };

    const fields = [
        {
            name: 'id',
            label: 'ID',
            item: 'title',
            value: record?.id || id
        },
        {
            name: 'name',
            label: 'Name',
            rules: [{ required: true, message: 'We need Name' }]
        }
    ];

    const onValuesChange = values => {
        if (!record?.id && values.name && values.name.length > 1) {
            setId(transformToID(values.name));
        }
    };

    productType === 'subscription' &&
        fields.push(
            {
                name: 'subscriptionId',
                label: 'Subscription ID (RC)',
                rules: [{ required: true, message: 'We need Subscription ID' }]
            },
            {
                item: 'select',
                label: 'Subscription Category',
                name: 'subscriptionCategory',
                rules: [{ required: true, message: 'Select campaign' }],
                values: referralSubscriptionCategories
            }
        );

    productType === 'feature' &&
        fields.push(
            {
                name: 'featureId',
                label: 'Feature ID',
                rules: [{ required: true, message: 'We need Subscription ID' }],
                tooltip: 'Same name as in the app',
                disabled: record?.id
            },
            {
                name: 'acknowledged',
                label: 'Acknowledged',
                tooltip:
                    'For now we only have Referral collection, think what we need when we have something else, defaults to false',
                item: 'switch',
                disabled: true
            }
        );

    productType === 'unlockUnhideCollections' &&
        fields.push(
            {
                name: 'featureId',
                label: 'Feature ID',
                rules: [{ required: true, message: 'We need Subscription ID' }],
                tooltip: 'Same name as in the app',
                disabled: record?.id
            },
            {
                item: 'select',
                mode: 'multiple',
                label: 'Collections',
                name: 'collections',
                rules: [{ required: true, message: 'Select at least a collection' }],
                values: collectionStore.allCollections
            }
        );

    return (
        <>
            <EmRadio
                onSelect={value => setProductType(value.id)}
                values={productTypes}
                checked={productType}
                style={{ paddingBottom: 30, paddingLeft: 150 }}
            />
            <EmForm fields={fields} onSubmit={onSubmit} initialValues={record} onValuesChange={onValuesChange} />
        </>
    );
};

export default ModalWrapper(ReferralProductsEdit);
