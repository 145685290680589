import React, { Component } from 'react';

import { observer } from 'mobx-react';
import { Row, Table } from 'antd';

import appStore from '@stores/app';
import StoriesTagsEdit from './StoriesTagsEdit';
import { DeleteIconButton } from '@components/Buttons';
import { deleteStoriesTags } from '@stores/middleware/storiesTags';
import { ViewHeader } from '@components/ViewHeader';

@observer
class StoriesTagsView extends Component {
    onDelete = ({ id }) => {
        deleteStoriesTags({
            id
        });
    };

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            width: 90,
            title: 'Options',
            key: 'edit',
            render: (text, record) => (
                <Row style={{ display: 'flex' }}>
                    <StoriesTagsEdit table edit content={record} title={'Edit Story Tag'} />
                    <DeleteIconButton onClick={() => this.onDelete(record)} />
                </Row>
            )
        }
    ];

    render() {
        return (
            <>
                <ViewHeader>
                    <StoriesTagsEdit textButton={'New Story tag'} title={'Add Story Tag'} />
                </ViewHeader>
                <Table
                    bordered
                    size="small"
                    dataSource={appStore.storiesTags}
                    rowKey={record => record.id}
                    columns={this.columns}
                />
            </>
        );
    }
}

export default StoriesTagsView;
