import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import 'react-quill/dist/quill.snow.css';
import _ from 'lodash';
import Axios from 'axios';
import ReactQuill from 'react-quill';
import { Button, Checkbox, Divider, Input, Row, Select, Tooltip } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import FormBuilder from '@lib/FormBuilder';
import ImageCropUpload from '@components/ImageUpload/ImageCropUpload';
import ImageCropUploadOriginal from '@components/ImageUpload/ImageCropUploadOriginal';
import MediaUpload from '@components/MediaUpload';
import ModalWrapper from '@components/ModalWrapper';
import notify from '@lib/notifications';
import SessionsStoriesTags from '@screens/Content/Sessions/Sessions/SessionsStoriesTags';
import { contentTypes, imagesConf, sessionsImages, sessionTypes } from '@lib/constants';
import { newTempDocID } from '@lib/firebaseHandler';
import { removeSessionFromStoriesFilter, updateStoriesFilters } from '@stores/middleware/storiesFilters';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 20
    }
};

const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], ['clean']]
};

// We are leaving this commented as template in case we need to add something to modules constant.
// const modulesDefault = {
//     toolbar: [
//         [{ header: [1, 2, false] }],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
//         ['link', 'image'],
//         ['clean']
//     ]
// };

const initialSession = {
    contentType: 'session'
};

const maxLengthPreview = 40;

@observer
class SessionsEdit extends Component {
    mediaUpload = React.createRef();
    @observable uploadProgress = 0;

    @observable busy = false;
    @observable editingMedia = false;

    @observable session = this.props.content || initialSession;

    loadSessionCategories = () => {
        return _.chain(appStore.joins.relationships.orderedSessionsCategory)
            .filter({ sessionId: this.session.id })
            .value();
    };

    @observable sessionCategories = this.loadSessionCategories() || [];

    @observable searchText = '';

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    /*    categoriesReaction = reaction(
            () => this.session.id,
            () => {
                this.sessionCategories = _.chain(appStore.joins.relationships.orderedSessionsCategory)
                    .filter({ sessionId: this.session.id })
                    .value();
            }
        );*/

    @computed get orderArray() {
        return _.fill(Array(appStore.sessions.length), '');
    }

    @computed get contentOptions() {
        if (this.session.contentType === 'story') {
            return [
                {
                    key: 'spicyness',
                    label: 'Spicyness',
                    widget: Select,
                    required: true,
                    widgetProps: {
                        mode: 'default',
                        onChange: spicyness => {
                            this.session.spicyness = spicyness;
                        }
                    },
                    initialValue: this.session.spicyness || 1,
                    children: [1, 2, 3].map(item => {
                        return (
                            <Option key={item} value={item}>
                                {item}
                            </Option>
                        );
                    })
                },
                {
                    render: () => {
                        const namedStoriesTags =
                            this.session.storiesTags?.map(item => {
                                const id = item.id ? item.id : item;
                                const { name } = _.find(appStore.storiesTags, { id });
                                return name;
                            }) || [];

                        return (
                            <Form.Item key={'storiesTags'} {...formItemLayout} label="Stories Tags">
                                <div style={{ display: 'flex' }}>
                                    <SessionsStoriesTags
                                        session={this.session}
                                        handleChange={this.setContent}
                                        textButton={'Set Stories Tags'}
                                        title={'Edit Stories Tags'}
                                        type={'secondary'}
                                    />
                                    <span style={{ justifyContent: 'start' }}>
                                        {namedStoriesTags.map(storyTag => `${storyTag} · `)}
                                    </span>
                                </div>
                            </Form.Item>
                        );
                    }
                },
                {
                    render: () => {
                        const filters = _.filter(appStore.storiesFilters, filter => filter.id !== 'All');

                        const defaultValue = _.chain(appStore.storiesFilters)
                            .filter(filter => _.includes(filter.sessions, this.session.id))
                            .map(filter => filter.id)
                            .value();

                        return (
                            <Form.Item key={'storiesFilters'} {...formItemLayout} label="Stories Filters">
                                <Select
                                    key="storiesFilters"
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select filter"
                                    defaultValue={defaultValue}
                                    onChange={value => (this.session.storiesFilters = value)}
                                >
                                    {filters.map(item => {
                                        return (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        );
                    }
                }
            ];
        } else {
            return [];
        }
    }

    handlePreviewText = e => {
        this.session.previewText = e.target.value;
    };

    @computed get sessionMeta() {
        return {
            formItemLayout,
            elements: [
                {
                    key: 'contentType',
                    label: 'Content type',
                    widget: Select,
                    widgetProps: {
                        mode: 'default',
                        onChange: contentType => {
                            this.session.contentType = contentType;
                        }
                    },
                    required: true,
                    initialValue: this.session.contentType,
                    children: contentTypes.map(item => {
                        return (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        );
                    })
                },
                {
                    key: 'name',
                    label: 'Name',
                    widget: Input,
                    required: true,
                    initialValue: this.session.name,
                    rules: [
                        {
                            validator: (r, value, cb) => {
                                const nodupe = _.isEmpty(
                                    _.pickBy(appStore.sessions, item => {
                                        return item.name == value && this.session.id != item.id;
                                    })
                                );

                                cb(nodupe ? undefined : false);
                            },
                            message: 'Duplicate session'
                        }
                    ]
                },
                {
                    key: 'description',
                    label: 'Description',
                    initialValue: this.session.description,
                    render: args => {
                        return (
                            <Form.Item {...args.formItemProps}>
                                <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    defaultValue={this.session.description}
                                    onChange={this.updateDescription}
                                    style={{ height: 250, marginBottom: 50 }}
                                />
                            </Form.Item>
                        );
                    }
                },
                {
                    key: 'previewText',
                    //really weird, if we delete the label the counter stop working
                    label: this.session.previewText,
                    required: true,
                    render: () => {
                        return (
                            //TODO enforce maxLengthPreview with rule/validator
                            <Form.Item key={'previewText'} {...formItemLayout} label="Preview Text ">
                                <Input
                                    onChange={this.handlePreviewText}
                                    defaultValue={this.session.previewText}
                                    suffix={
                                        <span
                                            style={{
                                                color:
                                                    maxLengthPreview > this.session.previewText?.length
                                                        ? 'inherit'
                                                        : 'red'
                                            }}
                                        >
                                            {this.session.previewText
                                                ? `${this.session.previewText.length}/${maxLengthPreview}`
                                                : `0/${maxLengthPreview}`}
                                        </span>
                                    }
                                />
                            </Form.Item>
                        );
                    }
                },
                {
                    key: 'categories',
                    label: 'Collections',
                    widget: Select,
                    widgetProps: { mode: 'multiple' },
                    initialValue: this.sessionCategories.map(item => item.categoryId),
                    children: appStore.categories.map(item => {
                        return (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        );
                    })
                },
                ...this.contentOptions,
                {
                    key: 'sessionType',
                    label: 'Session type',
                    widget: Select,
                    widgetProps: {
                        mode: 'default',
                        onChange: sessionType => {
                            this.session.sessionType = sessionType;
                        }
                    },
                    required: true,
                    initialValue: this.session.sessionType,
                    children: sessionTypes.map(item => {
                        return (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        );
                    })
                },
                {
                    key: 'images',
                    label: 'Images',
                    render: () => (
                        <Form.Item key={'images'} {...formItemLayout} label="Images">
                            <ImageCropUpload
                                unsplash
                                imagesFormat={sessionsImages}
                                content={this.session}
                                onSave={this.setContent}
                            />
                        </Form.Item>
                    )
                },
                {
                    key: 'imagesNew',
                    label: 'Images (New Format)',
                    render: () => (
                        <Form.Item key={'imagesNew'} {...formItemLayout} label="Images (New)">
                            <span style={{ opacity: '0.5' }}>
                                Here we need to upload the bigger image size we have, 2700 pixels at least
                            </span>
                            <ImageCropUploadOriginal
                                unsplash
                                storageFolder={'sessions'}
                                imagesFormat={imagesConf}
                                content={this.session}
                                onSave={this.setContent}
                            />
                        </Form.Item>
                    )
                },
                {
                    key: 'isFree',
                    label: 'Free to listen',
                    widget: Checkbox,
                    widgetProps: {
                        checked: this.session.isFree,
                        onChange: () => {
                            this.session.isFree = !this.session.isFree;
                        }
                    },
                    initialValue: this.session.isFree || false
                },
                {
                    key: 'needsPrivacy',
                    label: 'Needs Privacy',
                    widget: Checkbox,
                    widgetProps: {
                        checked: this.session.needsPrivacy,
                        onChange: () => {
                            this.session.needsPrivacy = !this.session.needsPrivacy;
                        }
                    },
                    initialValue: this.session.needsPrivacy || false
                },
                {
                    key: 'hideForComfortable',
                    label: 'Hide for comfortable',
                    widget: Checkbox,
                    widgetProps: {
                        checked: this.session.hideForComfortable,
                        onChange: () => {
                            this.session.hideForComfortable = !this.session.hideForComfortable;
                        }
                    },
                    initialValue: this.session.hideForComfortable || false
                },
                {
                    key: 'withPartnerOnly',
                    label: 'Only with partner',
                    widget: Checkbox,
                    widgetProps: {
                        checked: this.session.withPartnerOnly,
                        onChange: () => {
                            this.session.withPartnerOnly = !this.session.withPartnerOnly;
                        }
                    },
                    initialValue: this.session.withPartnerOnly || false
                }
            ]
        };
    }

    componentDidMount() {
        if (!this.session.id) {
            const newSession = newTempDocID('sessions');
            this.session.id = newSession.id;
        }
    }

    onModalOk = async () => {
        if (this.session.storiesFilters) {
            const oldFilters = _.chain(appStore.storiesFilters)
                .filter(filter => _.includes(filter.sessions, this.session.id))
                .map(filter => filter.id)
                .value();

            _.forEach(oldFilters, id => removeSessionFromStoriesFilter(id, this.session));

            updateStoriesFilters(this.session.storiesFilters, this.session);
            delete this.session.storiesFilters;
        }

        if (this.editingMedia) {
            if (this.mediaUpload.mediaData.blob) {
                this.busy = true;
                this.session.converting = true;

                this.session.mediaUrl = await appStore.uploadSessionMedia({
                    onProgress: progress => {
                        this.uploadProgress = progress;
                    },
                    name: this.mediaUpload.mediaData.name,
                    blob: this.mediaUpload.mediaData.blob,
                    id: this.session.id
                });

                this.session.mediaFileName = this.mediaUpload.mediaData.name;
                this.session.mediaDuration = this.mediaUpload.mediaData.duration;

                await appStore.updateSession(this.session);

                this.busy = false;
            }
            this.editingMedia = false;
        } else {
            this.props.form.validateFieldsAndScroll(async (errors, values) => {
                if (errors) {
                    return false;
                }

                this.busy = true;

                _.assign(this.session, values);

                this.session.orderName = _.chain(this.session.name).lowerCase().replace(/\s/gi, '').value();

                if (!this.props.edit) {
                    try {
                        await appStore.updateSession(this.session);
                        notify('success', this.session.timestamp ? 'addSession' : 'saveSession');
                    } catch (error) {
                        notify('error', 'addSession');
                    }
                } else {
                    try {
                        await appStore.updateSession(this.session);
                        notify('success', 'updateSession');
                    } catch (error) {
                        notify('error', 'updateSession');
                    }
                }
                this.props.closeModal();
            });
        }
    };

    updateDescription = content => {
        this.session.description = content;
    };

    onEditMedia = () => {
        _.assign(this.session, this.props.form.getFieldsValue());
        this.editingMedia = true;
    };

    setContent = content => {
        _.assign(this.session, content);
    };

    render() {
        const hasAudio = !!this.session?.mediaFileName && !!this.session?.playUrl;
        return (
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
                {!this.editingMedia && (
                    <Form>
                        <FormBuilder meta={this.sessionMeta} form={this.props.form} />

                        <Form.Item {...formItemLayout} label="Media">
                            {hasAudio && <b>{this.session.mediaFileName} </b>}
                            <Button type="primary" disabled={!this.session.timestamp} ghost onClick={this.onEditMedia}>
                                Set media
                            </Button>
                            {!this.session.timestamp && (
                                <Tooltip title="This seems to be a new session. Please save it first.">
                                    <ExclamationCircleOutlined style={{ color: '#f00', marginLeft: 10 }} />
                                </Tooltip>
                            )}
                        </Form.Item>
                    </Form>
                )}
                {this.editingMedia && (
                    <MediaUpload
                        browse
                        ref={ref => (this.mediaUpload = ref)}
                        session={this.session}
                        uploadProgress={this.uploadProgress}
                    />
                )}
                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" loading={this.busy} onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </Row>
        );
    }
}

export default ModalWrapper(Form.create()(SessionsEdit));
