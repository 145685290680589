import React, { useState } from 'react';

import _ from 'lodash';
import Modal from 'antd/lib/modal/Modal';

import appStore from '@stores/app';
import { Button } from 'antd';
import { EmForm } from '@components/EmForm';
import { EmTable } from '@components/EmTable';
import { firebaseShowDateWithHours } from '@lib/firebaseDates';
import { userTypes } from '@stores/middleware/referrals';

function ReferralCampaignsReward({ record, setReward, campaign, removeReward }) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onSubmit = async values => {
        setIsModalVisible(false);
        setReward(values);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const fields = [
        {
            item: 'select',
            label: 'User Type',
            name: 'userType',
            rules: [{ required: true, message: 'Select Owner' }],
            values: userTypes
        },
        {
            item: 'select',
            label: 'Product',
            name: 'product',
            rules: [{ required: true, message: 'Select product' }],
            values: appStore.referralProducts
        },
        {
            item: 'select',
            label: 'Custom Event',
            name: 'event',
            tooltip: 'Leave empty to disable events, reward will be granted on first code redeem',
            values: appStore.referralEvents
        },
        {
            name: 'validUntil',
            label: 'Reward valid until',
            item: 'date'
        }
    ];

    const columns = [
        {
            title: 'User',
            dataIndex: 'userType'
        },
        {
            title: 'Product',
            dataIndex: 'product',
            render: (text, data) => {
                const { name } = _.find(appStore.referralProducts, p => p.id === data.product);
                return name;
            }
        },
        {
            title: 'Event',
            dataIndex: 'event',
            render: (text, data) => {
                if (data.event) {
                    const { name } = _.find(appStore.referralEvents, e => e.id === data.event);
                    return name;
                } else {
                    return 'No event';
                }
            }
        },
        {
            title: 'Valid Until',
            dataIndex: 'validUntil',
            render: (text, data) => data.validUntil && firebaseShowDateWithHours(data.validUntil)
        }
    ];

    return (
        <>
            <Button type="secondary" onClick={showModal} style={{ marginBottom: 10, float: 'right', width: 300 }}>
                Add Reward
            </Button>
            <Modal
                title="Add Reward"
                visible={isModalVisible}
                onCancel={handleCancel}
                width={800}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <EmForm fields={fields} onSubmit={onSubmit} initialValues={record} />
            </Modal>
            <EmTable data={campaign?.rewards} columns={columns} onDelete={removeReward} style={{ maxWidth: 100 }} />
        </>
    );
}

export { ReferralCampaignsReward };
