import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import { Button, Input, Layout, Modal, Row, Space, Typography } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';

import _ from 'lodash';

import semver from 'semver';

import appStore from '../../../Stores/app';
import FormBuilder from '../../../lib/FormBuilder';

import notify from '../../../lib/notifications';

const { Text } = Typography;

const formItemLayout = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 20
    }
};

@observer
class AppSettingsView extends Component {
    @observable appSettings = {
        forceVersion: null
    };

    @observable onSaveVisible = false;

    @observable busy = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get appSettingsMeta() {
        return {
            formItemLayout,

            elements: [
                {
                    key: 'forceVersion',
                    label: 'Force Min Version',
                    widget: Input,
                    required: true,
                    initialValue: appStore.controlFeed.appSettings?.forceVersion,
                    rules: [
                        {
                            validator: (r, value, cb) => {
                                const validVersion = semver.valid(value);
                                cb(validVersion ? undefined : false);
                            },
                            message: 'Invalid version (has to be semver x.x.x)'
                        }
                    ]
                },
                {
                    key: 'lastStoreVersion',
                    label: 'Last Stores Version',
                    widget: Input,
                    required: true,
                    initialValue: appStore.controlFeed.appSettings?.lastStoreVersion || '0.0.1',
                    rules: [
                        {
                            validator: (r, value, cb) => {
                                const validVersion = semver.valid(value);
                                cb(validVersion ? undefined : false);
                            },
                            message: 'Invalid version (has to be semver x.x.x)'
                        }
                    ]
                }
            ]
        };
    }

    onSave = async () => {
        this.props.form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return false;
            }

            try {
                this.busy = true;

                if (!_.includes(appStore.controlFeed.appSettings.versionsHistory, values.lastStoreVersion)) {
                    values.versionsHistory = _.chain(appStore.controlFeed.appSettings.versionsHistory)
                        .concat([values.lastStoreVersion])
                        .compact()
                        .uniq()
                        .value()
                        .sort(semver.compare)
                        .reverse();
                }

                await appStore.updateAppSettings(values);
                this.busy = false;
                notify('success', 'updateAppSettings');
            } catch (error) {
                notify('error', 'updateAppSettings');
            }
        });
    };

    onShowModal = location => {
        if (location === 'save') {
            this.onSaveVisible = true;
        }
    };

    onCloseModal = location => {
        if (location === 'save') {
            this.onSaveVisible = false;
        }
    };

    onOkModal = location => {
        if (location === 'save') {
            this.onSave();
            this.onSaveVisible = false;
        }
    };

    render() {
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <Row type="flex" style={{ padding: 20 }}>
                    <Space direction={'vertical'}>
                        <Text type="danger">
                            This settings don't need to be published, once saved all user that will start an app will be
                            alerted if they have a lower version.
                        </Text>

                        <Text type="warning">
                            The app will display an update message automatically if it's behind with more than 5
                            versions compared with the last store verison.
                        </Text>
                    </Space>
                </Row>
                <Row type="flex" style={{}}>
                    <Form style={{ flex: 1 }}>
                        <FormBuilder meta={this.appSettingsMeta} form={this.props.form} />
                    </Form>
                </Row>

                <Row type="flex" justify="end" style={{ padding: 20 }}>
                    <Button type="primary" loading={this.busy} onClick={() => this.onShowModal('save')}>
                        Save
                    </Button>
                    <Modal
                        visible={this.onSaveVisible}
                        onOk={() => this.onOkModal('save')}
                        onCancel={() => this.onCloseModal('save')}
                        centered={true}
                        width={240}
                        closable={false}
                        destroyOnClose={true}
                    >
                        <p>Are you sure?</p>
                    </Modal>
                </Row>
            </Layout>
        );
    }
}

export default Form.create()(AppSettingsView);
