import _ from 'lodash';
import appStore from '@stores/app';
import { updateDocument } from '@lib/firebaseHandler';

const collection = 'sessions';

const updateSessionStoriesTags = session => {
    let results = appStore.joins.relationships.storiesTags || [];
    results = results.filter(result => result.sessionId !== session.id);

    session.storiesTags.forEach(id => {
        const { name } = _.find(appStore.storiesTags, { id });

        const data = {
            sessionName: session.name,
            sessionId: session.id,
            storiesTagsName: name,
            storiesTagsId: id
        };

        results = [...results, data];
    });

    results = _.uniq(results);
    return results;
};

const updateOrderSessions = async sessions => {
    for (let i = 0; i < sessions.length; i++) {
        await updateDocument(sessions[i], collection);
    }
};

export { updateOrderSessions, updateSessionStoriesTags };
