import _ from 'lodash';
import ModalWrapper from '@components/ModalWrapper';
import OrderListItems from '@components/OrderListItems/OrderListItems';
import React, { Component } from 'react';
import { Button, Divider } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { updateOrderCollections } from '@stores/middleware/collections';
import { updateOrderSessions } from '@stores/middleware/sessions';

@observer
class NewCollectionsOrder extends Component {
    @observable newCollections = this.props.newCollections;
    @observable busy = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get filteredData() {
        return _.sortBy(this.newCollections, ['isNewOrder']);
    }

    @computed get orderedSessions() {
        return _.filter(this.newCollections, item => {
            return item.contentType;
        });
    }

    @computed get orderedCollections() {
        return _.filter(this.newCollections, item => {
            return !item.contentType;
        });
    }

    onModalOk = async () => {
        this.busy = true;
        await updateOrderSessions(this.orderedSessions);
        await updateOrderCollections(this.orderedCollections);
        this.busy = false;
        this.props.closeModal();
    };

    onChange = result => {
        this.newCollections = result;
    };

    render() {
        return (
            <div>
                <OrderListItems
                    isContent
                    items={this.filteredData || this.newCollections}
                    onChange={this.onChange}
                    orderTag={'isNewOrder'}
                />

                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" loading={this.busy} onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }
}

export default ModalWrapper(NewCollectionsOrder);
