import React from 'react';

import _ from 'lodash';
import appStore from '@stores/app';
import { Tabs } from 'antd';

import GroupsView from 'Content/Groups/GroupsView';
import NewSessionsView from 'Content/Sessions/NewSessionsView';
import { storiesConfig, storiesMedia } from '@lib/contentConfig';

const { TabPane } = Tabs;

const StoriesScreen = () => {
    return (
        <Tabs defaultActiveKey={'storiesMedia'} animated={false} type="card">
            <TabPane tab={'Stories Media'} key={'storiesMedia'}>
                <NewSessionsView {...storiesMedia} />
            </TabPane>
            {_.map(storiesConfig, group => {
                const tab = (
                    <TabPane tab={group.name} key={group.groupType}>
                        <GroupsView {...group} />
                    </TabPane>
                );
                return group.advanced ? appStore.advancedMode && tab : tab;
            })}
        </Tabs>
    );
};

export { StoriesScreen };
