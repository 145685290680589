import React, { Component } from 'react';

import { Tabs } from 'antd';

import AuthorView from './AuthorView';
import CategoriesView from './CategoriesView';
import GoalsView from './Goals/GoalsView';
import NewCollectionsView from './NewCollections/NewCollectionsView';
import NewContentView from './NewContent/NewContentView';
import TagsView from './TagsView';

const { TabPane } = Tabs;

class CategoriesScreen extends Component {
    render() {
        return (
            <Tabs defaultActiveKey="categories" animated={false} type="card">
                <TabPane tab="Collections" key="categories">
                    <CategoriesView />
                </TabPane>
                <TabPane tab="Tags" key="tags">
                    <TagsView />
                </TabPane>
                <TabPane tab="Authors" key="authours">
                    <AuthorView />
                </TabPane>
                <TabPane tab="Goals" key="goals">
                    <GoalsView />
                </TabPane>
                <TabPane tab="New Stories" key="newStories">
                    <NewContentView />
                </TabPane>
                <TabPane tab="New Collections" key="newCollections">
                    <NewCollectionsView />
                </TabPane>
            </Tabs>
        );
    }
}

export default CategoriesScreen;
