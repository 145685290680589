import React from 'react';

import _ from 'lodash';
import { Button, Divider, Tree } from 'antd';
import { observer, useLocalObservable } from 'mobx-react';

import ModalWrapper from '@components/ModalWrapper';
import notify from '@lib/notifications';
import { groupsStore } from '@stores/middleware/groups';
import { sessionStore } from '@stores/session';

const { TreeNode } = Tree;

const GroupSelectSessions = observer(props => {
    //selectSessions
    const state = useLocalObservable(() => ({
        group: props.group,
        busy: false,
        get groupData() {
            if (props.selectSessions === 'wellbeing') {
                return _.chain(groupsStore.getByType('collections'))
                    .map(item => ({
                        categoryId: item.id,
                        categoryName: item.name,
                        sessions: _.map(item.sessions, sessionId => {
                            const session = sessionStore.getSingleById(sessionId);
                            return { sessionName: session.name, sessionId: session.id };
                        })
                    }))
                    .value();
            } else if (props.selectSessions === 'story') {
                return [
                    {
                        categoryName: 'Stories',
                        sessions: _.map(sessionStore.allStories, session => ({
                            sessionName: session.name,
                            sessionId: session.id
                        }))
                    }
                ];
            }
        }
    }));

    const onModalOk = async () => {
        try {
            state.busy = true;
            await groupsStore.saveGroup(state.group);
            state.busy = false;
            props.closeModal();
            notify('success', 'updateGoal');
        } catch (error) {
            notify('error', 'updateGoal');
        }
    };

    const onCheck = nextTargetSessions => {
        state.group.sessions = nextTargetSessions.checked;
    };

    return (
        <>
            <Tree onCheck={onCheck} checkable checkStrictly defaultCheckedKeys={state.group.sessions} defaultExpandAll>
                {_.map(state.groupData, item => {
                    return (
                        <TreeNode
                            title={<b>{item.categoryName}</b>}
                            checkable={false}
                            selectable={false}
                            key={item.categoryId}
                        >
                            {item.sessions.map(session => {
                                return <TreeNode title={session.sessionName} key={`${session.sessionId}`} />;
                            })}
                        </TreeNode>
                    );
                })}
            </Tree>
            <div style={{ textAlign: 'right' }}>
                <Divider />
                <Button onClick={props.closeModal}>Cancel</Button>
                <Button type="primary" loading={state.busy} onClick={onModalOk} style={{ margin: '0 10px' }}>
                    Save
                </Button>
            </div>
        </>
    );
});

export default ModalWrapper(GroupSelectSessions);
