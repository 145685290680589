import _ from 'lodash';
import appStore from '@stores/app';

const getInitialSessions = () => {
    return _.chain(appStore.controlFeed.appConfiguration?.initialSessions)
        .map(id => {
            return _.find(appStore.sessions, { id });
        })
        .compact()
        .value();
};

const updateInitialSessions = id => {
    const initialSessions = _.chain(getInitialSessions())
        .map(session => session.id)
        .value();
    appStore.updateAppConfig({ initialSessions: [...initialSessions, id] });
};

const deleteInitialSessions = id => {
    const initialSessions = _.chain(getInitialSessions())
        .filter(session => session.id !== id)
        .map(session => session.id)
        .value();
    appStore.updateAppConfig({ initialSessions });
};

export { getInitialSessions, updateInitialSessions, deleteInitialSessions };
