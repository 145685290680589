import _ from 'lodash';
import {
    authorsImages,
    businessPartnersLogos,
    categoriesImages,
    episodesImages,
    imagesConf,
    sessionsImages,
    wildCardImages
} from '@lib/constants';

const stripHtml = str => {
    return `${str || ''}`.replace(/(<([^>]+)>)/gi, '');
};

const buildOptions = options => {
    return _.map(options, option => ({
        key: option.id || option.groupType,
        ...(option.groupType && { widget: 'groupsSelect' }),
        ...(!option.id && { id: option.groupType }),
        ...option
    }));
};

const setImagesConfPath = (conf, path) => {
    return _.chain(conf)
        .cloneDeep()
        .map(image => {
            image.path = path;
            return image;
        })
        .value();
};

const defaultColumns = [];

const defaultOptions = buildOptions([
    {
        label: 'Id',
        id: 'id',
        widgetProps: {
            disabled: true
        }
    },
    {
        label: 'Name',
        id: 'name',
        required: true
    }
]);

const useSelectSessions = {
    label: 'Sessions / Stories',
    id: 'sessions',
    widget: 'sessionSelect',
    widgetProps: { mode: 'multiple' },
    valuesType: 'allSessions'
};

const useSelectWellbeing = {
    label: 'Wellbeing Sessions',
    id: 'sessions',
    widget: 'sessionSelect',
    widgetProps: { mode: 'multiple', contentType: 'allWellbeing' },
    valuesType: 'allWellbeing'
};

const useSelectStories = {
    label: 'Stories',
    id: 'sessions',
    widget: 'sessionSelect',
    widgetProps: { mode: 'multiple', contentType: 'allStories' },
    valuesType: 'allStories'
};

const useSelectCollections = {
    label: 'Collections',
    groupType: 'collections',
    widgetProps: { mode: 'multiple' }
};

const useSelectTags = {
    label: 'Select Group',
    id: 'allGroups',
    widget: 'groupsSelect',
    widgetProps: { mode: 'multiple', contentType: 'allGroups' },
    valuesType: 'allGroups'
};

const useAgeBand = {
    groupType: 'ageBands',
    name: 'Age Bands',
    singleName: 'Age Band',
    options: buildOptions([
        ...defaultOptions,
        { id: 'startAge', label: 'Start Age', widget: 'number' },
        { id: 'endAge', label: 'End Age', widget: 'number' }
    ])
};

const useAuthors = {
    groupType: 'authors',
    name: 'Authors',
    singleName: 'Author',
    oldDatabaseName: 'authors',
    order: { orderId: 'order', orderName: 'Authors' },
    images: authorsImages,
    columns: [...defaultColumns, { title: 'Image', key: 'image' }, { title: 'Id', key: 'id' }],
    options: buildOptions([
        ...defaultOptions,
        {
            label: 'Description',
            id: 'description',
            widget: 'textarea',
            widgetProps: { rows: 4 }
        },
        {
            label: 'Title',
            id: 'title'
        },
        {
            label: 'Link',
            id: 'link'
        }
        // {
        //     label: 'Business Partner',
        //     id: 'isBusinessPartner', // partner is aleady a tag, we need a nonGroup attribute
        //     widget: 'switch'
        // }
    ])
};

const commonConfig = [
    {
        groupType: 'freeContent',
        name: 'Free Content',
        singleName: 'Free Content',
        options: buildOptions([...defaultOptions, useSelectSessions, useSelectCollections])
    },
    {
        groupType: 'newContent',
        name: 'New Content',
        singleName: 'New Content',
        options: buildOptions([...defaultOptions, useSelectSessions, useSelectCollections])
    },
    {
        groupType: 'wellbeingNavigationTags',
        name: 'Wellbeing Navigation Tags',
        singleName: 'Navigation Tag',
        order: { orderId: 'order', orderName: 'Wellbeing Tags' },
        options: buildOptions([
            ...defaultOptions,
            useSelectTags,
            useSelectCollections,
            {
                label: 'Unify Sub-Navigation',
                id: 'unifySubNavigation',
                widget: 'switch'
            },
            {
                ...useSelectCollections,
                id: 'relatedCollections',
                label: 'Related Collections'
            },
            {
                label: 'Only Free Users',
                id: 'onlyFreeUsers',
                widget: 'switch'
            },
            {
                label: 'Only Paid Users',
                id: 'onlyPaidUsers',
                widget: 'switch'
            }
        ])
    },
    {
        groupType: 'storiesNavigationTags',
        name: 'Stories Navigation Tags',
        singleName: 'Navigation Tag',
        order: { orderId: 'order', orderName: 'Stories Tags' },
        options: buildOptions([
            ...defaultOptions,
            useSelectTags,
            useSelectStories,
            {
                label: 'Unify Sub-Navigation',
                id: 'unifySubNavigation',
                widget: 'switch'
            },
            {
                label: 'Only Free Users',
                id: 'onlyFreeUsers',
                widget: 'switch'
            },
            {
                label: 'Only Paid Users',
                id: 'onlyPaidUsers',
                widget: 'switch'
            }
        ])
    },
    {
        groupType: 'contentType',
        name: 'Content Type',
        singleName: 'Content Type',
        advanced: true,
        options: defaultOptions
    }
];

const mediaCommonFields = [
    {
        label: 'Description',
        id: 'description',
        widget: 'textAreaFormatted'
    },
    {
        label: 'Preview Text',
        id: 'previewText'
        //TODO input limited chars
    }
];

const storiesTagsFields = [
    {
        label: 'Spicyness',
        groupType: 'spicyness',
        required: true
    },
    {
        label: 'Accent',
        groupType: 'accent'
    },
    {
        label: 'Perspective',
        groupType: 'perspective'
    },
    {
        label: 'Sexual Orientation',
        groupType: 'sexualOrientation'
    },
    {
        label: 'Gender Dynamics',
        groupType: 'genderDynamics'
    },
    {
        label: 'Story Type',
        groupType: 'storyType'
    },
    {
        label: 'Story Structure',
        groupType: 'storyStructure'
    },
    {
        label: 'Number of People',
        groupType: 'numberOfPeople'
    },
    {
        label: 'Relationship',
        groupType: 'relationship'
    },
    {
        label: 'Vibe',
        groupType: 'vibe'
    },
    {
        label: 'Sexual Activity',
        widgetProps: { mode: 'multiple', noOrder: true }, // This si related agaon with rethnking the approach of the hierarchy, see also wellbeingConfig
        groupType: 'sexualActivity'
    },
    {
        label: 'Voice Over',
        widgetProps: { mode: 'multiple', noOrder: true },
        groupType: 'voiceOver'
    },
    {
        label: 'Age Bands',
        groupType: 'ageBands'
    },
    {
        label: 'Partner',
        widgetProps: { mode: 'multiple', noOrder: true },
        groupType: 'partner'
    },
    {
        label: 'Trans & Non-Binary',
        groupType: 'transNonBinary'
    }
];

const episodesTagsFields = [
    {
        label: 'Gender Dynamics',
        groupType: 'genderDynamics',
        widgetProps: { mode: 'multiple', noOrder: true } // This is related again with rethnking the approach of the hierarchy, see also wellbeingConfig
    },
    {
        label: 'Sexual Activity',
        groupType: 'sexualActivity',
        widgetProps: { mode: 'multiple', noOrder: true } // This is related again with rethnking the approach of the hierarchy, see also wellbeingConfig
    },
    {
        label: 'Vibe',
        groupType: 'vibe',
        widgetProps: { mode: 'multiple', noOrder: true } // This is related again with rethnking the approach of the hierarchy, see also wellbeingConfig
    },
    {
        label: 'Relationship',
        groupType: 'relationship',
        widgetProps: { mode: 'multiple', noOrder: true } // This is related again with rethnking the approach of the hierarchy, see also wellbeingConfig
    }
];

//WELLBEING MEDIA

const wellbeingMedia = {
    contentType: 'wellbeing',
    name: 'Wellbeing Session',
    oldDatabaseName: 'session',
    images: [sessionsImages, setImagesConfPath(imagesConf, 'sessions')],
    columns: [...defaultColumns, { title: 'Image', key: 'image' }],
    options: buildOptions([
        ...defaultOptions,
        ...mediaCommonFields,
        {
            label: 'Type of session',
            groupType: 'sessionType',
            required: true
        },
        {
            label: 'Needs Privacy',
            id: 'needsPrivacy',
            widget: 'switch'
        }
    ])
};

const wellbeingConfig = [
    {
        groupType: 'collections',
        name: 'Collections',
        singleName: 'collection',
        columns: [
            ...defaultColumns,
            { title: 'Image', key: 'image' },
            { title: 'Authors', groupId: 'authors', key: 'authors' },
            { title: 'Age Bands', groupId: 'ageBands', key: 'ageBands' },
            { title: 'Id', key: 'id' }
        ],
        oldDatabaseName: 'categories',
        images: [categoriesImages, setImagesConfPath(imagesConf, 'categories')],
        imagesMultiple: true,
        options: buildOptions([
            ...defaultOptions,
            {
                label: 'Description',
                id: 'description',
                widget: 'textAreaFormatted',
                widgetProps: { height: 100 }
            },
            // useSelectWellbeing, // TODO probably put this back, neeeds to be decided later
            useSelectSessions,
            {
                label: 'Authors',
                widgetProps: { mode: 'multiple' },
                groupType: 'authors'
            },
            {
                label: 'Life Stages',
                widgetProps: { mode: 'multiple' },
                groupType: 'lifestages'
            },
            {
                label: 'Age Bands',
                widgetProps: { mode: 'multiple' },
                groupType: 'ageBands'
            },
            {
                label: 'Partner',
                widgetProps: { mode: 'multiple' },
                groupType: 'partner'
            },
            // { // This is WIP - we need to rething a little the dependencies flow/hierarchy so we can be sure we can organise it well, see also storiesMedia
            //     label: 'Theme',
            //     id: 'wellbeingTheme',
            //     widgetProps: { mode: 'multiple' },
            //     groupType: 'wellbeingTheme'
            // },

            {
                label: 'Part of journey',
                id: 'journey',
                widget: 'switch'
            },
            {
                label: 'Hidden',
                id: 'hidden',
                widget: 'switch'
            }
        ])
    },
    {
        groupType: 'goals',
        name: 'Goals',
        singleName: 'Goal',
        oldDatabaseName: 'goals',
        selectSessions: 'wellbeing',
        order: { orderId: 'order', orderName: 'Goals' },
        options: buildOptions([
            ...defaultOptions,
            useSelectCollections,
            {
                label: 'Image',
                id: 'image',
                widget: 'imageUpload'
            }
        ])
    },
    useAuthors,
    {
        groupType: 'lifestages',
        name: 'Life Stages',
        singleName: 'Life Stage',
        options: defaultOptions
    },
    useAgeBand,
    {
        groupType: 'partner',
        name: 'Partner',
        singleName: 'Partner',
        options: defaultOptions
    },
    {
        groupType: 'wellbeingTheme',
        name: 'Theme',
        singleName: 'Theme',
        options: buildOptions([
            ...defaultOptions,
            useSelectCollections,
            {
                label: 'Sub Theme',
                widgetProps: { mode: 'multiple' },
                groupType: 'wellbeingSubTheme'
            }
        ])
    },
    {
        groupType: 'wellbeingSubTheme',
        name: 'Sub Theme',
        singleName: 'Sub Theme',
        options: buildOptions([...defaultOptions, useSelectCollections])
    },
    {
        groupType: 'sessionType',
        name: 'Type of Session',
        singleName: 'Type',
        advanced: true,
        options: defaultOptions
    }
];

// STORIES MEDIA

const storiesMedia = {
    contentType: 'story',
    name: 'Story Session',
    oldDatabaseName: 'session',
    images: [sessionsImages, setImagesConfPath(imagesConf, 'sessions')],
    columns: [...defaultColumns, { title: 'Image', key: 'image' }],
    advanced: false,
    options: buildOptions([
        ...defaultOptions,
        ...mediaCommonFields,
        ...storiesTagsFields,
        {
            label: 'Needs Privacy',
            id: 'needsPrivacy',
            widget: 'switch'
        }
    ])
};

// STORIES CONFIG

const storiesConfig = [
    //TODO check with content
    // {
    //     groupType: 'storyType',
    //     name: 'Story Collection',
    //     singleName: 'Episode / Saga',
    //     options: buildOptions([
    //         ...defaultOptions,
    //         {
    //             label: 'Type',
    //             id: 'storyTypeOptions',
    //             groupType: 'storyTypeOptions'
    //         },
    //         useSelectStories
    //     ])
    // },
    // {
    //     groupType: 'storyTheme', // Not needed anymore, we changed the way we filter stories
    //     name: 'Story Theme',
    //     singleName: 'Theme',
    //     order: { orderId: 'order', orderName: 'Story Theme' },
    //     options: buildOptions([...defaultOptions, useSelectStories])
    // },
    {
        groupType: 'spicyness',
        name: 'Spicyness',
        advanced: true,
        options: buildOptions([
            ...defaultOptions,
            { id: 'spicynessNumber', label: 'Spicyness Number', widget: 'number' }
        ])
    },
    {
        groupType: 'episodes',
        name: 'Series', //we name them series, but will be episoded internally in the app/db/admin
        singleName: 'episode',
        columns: [...defaultColumns, { title: 'Image', key: 'image' }, { title: 'Id', key: 'id' }],
        order: { orderId: 'order', orderName: 'Series' },
        images: [episodesImages, setImagesConfPath(imagesConf, 'episodes')],
        imagesMultiple: true,
        options: buildOptions([
            ...defaultOptions,
            {
                label: 'Description',
                id: 'description',
                widget: 'textAreaFormatted'
            },
            useSelectStories,
            ...episodesTagsFields
        ])
    },
    {
        groupType: 'accent',
        name: 'Accent',
        options: defaultOptions
    },
    {
        groupType: 'perspective',
        name: 'Perspective',
        options: defaultOptions
    },
    {
        groupType: 'sexualOrientation',
        name: 'Sexual Orientation',
        singleName: 'Sexual Orientation',
        options: defaultOptions
    },
    {
        groupType: 'genderDynamics',
        name: 'Gender Dynamics',
        singleName: 'Gender Dynamic',
        options: defaultOptions
    },
    {
        groupType: 'storyStructure',
        name: 'Story Structure',
        advanced: true,
        options: defaultOptions
    },
    {
        groupType: 'storyType',
        name: 'Story Type',
        advanced: true,
        options: defaultOptions
    },
    {
        groupType: 'numberOfPeople',
        name: 'Number of people',
        options: defaultOptions
    },
    {
        groupType: 'relationship',
        name: 'Relationship',
        singleName: 'Relationship',
        options: defaultOptions
    },
    {
        groupType: 'vibe',
        name: 'Vibe',
        options: defaultOptions
    },
    {
        groupType: 'sexualActivity',
        name: 'Sexual Activity',
        options: defaultOptions
    },
    useAgeBand,
    {
        groupType: 'partner',
        name: 'Partner',
        options: defaultOptions
    },
    {
        groupType: 'voiceOver',
        name: 'Voice Over',
        options: defaultOptions
    },
    {
        groupType: 'transNonBinary',
        name: 'Trans & Non-Binary',
        options: defaultOptions
    }
];

const partnersConfig = [
    //TODO it seems that initial values don't understand the dependsOn field
    {
        groupType: 'businessPartners',
        name: 'Business Partners',
        singleName: 'Business Partner',
        // order: { orderId: 'order', orderName: 'Business Partner' },
        images: businessPartnersLogos, // TODO add logos
        columns: [...defaultColumns, { title: 'Logo', key: 'image' }],
        options: buildOptions([
            {
                label: 'Id',
                id: 'id',
                widgetProps: {
                    disabled: true
                }
            },
            {
                label: 'Popup Title',
                id: 'popupTitle',
                widget: 'textarea',

                widgetProps: { rows: 2 },
                rules: [
                    {
                        //TODO we should make a component similar to previewText in session editor
                        validator: (rule, value, callback) => {
                            return new Promise((resolve, reject) => {
                                if (value?.length > 56) {
                                    reject(new Error('Please shorten the text to up to 56 chars'));
                                } else {
                                    resolve();
                                }
                            });
                        }
                    }
                ]
            },
            {
                label: 'Popup Description',
                id: 'popupDescription',
                widget: 'textAreaFormatted',
                widgetProps: { height: 100 },
                rules: [
                    {
                        //TODO we should make a component similar to previewText in session editor
                        validator: (rule, value, callback) => {
                            return new Promise((resolve, reject) => {
                                if (stripHtml(value).length > 185) {
                                    reject(new Error('Please shorten the text to up to 185 chars'));
                                } else {
                                    resolve();
                                }
                            });
                        }
                    }
                ]
            },

            {
                required: true,
                label: 'Type',
                key: 'type',
                id: 'type',
                item: 'select',
                widget: 'select',
                options: [
                    { label: 'Author', value: 'author' },
                    {
                        label: 'Brand',
                        value: 'brand'
                    }
                ]
            },

            {
                label: 'Author',
                id: 'author',
                key: 'author',
                required: true,
                groupType: 'authors',
                dependsOn: { key: 'type', value: 'author' }
            },

            {
                label: 'Brand name',
                id: 'name',
                key: 'name',
                required: true,
                dependsOn: { key: 'type', value: 'brand' }
            },
            {
                ...useSelectCollections,
                label: 'CTA Collection',
                widgetProps: { mode: '-' },
                dependsOn: { key: 'type', value: 'brand' }
            }
        ])
    }
];

const definedGroups = [...wellbeingConfig, ...storiesConfig, ...commonConfig, ...partnersConfig];

//can be delted after import
const defaultStoriesGroups = []; //TODO remove after we finish tagging, kept for migration no to fail for now
const defaultStoriesGroupsUnused = [
    ['accent', 'American', ''],
    ['accent', 'British', ''],
    ['accent', 'Australian', ''],
    ['accent', 'Canadian', ''],
    ['accent', 'Latin', ''],
    ['accent', 'Italian', ''],
    ['accent', 'Spanish', ''],
    ['accent', 'French', ''],
    ['perspective', 'His perspective', ''],
    ['perspective', 'Her perspective', ''],
    ['perspective', 'Their perspective', ''],
    ['sexualOrientation', 'Heterosexual', ''],
    ['sexualOrientation', 'Homosexual', ''],
    ['sexualOrientation', 'Bisexual', ''],
    ['sexualOrientation', 'Queer', ''],
    ['sexualOrientation', 'Other', ''],
    ['genderDynamics', 'Her+Him', 'MUySF6La9D4K7RJwVHZh'],
    ['genderDynamics', 'Her+Her', 'zbizwYR6mnC5i3aozDeF'],
    ['genderDynamics', 'Him+Him', 'S38JNSzBCTcvcoxQKEqL'],
    ['genderDynamics', 'Her+Him+Him', ''],
    ['genderDynamics', 'Her+Her+Him', ''],
    ['genderDynamics', 'Her+Him+Them', ''],
    ['genderDynamics', 'Her+You', 'omeE8JO6riX6d1mQcRUL'],
    ['genderDynamics', 'Him+You', 'OW79z5vwJUh8wh4KM0zF'],
    ['genderDynamics', 'Her+Him+You', ''],
    ['genderDynamics', 'Them+You', ''],
    ['genderDynamics', 'Them+Him', ''],
    ['genderDynamics', 'Them+Her', ''],
    ['genderDynamics', 'Only Her', ''],
    ['genderDynamics', 'Only Him', ''],
    ['storyType', 'Neutral', ''],
    ['storyType', 'Series', ''],
    ['storyType', 'Episodes', ''],
    ['storyStructure', 'Short', ''],
    ['storyStructure', 'Long', ''],
    ['partner', 'Single and dating', ''],
    ['partner', 'Single and not looking', ''],
    ['partner', 'Starting a relationship', ''],
    ['partner', 'Stable relationship', ''],
    ['partner', 'Healing from past relationship', ''],
    ['partner', 'All', 'partnerAll'],
    // ['storiesNavigationTags', 'All', 'storiesNavigationTagsAll'],
    // ['storiesNavigationTags', 'Hook Up', ''],
    // ['storiesNavigationTags', 'Romantic', ''],
    // ['storiesNavigationTags', 'Straight', ''],
    // ['storiesNavigationTags', 'Queer', ''],
    // ['storiesNavigationTags', 'His Perspective', ''],
    // ['storiesNavigationTags', 'Rough', ''],
    // ['storiesNavigationTags', 'Fantasy', ''],
    // ['storiesNavigationTags', 'Kinky', ''],
    // ['storiesNavigationTags', 'Unkinked', ''],
    // ['storiesNavigationTags', 'Episodes', ''],
    // ['storyTheme', 'All', 'storyThemeAll'],
    // ['storyTheme', 'Hook Up', '6KphOYjopFZZVYzqYBmI'],
    // ['storyTheme', 'Romantic', 'uRHCkNNrDgBJGVFnnpDn'],
    // ['storyTheme', 'Straight', 'ratsySNQPsF6zU4cAKzx'],
    // ['storyTheme', 'Queer', 'BfsbM44zIxRDDSboYX0e'],
    // ['storyTheme', 'His Perspective', 'lnU9RFi9yCKUcA8EWh6a'],
    // ['storyTheme', 'Rough', 'Bp5iELqZGLj3tqSWMB6Y'],
    // ['storyTheme', 'Fantasy', 'PLPOooqHWMlcZHRmLfnf'],
    // ['storyTheme', 'Kinky', ''],
    // ['storyTheme', 'Unkinked', ''],
    // ['storyTheme', 'Episodes', ''],
    // ['wellbeingNavigationTags', 'All', 'wellbeingNavigationTagsAll'],
    ['wellbeingTheme', 'All', 'wellbeingThemeAll'],
    ['numberOfPeople', 'Solo', ''],
    ['numberOfPeople', '2', ''],
    ['numberOfPeople', '3', ''],
    ['numberOfPeople', '4', ''],
    ['numberOfPeople', 'Group sex', 'QtacjYUM2yX99ToITNzN'],
    ['relationship', 'Strangers', ''],
    ['relationship', 'Friends', ''],
    ['relationship', 'Couple', '4zdr8lhXMyrTPLZFfIT6'],
    ['relationship', 'Ex', ''],
    ['relationship', 'Lovers', ''],
    ['relationship', 'Solo', ''],
    ['relationship', 'Open relationship', ''],
    ['vibe', 'Romantic', ''],
    ['vibe', 'Spontaneous', ''],
    ['vibe', 'Flirty', ''],
    ['vibe', 'Rough', ''],
    ['vibe', 'Fantasy', ''],
    ['vibe', 'Adventurous', ''],
    ['vibe', 'Playful', ''],
    ['vibe', 'Dramatic', ''],
    ['vibe', 'Passionate', ''],
    ['vibe', 'Soft', ''],
    ['sexualActivity', 'Toy Play', 'Zw0fIU3yiGg4C4Qbg3Hu'],
    ['sexualActivity', 'Oral Sex', ''],
    ['sexualActivity', 'Anal', ''],
    ['sexualActivity', 'Masturbation', ''],
    ['sexualActivity', 'BDSM', 'RACjsRx9MVcBxcLCWG19'],
    ['sexualActivity', 'Kinky', ''],
    ['sexualActivity', 'Phone Sex', ''],
    ['sexualActivity', 'Voyeurism', ''],
    ['sexualActivity', 'Non-penetrative', ''],
    ['sexualActivity', 'Double Penetration', ''],
    ['sexualActivity', 'Dirty Talk', ''],
    ['sexualActivity', 'Public Sex', 'US6E1bqFi3UIW7gHIBEH'],
    ['sexualActivity', 'Squirt', ''],
    ['sexualActivity', 'Unkinked', ''],
    ['sexualActivity', 'Multiples', ''],
    ['sexualActivity', '69', ''],
    ['voiceOver', 'Aaron Jabril Evans', ''],
    ['voiceOver', 'Adam Martignetti', ''],
    ['voiceOver', 'Adrian Smiley', ''],
    ['voiceOver', 'Alexandra Babich', ''],
    ['voiceOver', 'Alexandra E Cheff', ''],
    ['voiceOver', 'Arlethia Friday Hailstock', ''],
    ['voiceOver', 'Ben Bell', ''],
    ['voiceOver', 'Brandon Robinson', ''],
    ['voiceOver', 'Brenton John', ''],
    ['voiceOver', "Bri'Unia Johnson Stock", ''],
    ['voiceOver', 'Brooke Schellpfeffer', ''],
    ['voiceOver', 'Carissa Ann', ''],
    ['voiceOver', 'Carly Pandza', ''],
    ['voiceOver', 'Caroline Milsom', ''],
    ['voiceOver', 'Chantal Pinkham', ''],
    ['voiceOver', 'Curtis Lovell', ''],
    ['voiceOver', 'Dianna Conley', ''],
    ['voiceOver', 'Elanna White', ''],
    ['voiceOver', 'Emeric Bernard-Jones', ''],
    ['voiceOver', 'Faye Sewell', ''],
    ['voiceOver', 'Freya Saleh', ''],
    ['voiceOver', 'Greg Foster', ''],
    ['voiceOver', 'Hunter Hopkins Dunn', ''],
    ['voiceOver', 'Jacob Alden Roa', ''],
    ['voiceOver', 'James David Meddock', ''],
    ['voiceOver', 'Jess McAvoy', ''],
    ['voiceOver', 'Joanne Wilson', ''],
    ['voiceOver', 'Joe Lewis Garcia', ''],
    ['voiceOver', 'Joseph Mark Prata', ''],
    ['voiceOver', 'Joseph Michael Mau', ''],
    ['voiceOver', 'Joshua Greenblatt', ''],
    ['voiceOver', 'Katelyn Brennan', ''],
    ['voiceOver', 'Kristina Camaj', ''],
    ['voiceOver', 'Luis Orlando Cardozo', ''],
    ['voiceOver', 'Malik Shahjehan Muhammed Khan', ''],
    ['voiceOver', 'Mary Pochatko', ''],
    ['voiceOver', 'Matthew Winton', ''],
    ['voiceOver', 'Megan Gillespie', ''],
    ['voiceOver', 'Mia Lovett', ''],
    ['voiceOver', 'Nacia K Walsh', ''],
    ['voiceOver', 'Nelisa Nieto', ''],
    ['voiceOver', 'Paul Stefano', ''],
    ['voiceOver', 'Roslyn Catubig', ''],
    ['voiceOver', 'Sarsi Grace', ''],
    ['voiceOver', 'Tanya Means', ''],
    ['voiceOver', 'Tristan Boesch', ''],
    ['voiceOver', 'Victoria Jane Watson', ''],
    ['lifestages', 'All', ''],
    ['lifestages', 'Self Discovery I', ''],
    ['lifestages', 'Stable Relationship I', ''],
    ['lifestages', 'Self Discovery II', ''],
    ['lifestages', 'After Child', ''],
    ['lifestages', 'Stable Relationship II', ''],
    ['lifestages', 'Dating Again', ''],
    ['lifestages', 'Breakup/Divorce', ''],
    ['lifestages', 'Breakup', ''],
    ['wellbeingTheme', 'Pleasure', ''],
    ['wellbeingTheme', 'Body Acceptance', ''],
    ['wellbeingTheme', 'Desire', ''],
    ['wellbeingTheme', 'Self-Esteem', ''],
    ['wellbeingTheme', 'Health', ''],
    ['wellbeingTheme', 'Sex Ed', ''],
    ['wellbeingTheme', 'Relationship', ''],
    ['wellbeingTheme', 'Trans', ''],
    // ['wellbeingNavigationTags', 'Pleasure', ''],
    // ['wellbeingNavigationTags', 'Body Acceptance', ''],
    // ['wellbeingNavigationTags', 'Desire', ''],
    // ['wellbeingNavigationTags', 'Self-Esteem', ''],
    // ['wellbeingNavigationTags', 'Health', ''],
    // ['wellbeingNavigationTags', 'Sex Ed', ''],
    // ['wellbeingNavigationTags', 'Relationship', ''],
    // ['wellbeingNavigationTags', 'Trans', ''],
    ['wellbeingSubTheme', 'Self Discovery', ''],
    ['wellbeingSubTheme', 'Boosting Body Acceptance', ''],
    ['wellbeingSubTheme', 'Limiting Beliefs', ''],
    ['wellbeingSubTheme', 'Relaxation', ''],
    ['wellbeingSubTheme', 'Masturbation', ''],
    ['wellbeingSubTheme', 'Orgasm', ''],
    ['wellbeingSubTheme', 'Trauma', ''],
    ['wellbeingSubTheme', 'Type of desire', ''],
    ['wellbeingSubTheme', 'Kinky', ''],
    ['wellbeingSubTheme', 'Menstruation', ''],
    ['wellbeingSubTheme', 'Spice up (desire)', ''],
    ['wellbeingSubTheme', 'Myths', ''],
    ['wellbeingSubTheme', 'Communication', ''],
    ['wellbeingSubTheme', 'Sex & Relationships', ''],
    ['wellbeingSubTheme', 'Type of Relationships', ''],
    ['wellbeingSubTheme', 'Dating', ''],
    ['wellbeingSubTheme', 'Sex & Body', ''],
    ['wellbeingSubTheme', 'Healthy Relationships', ''],
    ['wellbeingSubTheme', 'Breakup', ''],
    ['wellbeingSubTheme', 'How to', ''],
    ['transNonBinary', 'Trans & Non-Binary', '5TA9l5WkQ7kQMHpIA3CS']
];

const defaultAgeBands = [];
const defaultAgeBandsUnused = [
    ['17-21', 17, 21],
    ['22-26', 22, 26],
    ['27-32', 27, 32],
    ['33-36', 33, 36],
    ['37-42', 37, 42],
    ['43-48', 43, 48],
    ['49-55', 49, 55],
    ['55+', 56, 120]
];

export {
    definedGroups,
    wellbeingConfig,
    storiesConfig,
    wellbeingMedia,
    storiesMedia,
    commonConfig,
    defaultStoriesGroups,
    defaultAgeBands,
    partnersConfig
};
