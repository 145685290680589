import moment from 'moment';

const firebaseToDatePicker = date => moment(date.seconds * 1000);

const datePickerToFirebase = date => date.toDate();

const firebaseShowDateWithHours = date => moment(date.seconds * 1000).format('DD/MM/YYYY h:mm');

const firebaseShowDate = date => moment(date.seconds * 1000).format('DD/MM/YYYY');

export { firebaseToDatePicker, datePickerToFirebase, firebaseShowDateWithHours, firebaseShowDate };
