import React, { Component, Fragment } from 'react';

import '@ant-design/compatible/assets/index.css';
import { AutoComplete, Layout, Row, Select, Table } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';

import _ from 'lodash';

import appStore from '@stores/app';
import NewCollectionsOrder from '@screens/Content/Categories/NewCollections/NewCollectionsOrder';
import { DeleteIconButton } from '@components/Buttons';
import { OrderedListOutlined } from '@ant-design/icons';
import { updateOrderCollections } from '@stores/middleware/collections';
import { ViewHeader } from '@components/ViewHeader';

const { Option } = Select;

@observer
class NewContentView extends Component {
    @observable filteredData = [];
    @observable searchText = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get newCollections() {
        const newCollections = _.filter(appStore.categories, item => {
            return item.isNew;
        });

        return _.sortBy(newCollections, ['isNewOrder']);
    }

    @computed get orderedCollections() {
        return _.filter(this.newCollections, item => {
            return !item.contentType;
        });
    }

    renderNameColumn = (text, record) => {
        return <Fragment>{record.name}</Fragment>;
    };

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: this.renderNameColumn
        },
        {
            width: 90,
            key: 'edit',
            render: (text, record) => (
                <Row>
                    <DeleteIconButton
                        onClick={() => {
                            this.onDeleteNewCollections(record);
                        }}
                    />
                </Row>
            )
        }
    ];

    onChange = value => (this.searchText = value);

    onSearch = searchText => {
        this.searchText = searchText;

        this.filteredCollections = searchText
            ? _.filter(appStore.categories, item => {
                  return !item.isNew && item.name.match(new RegExp(searchText, 'gi'));
              })
            : [];

        this.filteredData = this.filteredCollections;
    };

    onSelect = async contentId => {
        if (!!_.find(appStore.categories, { id: contentId })) {
            const selectedCategory = _.find(appStore.categories, { id: contentId });

            const getTagId = _.map(selectedCategory.tags, tag => tag.tagId);

            selectedCategory.tags = getTagId;
            selectedCategory.isNew = true;
            selectedCategory.isNewOrder = this.newCollections.length + 1;

            await appStore.updateCategory(selectedCategory);

            this.searchText = null;
            this.filteredData = [];
        }
    };

    onDeleteNewCollections = async record => {
        if (!!_.find(appStore.categories, { id: record.id })) {
            const selectedCategory = _.find(appStore.categories, { id: record.id });

            const getTagId = _.map(selectedCategory.tags, tag => tag.tagId);

            selectedCategory.tags = getTagId;
            selectedCategory.isNew = false;
            selectedCategory.isNewOrder = null;

            await appStore.updateCategory(selectedCategory);
        }
        this.orderAfterDelete();
    };

    orderAfterDelete = async () => {
        _.map(this.newCollections, (item, index) => {
            item.isNewOrder = index + 1;
        });

        await updateOrderCollections(this.orderedCollections);
    };

    filterContentType = item => {
        let itemType;

        const isCollection = !!_.find(appStore.categories, category => {
            return category.id === item.id;
        });

        if (isCollection) {
            itemType = 'collection';
        }

        return itemType;
    };

    render() {
        let concatItem;
        const children = _.map(this.filteredData, item => {
            concatItem = `${item.name} (${this.filterContentType(item)})`;

            return <Option key={item.id}>{concatItem}</Option>;
        });
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <ViewHeader
                    input={
                        <AutoComplete
                            type="text"
                            style={{ width: '44%' }}
                            name="filter"
                            onSearch={this.onSearch}
                            onChange={this.onChange}
                            placeholder="Search by name..."
                            onSelect={this.onSelect}
                            value={this.searchText}
                        >
                            {children}
                        </AutoComplete>
                    }
                >
                    <NewCollectionsOrder
                        textButton={'Order New Collections'}
                        type={'secondary'}
                        iconButton={<OrderedListOutlined />}
                        title={'Order New Collections'}
                        newCollections={this.newCollections}
                    />
                </ViewHeader>

                <Table
                    bordered
                    size="small"
                    dataSource={this.newCollections}
                    rowKey={record => record.id}
                    columns={this.columns}
                />
            </Layout>
        );
    }
}

export default Form.create()(NewContentView);
