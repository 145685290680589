import ModalWrapper from '@components/ModalWrapper';
import OrderListItems from '@components/OrderListItems/OrderListItems';
import React, { Component } from 'react';
import { Button, Divider } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { updateOrderStoriesFilters } from '@stores/middleware/storiesFilters';

import _ from 'lodash';

@observer
class StoriesFiltersOrder extends Component {
    @observable storiesFilters = _.filter(this.props.filters, item => {
        return item.readOnly !== true;
    });

    onModalOk = async () => {
        this.busy = true;
        await updateOrderStoriesFilters(this.storiesFilters);
        this.busy = false;
        this.props.closeModal();
    };

    onChange = result => {
        this.storiesFilters = result;
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <div>
                <OrderListItems items={this.storiesFilters} onChange={this.onChange} />

                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" loading={this.busy} onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }
}

export default ModalWrapper(StoriesFiltersOrder);
