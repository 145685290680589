import _ from 'lodash';
import { observable } from 'mobx';

import appStore from './app';
import { authorStore } from './author';
// import { getPercentage } from '@libs/utils';

const newSessions = _.map(appStore?.newSessions, session => session.id);

const collectionStore = observable({
    get allCollections() {
        return _.map(appStore.categories, collection => {
            // Reverse so firstGoal is true
            _.forEach(_.reverse(collection.sessions), session => {
                collection.hasNewSessions = _.includes(newSessions, session.sessionId);
                collection.firstGoal = _.find(appStore.goals, goal => _.includes(goal.sessions, session.sessionId));
            });

            collection.authorsData = authorStore.getMultipleByIds(collection.authors);

            const totalSessions = _.size(collection.sessions);
            const totalListened = _.size(_.filter(this.listenedSessions, session => session === true));

            collection.totalSessions = totalSessions;
            collection.totalListened = totalListened;
            // collection.percentageCompleted = _.toNumber(getPercentage(totalListened, totalSessions));
            collection.completed = totalSessions === totalListened;

            return collection;
        });
    },
    getSingleById(id) {
        return _.find(this.allCollections, { id });
    },
    getMultipleByIds(ids) {
        return _.map(ids, id => this.getSingleById(id));
    },
    getMultipleByTag(tag) {
        const allCatIds = _.chain(_.find(appStore.tags, { id: tag.id }).categories)
            .orderBy('categoryOrder')
            .value();
        const categories = _.map(allCatIds, cat => this.getSingleById(cat.categoryId));
        const relatedCategories = _.filter(this.allCollections, cat => _.includes(tag.relatedCategories, cat.id));

        return { categories, relatedCategories };
    },
    get newCollections() {
        return _.map(appStore.newCollections, collection => this.getSingleById(collection.id));
    }
});

export { collectionStore };
