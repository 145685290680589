import React, { useEffect } from 'react';

import _ from 'lodash';
import { Input, List } from 'antd';
import { observer, useLocalObservable } from 'mobx-react';
import { PlusCircleOutlined } from '@ant-design/icons';

import { DeleteIconButton } from '../Buttons';

const MultiLine = observer(({ value, onChange }) => {
    const state = useLocalObservable(() => {
        return {
            inputValue: '',
            newValue: [],
            addLine() {
                if (this.inputValue) {
                    state.newValue.push({ text: this.inputValue, type: 'random' }); // for now we only have random types, but later we might add date triggered, etc
                    state.inputValue = '';
                    onChange(this.newValue);
                }
            },
            removeLine(line) {
                _.pull(state.newValue, line);
                // TODO This is needed for te form to re-render. We should use the AntdFormBuilder way though https://github.com/rekit/antd-form-builder#2-for-functional-components
                onChange(state.newValue);
            }
        };
    });

    useEffect(() => {
        // sometimes value comes late?
        state.newValue = value || [];
    }, [value, state]);

    const list = state.newValue?.length ? (
        <List
            size="small"
            dataSource={state.newValue}
            bordered
            renderItem={(item, key) => (
                <List.Item
                    key={key}
                    actions={[
                        <DeleteIconButton
                            noModal
                            onClick={() => {
                                state.removeLine(item);
                            }}
                        />
                    ]}
                >
                    {item.text} - {item.type}
                </List.Item>
            )}
        />
    ) : null;
    return (
        <>
            <Input
                value={state.inputValue}
                onChange={e => {
                    state.inputValue = e.target.value;
                }}
                addonAfter={<PlusCircleOutlined onClick={state.addLine} />}
            />
            {list}
        </>
    );
});

export { MultiLine };
