import 'react-image-crop/dist/ReactCrop.css';
import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
class ReactCropWrapper extends Component {
    @observable croppedImageUrl = null;
    @observable pixelCrop = null;
    @observable crop = {
        unit: 'px',
        aspect: this.props.currentCrop.size[0] / this.props.currentCrop.size[1]
    };

    @observable imageRealHeight = 400;
    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentCrop !== this.props.currentCrop) {
            this.crop = {
                ...this.crop,
                aspect: this.props.currentCrop.size[0] / this.props.currentCrop.size[1]
            };
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = crop => {
        this.crop = crop;
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const blob = await this.getCroppedImg(this.imageRef, crop, `${this.props.currentCrop.id}.webp`);
            this.props.onChange({ ...this.props.currentCrop, blob, src: this.croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        this.imageRealHeight = this.props.useNaturalHeight ? image.naturalHeight : 400;

        canvas.width = this.props.currentCrop.size[0];
        canvas.height = this.props.currentCrop.size[1];
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        let webp = canvas.toDataURL('image/webp', 0.8);

        // return new Promise((resolve, reject) => {
        let arr = webp.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        let blob = new Blob([u8arr], { type: mime });

        blob.name = fileName;
        this.croppedImageUrl = window.URL.createObjectURL(blob);

        return blob;
    }

    render() {
        return (
            <ReactCrop
                src={this.props.src}
                crop={this.crop}
                ruleOfThirds
                imageStyle={{ height: this.imageRealHeight }}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
            />
        );
    }
}

export default ReactCropWrapper;
