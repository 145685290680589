import React, { Component } from 'react';

import { Tabs } from 'antd';

import AutomatedOfferView from './AutomatedOfferView';
import WidgetsView from './WidgetsView';

const { TabPane } = Tabs;

class DynamicContentView extends Component {
    render() {
        return (
            <Tabs defaultActiveKey="todayScreen" animated={false} type="card">
                <TabPane tab="Today Screen" key="todayScreen">
                    <WidgetsView location={'todayScreen'} />
                </TabPane>
                {/* <TabPane tab="Default Offer" key="automatedOffer">
                    <AutomatedOfferView />
                </TabPane> */}
            </Tabs>
        );
    }
}

export { DynamicContentView };
