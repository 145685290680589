import React, { Component, Fragment } from 'react';

import '@ant-design/compatible/assets/index.css';

import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { Button, Checkbox, Col, DatePicker, Input, Modal, Row, Table, Tabs } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';
import { PlusCircleOutlined } from '@ant-design/icons';

import appStore from '@stores/app';
import config from '@lib/config.json';
import DownloadDataButton from '@screens/Content/Users/DownloadDataButton/DownloadDataButton';
import FormBuilder from '@lib/FormBuilder';
import notify from '@lib/notifications';
import { getRevenueCatUser } from '@lib/revenueCat';
import { ViewHeader } from '@components/ViewHeader';

const { apiAddVipUserByEmail, apiDeleteUserEndpoint, apiSearchUserEndpoint } = config;

const { TextArea } = Input;
const { TabPane } = Tabs;

@observer
class UsersScreen extends Component {
    @observable deleting = false;
    @observable addingVip = false;
    @observable busy = false;
    @observable userEmailFound = false;
    @observable onSaveVisible = false;
    @observable onDeleteVipVisible = false;
    @observable onDeleteUserVisible = false;
    @observable userInfo = {
        uid: '',
        email: '',
        vipReason: null,
        vipExpiring: null
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get addUserByIdMeta() {
        return {
            formItemLayout: {
                labelCol: {
                    span: 7
                },
                wrapperCol: {
                    span: 17
                }
            },

            elements: [
                {
                    key: 'userId',
                    label: 'Id',
                    widget: Input,
                    widgetProps: { rows: 4 },
                    required: true,
                    initialValue: ''
                }
            ]
        };
    }

    @computed get userEmailMeta() {
        return {
            formItemLayout: {
                labelCol: {
                    span: 7
                },
                wrapperCol: {
                    span: 17
                }
            },

            elements: [
                {
                    key: 'userEmail',
                    label: 'Email',
                    widget: Input,
                    widgetProps: { rows: 4 },
                    required: true,
                    initialValue: ''
                }
            ]
        };
    }

    @computed get userDeleteMeta() {
        return {
            formItemLayout: {
                labelCol: {
                    span: 3
                },
                wrapperCol: {
                    span: 20
                }
            },

            elements: [
                {
                    key: 'userDeleteReason',
                    label: 'Delete Reason',
                    widget: TextArea,
                    widgetProps: { rows: 4 },
                    required: true,
                    initialValue: ''
                }
            ]
        };
    }

    @computed get userVipMeta() {
        return {
            formItemLayout: {
                labelCol: {
                    span: 3
                },
                wrapperCol: {
                    span: 20
                }
            },

            elements: [
                {
                    key: 'userVipReason',
                    label: 'VIP Reason',
                    widget: TextArea,
                    widgetProps: { rows: 4 },
                    required: true,
                    initialValue: this.userInfo.vipReason
                }
            ]
        };
    }

    onAddUserById = async () => {
        this.busy = true;

        const searchUserFormData = this.props.form.getFieldsValue();
        const { userId } = searchUserFormData;

        try {
            const safeUserId = _.trim(userId);
            await getRevenueCatUser({ userId: safeUserId });
            notify('success', 'userAdd');
        } catch (error) {
            notify('error', 'userAdd');
        }

        this.busy = false;
    };

    onDeleteUser = () => {
        this.deleting = true;
    };

    onAddVip = () => {
        this.addingVip = true;
    };

    onModalCancel = () => {
        this.resetUser();
    };

    resetUser = () => {
        this.userEmailFound = false;
        this.deleting = false;
        this.addingVip = false;
        this.busy = false;
        this.userInfo = { uid: '', email: '', vipReason: null, vipExpiring: null }; // To reset user once cancelled the modal
    };

    onSearchUserByEmail = async () => {
        this.props.form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return false;
            }

            this.userEmailFound = false;
            this.busy = true;

            const searchUserFormData = this.props.form.getFieldsValue();
            const { userEmail } = searchUserFormData;

            try {
                const searchRes = await axios.post(apiSearchUserEndpoint, {
                    userEmail: userEmail
                });

                if (searchRes.data.documentData.isVip !== null) {
                    this.userInfo.vipReason = searchRes.data.documentData.vipReason;
                    this.userInfo.vipExpiring = searchRes.data.documentData.vipExpiring;
                }
                this.userInfo.uid = searchRes.data.uid;
                this.userInfo.email = userEmail;

                this.busy = false;
                this.userEmailFound = true;
            } catch (error) {
                this.busy = false;
                notify('error', 'emailSearch');
            }
        });
    };

    onDeleteUserByEmail = async () => {
        this.props.form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return false;
            }

            this.busy = true;
            const deleteUserFormData = this.props.form.getFieldsValue();
            const { userEmail, userDeleteReason } = deleteUserFormData;

            try {
                const deleteRes = await axios.post(apiDeleteUserEndpoint, {
                    userEmail: userEmail,
                    deleteReason: userDeleteReason
                });

                this.busy = false;
                notify('success', 'userDelete');
            } catch (error) {
                this.busy = false;
                notify('error', 'userDelete');
            }
        });
    };

    onDateChange = (date, dateString) => {
        let vipDate = Date.parse(dateString);
        this.userInfo.vipExpiring = vipDate / 1000;
    };

    onVipUserAdd = async () => {
        this.props.form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return false;
            }

            this.busy = true;
            const addVipFormData = this.props.form.getFieldsValue();
            const { userEmail, userVipReason } = addVipFormData;

            this.userInfo.vipReason = userVipReason;

            try {
                const vipRes = await axios.post(apiAddVipUserByEmail, {
                    userEmail: userEmail,
                    vipReason: this.userInfo.vipReason,
                    vipDate: this.userInfo.vipExpiring,
                    isVip: true
                });

                this.busy = false;
                notify('success', 'vipAdd');
            } catch (error) {
                this.busy = false;
                notify('error', 'vipAdd');
            }
        });
    };

    onVipUserRemove = async () => {
        this.busy = true;
        const addVipFormData = this.props.form.getFieldsValue();
        const { userEmail, userVipReason } = addVipFormData;

        this.userInfo.vipReason = `${userVipReason} *** User VIP status was revoked on ${moment().format(
            'YYYY-MM-DD'
        )} ***`;

        try {
            const vipRes = await axios.post(apiAddVipUserByEmail, {
                userEmail: userEmail,
                vipReason: this.userInfo.vipReason,
                vipDate: this.userInfo.vipExpiring,
                isVip: false
            });

            this.busy = false;
            notify('success', 'vipRemove');
        } catch (error) {
            this.busy = false;
            notify('error', 'vipRemove');
        }
    };

    onShowModal = location => {
        if (location === 'saveVip') {
            this.onSaveVisible = true;
        } else if (location === 'deleteVip') {
            this.onDeleteVipVisible = true;
        } else {
            this.onDeleteUserVisible = true;
        }
    };

    onCloseModal = location => {
        if (location === 'saveVip') {
            this.onSaveVisible = false;
        } else if (location === 'deleteVip') {
            this.onDeleteVipVisible = false;
        } else {
            this.onDeleteUserVisible = false;
        }
    };

    onOkModal = location => {
        if (location === 'saveVip') {
            this.onVipUserAdd();
            this.onSaveVisible = false;
        } else if (location === 'deleteVip') {
            this.onVipUserRemove();
            this.onDeleteVipVisible = false;
        } else {
            this.onDeleteUserByEmail();
            this.onDeleteUserVisible = false;
        }
    };

    onCheckboxChange = e => {
        this.userInfo.vipExpiring = e.target.checked ? 'unlimited' : null;
    };

    renderUserDeletedReasonColumn = (text, record) => {
        return record.deleteReason;
    };

    renderUserDeletedUidColumn = (text, record) => {
        return record.uid;
    };

    renderVipEmailColumn = (text, record) => {
        if (record.isDeleted) {
            return '*** User Deleted ***';
        }
        return record.email;
    };

    renderVipReasonColumn = (text, record) => {
        return record.vipReason;
    };

    renderVipStatusColumn = (text, record) => {
        return record.isVip.toString();
    };

    renderVipExpiringColumn = (text, record) => {
        if (record.vipExpiring === 'unlimited') {
            return record.vipExpiring;
        }
        return moment(record.vipExpiring * 1000).format('YYYY-MM-DD');
    };

    renderVipUidColumn = (text, record) => {
        return record.uid;
    };

    columnsVip = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: this.renderVipEmailColumn
        },
        {
            title: 'VIP Reason',
            dataIndex: 'vipreason',
            key: 'vipreason',
            render: this.renderVipReasonColumn
        },
        {
            title: 'Current VIP status',
            dataIndex: 'vipstatus',
            key: 'vipstatus',
            render: this.renderVipStatusColumn
        },
        {
            title: 'Expiring at',
            dataIndex: 'expiring',
            key: 'expiring',
            render: this.renderVipExpiringColumn
        },
        {
            title: 'User UID',
            dataIndex: 'useruid',
            key: 'useruid',
            render: this.renderVipUidColumn
        }
    ];

    columnsDeleted = [
        {
            title: 'Delete Reason',
            dataIndex: 'deletereason',
            key: 'deletereason',
            render: this.renderUserDeletedReasonColumn
        },
        {
            title: 'User UID',
            dataIndex: 'useruid',
            key: 'useruid',
            render: this.renderUserDeletedUidColumn
        }
    ];

    render() {
        let defaultDate;

        if (this.userInfo.vipExpiring && this.userInfo.vipExpiring !== 'unlimited') {
            defaultDate = moment(this.userInfo.vipExpiring * 1000);
        } else {
            defaultDate = null;
        }

        const addUserTab = (
            <TabPane tab={'Add User'} key={'addUser'}>
                <Form layout="inline">
                    <FormBuilder meta={this.addUserByIdMeta} form={this.props.form} />
                    <Form.Item>
                        <Button type="primary" ghost loading={this.busy} onClick={this.onAddUserById}>
                            Add User
                        </Button>
                    </Form.Item>
                </Form>
            </TabPane>
        );

        return (
            <Fragment>
                <Tabs defaultActiveKey="delete" animated={false} type="card">
                    <TabPane tab="Delete User" key="delete">
                        <ViewHeader>
                            <Button type="primary" icon={<PlusCircleOutlined />} onClick={this.onDeleteUser}>
                                Delete User
                            </Button>
                        </ViewHeader>
                        <Table
                            bordered
                            size="small"
                            dataSource={appStore.deletedUsers}
                            rowKey={record => record.id}
                            columns={this.columnsDeleted}
                        />
                    </TabPane>
                    <TabPane tab="Add VIP User" key="vip">
                        <ViewHeader>
                            <Button type="primary" icon={<PlusCircleOutlined />} onClick={this.onAddVip}>
                                Add VIP User
                            </Button>
                        </ViewHeader>
                        <Table
                            bordered
                            size="small"
                            dataSource={appStore.vipUsers}
                            rowKey={record => record.id}
                            columns={this.columnsVip}
                        />
                    </TabPane>
                    <TabPane tab="Download User Data" key="feedback">
                        <Row type="flex" justify="center" style={{ padding: 20, flexDirection: 'column' }}>
                            <DownloadDataButton dataName={'Feedback'} functionID={'exportFeedbackToCsv'} />
                            <DownloadDataButton dataName={'Users CSV'} functionID={'exportUsersToCsv'} />
                            <DownloadDataButton dataName={'Survey'} functionID={'exportSurveyToCsv'} id={'sit'} />
                            <DownloadDataButton dataName={'Survey'} functionID={'exportSurveyToCsv'} id={'desire'} />
                        </Row>
                    </TabPane>
                    {appStore.advancedMode && addUserTab}
                </Tabs>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    closable={false}
                    width={1000}
                    title={'Delete User'}
                    visible={this.deleting}
                    footer={[
                        <Button key="close" loading={this.busy} type="primary" onClick={this.onModalCancel}>
                            Close
                        </Button>
                    ]}
                >
                    <Form layout="inline">
                        <FormBuilder meta={this.userEmailMeta} form={this.props.form} />
                        <Form.Item>
                            <Button type="primary" ghost loading={this.busy} onClick={this.onSearchUserByEmail}>
                                Search
                            </Button>
                        </Form.Item>
                    </Form>
                    {this.userEmailFound && (
                        <Form>
                            <Row type="flex" justify="space-around" style={{ marginBottom: 20, marginTop: 20 }}>
                                <Col span={10}>
                                    User UID: <b>{this.userInfo.uid}</b>
                                </Col>
                                <Col span={12}>
                                    User Email: <b>{this.userInfo.email}</b>
                                </Col>
                            </Row>
                            <FormBuilder meta={this.userDeleteMeta} form={this.props.form} />
                            <Button
                                type="primary"
                                ghost
                                onClick={() => this.onShowModal('deleteUser')}
                                loading={this.busy}
                            >
                                Delete
                            </Button>
                            <Modal
                                visible={this.onDeleteUserVisible}
                                onOk={() => this.onOkModal('deleteUser')}
                                onCancel={() => this.onCloseModal('deleteUser')}
                                centered={true}
                                width={240}
                                closable={false}
                                destroyOnClose={true}
                            >
                                <p>Are you sure?</p>
                            </Modal>
                        </Form>
                    )}
                </Modal>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    closable={false}
                    width={1000}
                    title={'VIP User'}
                    visible={this.addingVip}
                    footer={[
                        <Button key="close" loading={this.busy} type="primary" onClick={this.onModalCancel}>
                            Close
                        </Button>
                    ]}
                >
                    <Form layout="inline">
                        <FormBuilder meta={this.userEmailMeta} form={this.props.form} />
                        <Form.Item>
                            <Button type="primary" ghost loading={this.busy} onClick={this.onSearchUserByEmail}>
                                Search
                            </Button>
                        </Form.Item>
                    </Form>
                    {this.userEmailFound && (
                        <Fragment>
                            <Row type="flex" justify="space-around" style={{ marginBottom: 20, marginTop: 20 }}>
                                <Col span={10}>
                                    User UID: <b>{this.userInfo.uid}</b>
                                </Col>
                                <Col span={12}>
                                    User Email: <b>{this.userInfo.email}</b>
                                </Col>
                            </Row>
                            <Form>
                                <FormBuilder meta={this.userVipMeta} form={this.props.form} />

                                <Form.Item>
                                    <p>Choose the VIP expiring date: </p>
                                    <DatePicker
                                        onChange={this.onDateChange}
                                        disabled={this.userInfo.vipExpiring === 'unlimited'}
                                        value={defaultDate}
                                        defaultValue={defaultDate}
                                    />
                                    <Checkbox
                                        onChange={this.onCheckboxChange}
                                        style={{ marginLeft: 20 }}
                                        defaultChecked={this.userInfo.vipExpiring === 'unlimited'}
                                    >
                                        VIP not expiring
                                    </Checkbox>
                                </Form.Item>
                                <Row type="flex" justify="start">
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            ghost
                                            onClick={() => this.onShowModal('saveVip')}
                                            loading={this.busy}
                                            style={{ marginRight: 39 }}
                                        >
                                            Save
                                        </Button>
                                        <Modal
                                            visible={this.onSaveVisible}
                                            onOk={() => this.onOkModal('saveVip')}
                                            onCancel={() => this.onCloseModal('saveVip')}
                                            centered={true}
                                            width={240}
                                            closable={false}
                                            destroyOnClose={true}
                                        >
                                            <p>Are you sure?</p>
                                        </Modal>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="danger"
                                            ghost
                                            onClick={() => this.onShowModal('deleteVip')}
                                            loading={this.busy}
                                        >
                                            Delete
                                        </Button>
                                        <Modal
                                            visible={this.onDeleteVipVisible}
                                            onOk={() => this.onOkModal('deleteVip')}
                                            onCancel={() => this.onCloseModal('deleteVip')}
                                            centered={true}
                                            width={240}
                                            closable={false}
                                            destroyOnClose={true}
                                        >
                                            <p>Are you sure?</p>
                                        </Modal>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </Fragment>
                    )}
                </Modal>
            </Fragment>
        );
    }
}

export default Form.create()(UsersScreen);
