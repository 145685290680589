import { deleteDocument, setDocument, updateDocument } from '@lib/firebaseHandler';

const collection = 'controlFeed/appSettings/featureFlags';

const createFeatureFlag = async params => {
    await setDocument(params, collection);
};

const editFeatureFlag = async (params, oldData) => {
    const newData = {
        ...params,
        history: oldData.history ? [params, ...oldData.history] : [params]
    };
    await updateDocument(newData, collection);
};

const deleteFeatureFlag = async params => {
    await deleteDocument(params, collection);
};

export { createFeatureFlag, editFeatureFlag, deleteFeatureFlag };
