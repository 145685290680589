import React from 'react';

import _ from 'lodash';
import { observer, useLocalObservable } from 'mobx-react';

import ModalWrapper from '@components/ModalWrapper';
import { createCampaign, editCampaign } from '@stores/middleware/referrals';
import { EmForm } from '@components/EmForm';
import { ReferralCampaignsReward } from './ReferralCampaignsReward';

// import React, { useState } from 'react';
// import { EmForm } from '@components/EmForm';

// const ReferralCampaignsEditOld = ({ closeModal, record = { active: false } }) => {
//     console.log({ record });
//     const campaign = record;

//     // const [campaign, setCampaign] = useState(record);

//     const onSubmit = async (values) => {
//         const result = { ...record, ...campaign, ...values };
//         if (record) {
//             await editCampaign(result);
//         } else {
//             await createCampaign(result);
//         }
//         closeModal();
//     };

//     const setReward = (reward) => {
//         setCampaign({ ...campaign, rewards: campaign.rewards ? [...campaign.rewards, reward] : [reward] });
//     };

//     const removeReward = (reward) => {
//         const filteredRewards = _.filter(campaign.rewards, (r) => r !== reward);
//         setCampaign({ ...campaign, rewards: filteredRewards });
//     };

//     const onValuesChange = (values) => {
//         if (values.name && values.name.length >= 0) {
//             const id = transformToID(values.name);
//             setCampaign({ ...campaign, id });
//         }
//     };

//     const fields = [
//         {
//             name: 'id',
//             label: 'ID',
//             item: 'title',
//             value: record?.id || campaign?.id
//         },
//         {
//             name: 'name',
//             label: 'Name',
//             rules: [{ required: true, message: 'We need Name' }]
//         },
//         {
//             name: 'active',
//             label: 'Active',
//             item: 'switch'
//         },
//         {
//             name: 'startDate',
//             label: 'Start Campaign Date',
//             item: 'date'
//         },
//         {
//             name: 'endDate',
//             label: 'End Campaign Date',
//             item: 'date'
//         },
//         {
//             name: 'onlyNewUsers',
//             label: 'Only for New users?',
//             item: 'switch'
//         },
//         {
//             item: 'number',
//             label: 'Redemptions Limit',
//             name: 'redemptionsLimit',
//             tooltip: 'Max number of users can unlock the reward (for exmaple Invite 2 friends to 14FT)'
//         },
//         {
//             name: 'campaignFilter',
//             label: 'Show in Campaign Filter',
//             tooltip: 'Show with other campaigns, defaults to TRUE',
//             item: 'switch',
//             defaultChecked: true
//         },
//         {
//             label: 'Notes',
//             name: 'notes',
//             id: 'notes',
//             item: 'textArea',
//             placeholder: 'Additional campaign Info'
//         },
//         {
//             type: 'input',
//             label: 'Deactivate Reason',
//             name: 'deactivateReason',
//             id: 'deactivateReason',
//             item: 'textArea',
//             placeholder: 'Include reason why we deactivate this campaign'
//         }
//     ];

//     return (
//         <EmForm
//             fields={fields}
//             onSubmit={onSubmit}
//             initialValues={{ ...record, ...campaign }}
//             onValuesChange={onValuesChange}
//             bottomComponent={
//                 <ReferralCampaignsReward
//                     setReward={setReward}
//                     removeReward={removeReward}
//                     campaign={{ ...record, ...campaign }}
//                 />
//             }
//         />
//     );
// };

const ReferralCampaignsEdit = observer(({ record = {}, closeModal }) => {
    const state = useLocalObservable(() => ({
        data: record || {},
        setReward(reward) {
            this.data = { ...this.data, rewards: this.data.rewards ? [...this.data.rewards, reward] : [reward] };
        },
        removeReward(reward) {
            const filteredRewards = _.filter(this.data.rewards, r => r !== reward);
            this.data = { ...this.data, rewards: filteredRewards };
        }
    }));

    const fields = [
        {
            name: 'id',
            label: 'ID',
            disabled: true
        },
        {
            name: 'name',
            label: 'Name',
            item: 'input',
            rules: [{ required: true, message: 'We need Name' }]
        },
        // { //TODO if needed
        //     name: 'isBusinessPartner',
        //     key: 'isBusinessPartner',
        //     label: 'Business Partner',
        //     item: 'switch'
        // },
        // {
        //     label: 'Author',
        //     id: 'author',
        //     key: 'author',
        //     required: true,
        //     groupType: 'authors',
        //     dependsOn: { key: 'isBusinessPartner', value: 'true' }
        // },
        {
            name: 'active',
            label: 'Active',
            item: 'switch'
        },
        {
            name: 'startDate',
            label: 'Start Campaign Date',
            item: 'date'
        },
        {
            name: 'endDate',
            label: 'End Campaign Date',
            item: 'date'
        },
        {
            name: 'onlyNewUsers',
            label: 'Only for New users?',
            item: 'switch'
        },
        {
            item: 'number',
            label: 'Redemptions Limit',
            name: 'redemptionsLimit',
            tooltip: 'Max number of users can unlock the reward (for exmaple Invite 2 friends to 14FT)'
        },
        {
            name: 'campaignFilter',
            label: 'Show in Campaign Filter',
            tooltip: 'Show with other campaigns, defaults to TRUE',
            item: 'switch',
            defaultChecked: true
        },
        {
            label: 'Notes',
            name: 'notes',
            id: 'notes',
            item: 'textarea',
            placeholder: 'Additional campaign Info',
            widgetProps: { rows: 3 }
        },
        {
            label: 'Deactivate Reason',
            name: 'deactivateReason',
            id: 'deactivateReason',
            item: 'textarea',
            placeholder: 'Include reason why we deactivate this campaign',
            widgetProps: { rows: 3 }
        }
    ];

    const onSubmit = async values => {
        const result = { ...state.data, ...values };
        if (_.size(record)) {
            await editCampaign(result);
        } else {
            await createCampaign(result);
        }

        closeModal();
    };

    return (
        <EmForm
            fields={fields}
            onSubmit={onSubmit}
            initialValues={record}
            //TODO do we want to keep it as bottom component or should we create a definedWidget
            bottomComponent={
                state.data.id && (
                    <ReferralCampaignsReward
                        setReward={state.setReward}
                        removeReward={state.removeReward}
                        campaign={state.data}
                    />
                )
            }
        />
    );
});

export default ModalWrapper(ReferralCampaignsEdit);
