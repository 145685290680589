import React, { Component } from 'react';

import { Button, Divider, Input } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import ModalWrapper from '@components/ModalWrapper';
import { addStoriesTags, updateStoriesTags } from '@stores/middleware/storiesTags';

@observer
class StoriesTagsEdit extends Component {
    @observable name = this.props.content?.name || null;

    onModalOk = () => {
        if (this.props.edit) {
            this.onEdit();
        } else {
            this.onAdd();
        }
    };

    onAdd = () => {
        addStoriesTags({
            name: this.name
        });
        this.props.closeModal();
    };

    onEdit = () => {
        updateStoriesTags({
            name: this.name,
            id: this.props.content.id
        });
        this.props.closeModal();
    };

    handleChange = e => {
        this.name = e.target.value;
    };

    renderInput = () => {
        if (!this.props.edit) {
            return <Input size="large" value={this.name} onChange={this.handleChange} style={{ maxWidth: 400 }} />;
        } else {
            return <Input size="large" value={this.name} onChange={this.handleChange} style={{ maxWidth: 400 }} />;
        }
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <>
                {this.renderInput()}
                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </>
        );
    }
}

export default ModalWrapper(StoriesTagsEdit);
