import React, { Component, Fragment } from 'react';

import '@ant-design/compatible/assets/index.css';
import { AutoComplete, Layout, Row, Select, Table } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';

import _ from 'lodash';

import appStore from '@stores/app';
import NewContentOrder from '@screens/Content/Categories/NewContent/NewContentOrder';
import { DeleteIconButton } from '@components/Buttons';
import { OrderedListOutlined } from '@ant-design/icons';
import { updateOrderSessions } from '@stores/middleware/sessions';
import { ViewHeader } from '@components/ViewHeader';

const { Option } = Select;

@observer
class NewContentView extends Component {
    @observable filteredData = [];
    @observable searchText = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get newContent() {
        //Remove && item.contentType === 'story' to have Sessions back on the table.
        const newSessions = _.filter(appStore.sessions, item => {
            return item.contentType === 'story' && item.isNew;
        });

        return _.sortBy(newSessions, ['isNewOrder']);
    }

    @computed get orderedSessions() {
        return _.filter(this.newContent, item => {
            return item.contentType;
        });
    }

    @computed get orderedCollections() {
        return _.filter(this.newContent, item => {
            return !item.contentType;
        });
    }

    renderNameColumn = (text, record) => {
        return <Fragment>{record.name}</Fragment>;
    };

    //Uncomment to get back Content Type column
    // renderContentTypeColumn = (text, record) => {
    //     return <Fragment>{this.filterContentType(record)}</Fragment>;
    // };

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: this.renderNameColumn
        },
        //Uncomment to get back Content Type column
        // {
        //     title: 'Content Type',
        //     dataIndex: 'contentType',
        //     key: 'contentType',
        //     render: this.renderContentTypeColumn
        // },

        {
            width: 90,
            key: 'edit',
            render: (text, record) => (
                <Row>
                    <DeleteIconButton
                        onClick={() => {
                            this.onDeleteNewContent(record);
                        }}
                    />
                </Row>
            )
        }
    ];

    onChange = value => (this.searchText = value);

    onSearch = searchText => {
        this.searchText = searchText;

        //Remove && item.contentType === 'story' to have Sessions back on search input.
        this.filteredSessions = searchText
            ? _.filter(appStore.sessions, item => {
                  return !item.isNew && item.contentType === 'story' && item.name.match(new RegExp(searchText, 'gi'));
              })
            : [];
        this.filteredData = this.filteredSessions;
    };

    onSelect = async contentId => {
        if (!!_.find(appStore.sessions, { id: contentId })) {
            const selectedSession = _.find(appStore.sessions, { id: contentId });

            const sessionCategories = _.chain(appStore.joins.relationships.orderedSessionsCategory)
                .filter({ sessionId: selectedSession.id })
                .value();

            selectedSession.categories = sessionCategories.map(item => item.categoryId);
            selectedSession.isNew = true;
            selectedSession.isNewOrder = this.newContent.length + 1;

            await appStore.updateSession(selectedSession);

            this.searchText = null;
            this.filteredData = [];
        }
    };

    onDeleteNewContent = async record => {
        if (!!_.find(appStore.sessions, { id: record.id })) {
            const selectedSession = _.find(appStore.sessions, { id: record.id });

            const sessionCategories = _.chain(appStore.joins.relationships.orderedSessionsCategory)
                .filter({ sessionId: selectedSession.id })
                .value();

            selectedSession.categories = sessionCategories.map(item => item.categoryId);
            selectedSession.isNew = false;
            selectedSession.isNewOrder = null;

            await appStore.updateSession(selectedSession);
        }
        this.orderAfterDelete();
    };

    orderAfterDelete = async () => {
        _.map(this.newContent, (item, index) => {
            item.isNewOrder = index + 1;
        });

        await updateOrderSessions(this.orderedSessions);
    };

    filterContentType = item => {
        let itemType;

        if (item.contentType !== 'story') {
            itemType = 'session';
        } else {
            itemType = item.contentType;
        }

        return itemType;
    };

    render() {
        let concatItem;
        const children = _.map(this.filteredData, item => {
            concatItem = `${item.name} (${this.filterContentType(item)})`;

            return <Option key={item.id}>{concatItem}</Option>;
        });
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <ViewHeader
                    input={
                        <AutoComplete
                            type="text"
                            style={{ width: '44%' }}
                            name="filter"
                            onSearch={this.onSearch}
                            onChange={this.onChange}
                            placeholder="Search by name..."
                            onSelect={this.onSelect}
                            value={this.searchText}
                        >
                            {children}
                        </AutoComplete>
                    }
                >
                    <NewContentOrder
                        textButton={'Order New Content'}
                        type={'secondary'}
                        iconButton={<OrderedListOutlined />}
                        title={'Order New Content'}
                        newContent={this.newContent}
                    />
                </ViewHeader>

                <Table
                    bordered
                    size="small"
                    dataSource={this.newContent}
                    rowKey={record => record.id}
                    columns={this.columns}
                />
            </Layout>
        );
    }
}

export default Form.create()(NewContentView);
