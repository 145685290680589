import React, { useState } from 'react';

import ModalWrapper from '@components/ModalWrapper';
import { createEvent, editEvent } from '@stores/middleware/referrals';
import { EmForm } from '@components/EmForm';
import { transformToID } from '@lib/utils';

const ReferralEventsEdit = ({ closeModal, record }) => {
    const [id, setId] = useState(record?.id);

    const onSubmit = values => {
        const data = {
            ...values,
            id
        };
        if (record) {
            editEvent(data);
        } else {
            createEvent(data);
        }
        closeModal();
    };

    const onValuesChange = values => {
        if (!record?.id && values.name && values.name.length > 1) {
            setId(transformToID(values.name));
        }
    };

    const fields = [
        {
            name: 'id',
            label: 'ID',
            item: 'title',
            value: record?.id || id
        },
        {
            name: 'event',
            label: 'Event',
            placeholder: 'Make sure is the same event as we have in app',
            rules: [{ required: true, message: 'We need ID' }],
            tooltip: 'Same Event as in the function call',
            disabled: record?.id
        },
        {
            name: 'name',
            label: 'Name',
            rules: [{ required: true, message: 'We need Name' }],
            tooltip: 'UI readable name'
        }
    ];

    return <EmForm fields={fields} onSubmit={onSubmit} initialValues={record} onValuesChange={onValuesChange} />;
};

export default ModalWrapper(ReferralEventsEdit);
