import React, { Component } from 'react';

import _ from 'lodash';
import { observer } from 'mobx-react';
import { Row, Table } from 'antd';

import appStore from '@stores/app';
import StoriesFiltersEdit from './StoriesFiltersEdit';
import StoriesFiltersOrder from './StoriesFiltersOrder';
import { DeleteIconButton } from '@components/Buttons';
import { deleteStoriesFilters } from '@stores/middleware/storiesFilters';
import { OrderedListOutlined } from '@ant-design/icons';
import { ViewHeader } from '@components/ViewHeader';

@observer
class StoriesFiltersView extends Component {
    onDelete = ({ id }) => {
        deleteStoriesFilters({
            id
        });
    };

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            width: 90,
            title: 'Options',
            key: 'edit',
            render: (text, record) => (
                <Row style={{ display: 'flex' }} justify={'end'}>
                    {!record.readOnly && (
                        <>
                            <StoriesFiltersEdit table edit content={record} title={'Edit Story Filter'} />
                            <DeleteIconButton onClick={() => this.onDelete(record)} />
                        </>
                    )}
                </Row>
            )
        }
    ];

    render() {
        const filters = _.chain(appStore.storiesFilters).orderBy('order').value();
        return (
            <>
                <ViewHeader>
                    <StoriesFiltersOrder
                        filters={filters}
                        textButton={'Order Filters'}
                        type={'secondary'}
                        iconButton={<OrderedListOutlined />}
                        title={'Order Story Filter'}
                    />
                    <StoriesFiltersEdit
                        textButton={'New Story filter'}
                        defaultOrder={filters.length}
                        title={'Edit Story Filter'}
                    />
                </ViewHeader>
                <Table bordered size="small" dataSource={filters} rowKey={record => record.id} columns={this.columns} />
            </>
        );
    }
}

export default StoriesFiltersView;
