import React, { useEffect, useState } from 'react';

import _ from 'lodash';

import appStore from '@stores/app';
import ModalWrapper from '@components/ModalWrapper';
import { Button } from 'antd';
import { createReferral, editReferral, referralSwitchActive } from '@stores/middleware/referrals';
import { EmForm } from '@components/EmForm';
import { getDocument } from '@lib/firebaseHandler';

import { groupsStore } from '@stores/middleware/groups';

const businessPopupField = {
    item: 'switch',
    label: 'Has Business Popup',
    name: 'businessPopup'
};

const ReferralsEdit = ({ closeModal, record }) => {
    //TODO this form won't refresh on businessPartner change after save, needs to be fixed
    //TODO convert this form to the new form builder and mobx states

    const [validUserId, setValidUserId] = useState('');
    const [userId, setUserId] = useState(null);
    const [validCode, setValidCode] = useState(null);

    const [finalFields, setFinalFields] = useState([]);

    const onSubmit = values => {
        if (!values.businessPartner) {
            values.businessPopup = false;
        }

        if (record) {
            editReferral(values);
        } else {
            createReferral(values);
        }
        closeModal();
    };

    useEffect(() => {
        if (record?.businessPartner) {
            fields.push(businessPopupField);
        }
        setFinalFields(fields);
    }, [fields, record?.businessPartner]);

    const onValuesChange = values => {
        if (values.businessPartner) {
            fields.push(businessPopupField);
        } else {
            values.businessPopup = false;
            _.pull(fields, businessPopupField);
        }

        !_.isUndefined(values.businessPartner) && setFinalFields(fields);

        if (values.userId?.length > 1) {
            getDocument('users', values.userId).then(doc => {
                if (doc) {
                    setValidUserId('success');
                    setUserId(doc);
                } else {
                    setValidUserId('error');
                    setUserId(null);
                }
            });
        } else {
            setValidUserId('');
            setUserId(null);
        }
        if (values.code?.length > 1) {
            getDocument('referralCodes', values.code).then(doc => {
                if (!doc) {
                    setValidCode('success');
                } else {
                    setValidCode('error');
                }
            });
        } else {
            setValidCode(null);
        }
    };

    const campaigns = _.filter(appStore.referralCampaings, campaign => campaign.name);
    const businessPartners = [{}, ...groupsStore.getByType('businessPartners')]; // we need to allow empty value

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let fields = [
        {
            label: 'CODE',
            name: 'code',
            placeholder: 'Only letters and numbers allowed',
            rules: [{ required: true, message: 'We need a CODE' }],
            validateStatus: validCode,
            normalize: value => (value || '').toUpperCase(),
            disabled: record
        },
        {
            label: 'User ID',
            name: 'userId',
            help: userId && userId.email,
            validateStatus: validUserId,
            placeholder: 'User ID is not obligatory, use to link to Emjoy user' //TODO ADD TOOLTIP
        },
        {
            item: 'select',
            label: 'Associated Campaign',
            name: 'referralType',
            rules: [{ required: true, message: 'Select campaign' }],
            values: campaigns
        },
        {
            item: 'select',
            label: 'Business Partner',
            name: 'businessPartner',
            values: businessPartners
        }
    ];

    return (
        <>
            <EmForm fields={finalFields} onSubmit={onSubmit} initialValues={record} onValuesChange={onValuesChange} />
            {record && record.active && (
                <Button type="danger" onClick={() => referralSwitchActive(record)} style={{ marginRight: 20 }}>
                    Deactivate
                </Button>
            )}
        </>
    );
};

export default ModalWrapper(ReferralsEdit);
