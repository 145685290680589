import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import _ from 'lodash';
import move from 'lodash-move';
import { Button, Divider, Empty, Input, List, message, Select, Tabs } from 'antd';
import { computed, makeObservable, observable, when } from 'mobx';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import appStore from '@stores/app';
import FormBuilder from '@lib/FormBuilder';
import ImageCropUpload from '@components/ImageUpload/ImageCropUpload';
import ImageCropUploadOriginal from '@components/ImageUpload/ImageCropUploadOriginal';
import ModalWrapper from '@components/ModalWrapper';
import notify from '@lib/notifications';
import { categoriesImages, imagesConf } from '@lib/constants';
import { newTempDocID } from '@lib/firebaseHandler';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

@observer
class SessionRow extends Component {
    render() {
        return (
            <List.Item
                style={{
                    zIndex: 11,
                    cursor: 'move',
                    height: 40
                }}
            >
                ({this.props.value.sessionOrder}) {this.props.value.sessionName}
            </List.Item>
        );
    }
}

const SortableSession = SortableElement(({ value }) => {
    return <SessionRow value={value} />;
});

const SortableList = SortableContainer(({ items }) => {
    return (
        <List bordered>
            {items.map((item, index) => {
                return <SortableSession key={item.sessionId} index={index} value={item} />;
            })}
        </List>
    );
});

const formItemLayout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 20
    }
};

const categoryInitialState = { tags: [], authors: [] };

@observer
class CategoriesEdit extends Component {
    @observable busy = false;
    @observable currentTab = 'category';
    @observable category = this.props.category || categoryInitialState;
    @observable sortedSessions = [];
    @observable categoryTags = this.category.tags || [];

    @observable searchText = '';

    constructor(props) {
        super(props);
        makeObservable(this);

        when(
            () => {
                return `${this.category?.id}_${appStore.sessions.length}`;
            },
            () => {
                this.sortedSessions = _.chain(appStore.joins.relationships.orderedSessionsCategory)
                    .filter({ categoryId: this.category.id })
                    .sortBy('sessionOrder')
                    .value();
            }
        );

        when(
            () => this.category.id,
            () => {
                this.categoryTags = _.chain(appStore.joins.relationships.orderedCategoriesTag)
                    .filter({ categoryId: this.category.id })
                    .value();
            }
        );
    }

    @computed get filteredData() {
        return this.searchText
            ? _.filter(appStore.categories, item => {
                  return item.name.match(new RegExp(this.searchText, 'gi'));
              })
            : appStore.categories;
    }

    @computed get orderArray() {
        return _.fill(Array(appStore.categories.length), '');
    }

    @computed get categoryMeta() {
        return {
            formItemLayout,
            elements: [
                {
                    key: 'name',
                    label: 'Name',
                    widget: Input,
                    required: true,
                    initialValue: this.category.name,
                    rules: [
                        {
                            validator: (r, value, cb) => {
                                const nodupe = _.isEmpty(
                                    _.pickBy(appStore.categories, item => {
                                        return item.name === value && this.category.id !== item.id;
                                    })
                                );

                                cb(nodupe ? undefined : false);
                            },
                            message: 'Duplicate category'
                        }
                    ]
                },
                {
                    key: 'description',
                    label: 'Description',
                    widget: TextArea,
                    widgetProps: { rows: 4 },
                    required: true,
                    initialValue: this.category.description
                },
                {
                    key: 'tags',
                    label: 'Tags',
                    widget: Select,
                    widgetProps: { mode: 'multiple' },
                    initialValue: _.uniq(
                        _.compact(this.categoryTags.map(item => (item.tagId !== 'All' ? item.tagId : null)))
                    ),

                    children: appStore.tags.map(item => {
                        return item.id !== 'All' ? (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        ) : null;
                    })
                },
                {
                    key: 'authors',
                    label: 'Authors',
                    widget: Select,
                    widgetProps: { mode: 'multiple' },
                    initialValue: this.category.authors,

                    children: appStore.authors.map(item => {
                        return (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        );
                    })
                }
            ]
        };
    }

    componentDidMount() {
        if (!this.category.id) {
            const newSession = newTempDocID('categories');
            this.category.id = newSession.id;
        }
    }

    onModalOk = async () => {
        if (this.currentTab === 'sessions_order') {
            try {
                this.busy = true;
                await appStore.updateSessionsSorting(this.category.id, this.sortedSessions);
                this.busy = false;
                this.currentTab = 'category';
                notify('success', 'updateSessionsSort');
            } catch (error) {
                notify('error', 'updateSessionsSort');
            }
        } else {
            this.props.form.validateFieldsAndScroll(async (errors, values) => {
                if (errors) {
                    return false;
                }
                _.assign(this.category, values);

                this.category.orderName = _.chain(this.category.name).lowerCase().replace(/\s/gi, '').value();

                if (!this.props.edit) {
                    try {
                        this.busy = true;
                        this.adding = false;
                        await appStore.addCategory(this.category);
                        notify('success', 'addCategory');
                        this.props.closeModal();
                    } catch (error) {
                        notify('error', 'addCategory');
                    }
                } else {
                    try {
                        this.busy = true;
                        await appStore.updateCategory(this.category);
                        notify('success', 'updateCategory');
                        this.props.closeModal();
                    } catch (error) {
                        notify('error', 'updateCategory');
                    }
                }
                this.busy = false;
            });
        }
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.sortedSessions = move(this.sortedSessions, oldIndex, newIndex);
        _.each(this.sortedSessions, (item, idx) => {
            this.sortedSessions[idx].sessionOrder = idx + 1;
        });
    };

    onCopy = () => {
        message.success('Link successfully copied to clipboard');
    };

    setContent = content => {
        _.assign(this.category, content);
    };

    render() {
        return (
            <>
                <Tabs
                    defaultActiveKey={this.currentTab}
                    activeKey={this.currentTab}
                    animated={false}
                    type="card"
                    onChange={tab => (this.currentTab = tab)}
                    okButtonProps={{ loading: this.busy }}
                >
                    <TabPane tab="Category" key="category">
                        <Form>
                            <FormBuilder meta={this.categoryMeta} form={this.props.form} />

                            <Form.Item {...formItemLayout} label="Images">
                                <ImageCropUpload
                                    unsplash
                                    storageFolder={'categories'}
                                    imagesFormat={categoriesImages}
                                    content={this.category}
                                    onSave={this.setContent}
                                />
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Images (New)">
                                <span style={{ opacity: '0.5' }}>
                                    Here we need to upload the bigger image size we have, 2700 pixels at least
                                </span>
                                <ImageCropUploadOriginal
                                    unsplash
                                    storageFolder={'categories'}
                                    imagesFormat={imagesConf}
                                    content={this.category}
                                    onSave={this.setContent}
                                />
                            </Form.Item>
                        </Form>
                    </TabPane>
                    {this.category.id && (
                        <TabPane tab="Sessions order" key="sessions_order">
                            {!this.sortedSessions.length && <Empty description="This category has no sessions" />}
                            {!!this.sortedSessions.length && (
                                <SortableList items={this.sortedSessions} onSortEnd={this.onSortEnd} />
                            )}
                        </TabPane>
                    )}
                </Tabs>
                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" loading={this.busy} onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </>
        );
    }
}

export default ModalWrapper(Form.create()(CategoriesEdit));
