import React from 'react';

import _ from 'lodash';
import move from 'lodash-move';
import { List } from 'antd';

import { observer, useLocalObservable } from 'mobx-react';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const ItemRow = ({ value }) => {
    return (
        <List.Item
            style={{
                zIndex: 1000,
                cursor: 'move',
                height: 40
            }}
        >
            {value.name}
        </List.Item>
    );
};

const SortableItem = SortableElement(({ value }) => {
    return <ItemRow value={value} />;
});

const SortableList = SortableContainer(({ items }) => {
    return (
        <List bordered>
            {items.map((item, index) => {
                return <SortableItem key={item.id} index={index} value={item} />;
            })}
        </List>
    );
});

const SimpleSortList = observer(({ items, onChange }) => {
    const state = useLocalObservable(() => ({
        sortedItems: _.map(items)
    }));

    const onSortEnd = ({ oldIndex, newIndex }) => {
        state.sortedItems = move(state.sortedItems, oldIndex, newIndex);
        onChange(state.sortedItems);
    };

    return <SortableList items={state.sortedItems} onSortEnd={onSortEnd} />;
});

export { SimpleSortList };
