import _ from 'lodash';
import appStore from '@stores/app';
import ModalWrapper from '@components/ModalWrapper';
import move from 'lodash-move';
import React, { Component } from 'react';
import { Button, Divider, List } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { saveSortedGoals } from '@stores/middleware/goals';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

class GoalRow extends Component {
    render() {
        return (
            <List.Item
                style={{
                    zIndex: 11,
                    cursor: 'move',
                    height: 40
                }}
            >
                {this.props.value.name}
            </List.Item>
        );
    }
}

@observer
class GoalsOrder extends Component {
    @observable sortedGoals = appStore.goals;
    @observable busy = false;

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.sortedGoals = move(this.sortedGoals, oldIndex, newIndex);
        _.each(this.sortedGoals, (item, idx) => {
            this.sortedGoals[idx].order = idx + 1;
        });
    };

    onModalOk = async () => {
        this.busy = true;
        await saveSortedGoals(this.sortedGoals);
        this.busy = false;
        this.props.closeModal();
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        const SortableGoal = SortableElement(({ value }) => {
            return <GoalRow value={value} />;
        });

        const SortableList = SortableContainer(({ items }) => {
            return (
                <List bordered>
                    {items.map((item, index) => {
                        return <SortableGoal key={item.id} index={index} value={item} />;
                    })}
                </List>
            );
        });

        return (
            <div>
                <SortableList items={this.sortedGoals} onSortEnd={this.onSortEnd} />
                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" loading={this.busy} onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }
}

export default ModalWrapper(GoalsOrder);
