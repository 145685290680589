const notifications = {
    error: {
        vipAdd: {
            title: 'Error adding VIP user',
            content: 'There was an error while trying to add user as VIP. Please try again.',
            centered: true
        },
        vipRemove: {
            title: 'Error removing VIP user',
            content: 'There was an error while trying to remove user as VIP. Please try again.',
            centered: true
        },
        userAdd: {
            title: 'Error adding the user',
            content: 'There was an error while trying to add the user to RevenueCat.',
            centered: true
        },
        userDelete: {
            title: 'Error deleting user',
            content: 'There was an error while trying to delete user from database. Please try again.',
            centered: true
        },
        emailSearch: {
            title: 'Email not found',
            content: 'Email was not found in database. Please try again.',
            centered: true
        },
        sortCategories: {
            title: 'Error sorting categories',
            content: 'Categories could not be sorted. Please try again.',
            centered: true
        },
        addTag: {
            title: 'Error adding tag',
            content: 'Tag could not be added. Please try again',
            centered: true
        },
        updateTag: {
            title: 'Error updating tag',
            content: 'Tag could not be updated. Please try again',
            centered: true
        },
        deleteTag: {
            title: 'Error deleting tag',
            content: 'Tag could not be deleted. Please try again',
            centered: true
        },
        updateSessionsSort: {
            title: 'Error sorting sessions',
            content: 'Sessions could not be sorted. Please try again',
            centered: true
        },
        uploadCategoryImage: {
            title: 'Error uploading category image',
            content: 'Category image could not be uploaded. Please try again',
            centered: true
        },
        addCategory: {
            title: 'Error adding category',
            content: 'Category could not be added. Please try again',
            centered: true
        },
        updateCategory: {
            title: 'Error updating category',
            content: 'Category could not be updated. Please try again',
            centered: true
        },
        deleteCategory: {
            title: 'Error deleting category',
            content: 'Category could not be deleted. Please try again',
            centered: true
        },
        uploadAuthorImage: {
            title: 'Error uploading author image',
            content: 'Author image could not be uploaded. Please try again',
            centered: true
        },
        addAuthor: {
            title: 'Error adding author',
            content: 'Author could not be added. Please try again',
            centered: true
        },
        updateAuthor: {
            title: 'Error updating author',
            content: 'Author could not be updated. Please try again',
            centered: true
        },
        deleteAuthor: {
            title: 'Error deleting author',
            content: 'Author could not be deleted. Please try again',
            centered: true
        },
        updateGoal: {
            title: 'Error updating goal',
            content: 'Goal could not be updated. Please try again',
            centered: true
        },
        updateAppConfig: {
            title: 'Error updating goal',
            content: 'Initial app sessions could not be updated. Please try again',
            centered: true
        },
        addSession: {
            title: 'Error adding session',
            content: 'Session could not be added. Please try again',
            centered: true
        },
        saveSession: {
            title: 'Error saving session',
            content: 'Session could not be saved. Please try again',
            centered: true
        },
        updateSession: {
            title: 'Error updating session',
            content: 'Session could not be updated. Please try again',
            centered: true
        },
        deleteSession: {
            title: 'Error deleting session',
            content: 'Session could not be deleted. Please try again',
            centered: true
        },
        addPage: {
            title: 'Error adding page',
            content: 'Page could not be added. Please try again',
            centered: true
        },
        updatePage: {
            title: 'Error updating page',
            content: 'Page could not be updated. Please try again',
            centered: true
        },
        deletePage: {
            title: 'Error deleting page',
            content: 'Page could not be deleted. Please try again',
            centered: true
        },
        apiBackupEndpoint: {
            title: 'Error with content backup',
            content: 'Content backup could not be performed. Please try again',
            centered: true
        },
        apiPublishEndpoint: {
            title: 'Error publishing content',
            content: 'Content could not be published. Please try again',
            centered: true
        },
        updateAppSettings: {
            title: 'Error saving force min version',
            content: 'Minimum app version could not be saved. Please try again',
            centered: true
        },
        feedbackDownload: {
            title: 'Error downloading file',
            content: 'The feedback file could not be downloaded. Please try again',
            centered: true
        },
        addingDeepLink: {
            title: 'Error adding deep link',
            content: 'The Deep link could not be added. Please try again.',
            centered: true
        },
        editingDeepLink: {
            title: 'Error editing deep link',
            content: 'The Deep link could not be edited. Please try again.',
            centered: true
        },
        removingDeepLink: {
            title: 'Error removing deep link',
            content: 'The Deep link could not be removed. Please try again.',
            centered: true
        },
        repeatedDeepLink: {
            title: 'Deep link already exists',
            content: 'The Deep link you are trying to add already exists.',
            centered: true
        },
        surveyDownload: {
            title: 'Error downloading file',
            content: 'The survey file could not be downloaded. Please try again',
            centered: true
        },
        automatedOffer: {
            title: 'Error adding automated offer',
            content: 'The automated offer could not be added. Please try again.',
            centered: true
        }
    },
    success: {
        vipAdd: {
            title: 'VIP user added',
            content: 'User added as VIP successfully.',
            centered: true
        },
        vipRemove: {
            title: 'VIP user removed',
            content: 'User removed as VIP successfully.',
            centered: true
        },
        userAdd: {
            title: 'User Added',
            content: 'Please check in RevenueCat the user id is correct.',
            centered: true
        },
        userDelete: {
            title: 'User deleted',
            content: 'User deleted from database successfully.',
            centered: true
        },
        sortCategories: {
            title: 'Categories sorted',
            content: 'Categories sorted successfully',
            centered: true
        },
        addTag: {
            title: 'Tag added',
            content: 'Tag added successfully',
            centered: true
        },
        updateTag: {
            title: 'Tag updated',
            content: 'Tag updated successfully',
            centered: true
        },
        deleteTag: {
            title: 'Tag deleted',
            content: 'Tag deleted successfully.',
            centered: true
        },
        updateSessionsSort: {
            title: 'Sessions sorted',
            content: 'Sessions sorted successfully.',
            centered: true
        },
        uploadCategoryImage: {
            title: 'Category image uploaded',
            content: 'Category image uploaded successfully.',
            centered: true
        },
        addCategory: {
            title: 'Category added',
            content: 'Category added successfully',
            centered: true
        },
        updateCategory: {
            title: 'Category updated',
            content: 'Category updated successfully',
            centered: true
        },
        deleteCategory: {
            title: 'Category deleted',
            content: 'Category deleted successfully',
            centered: true
        },
        uploadAuthorImage: {
            title: 'Author image uploaded',
            content: 'Author image uploaded successfully',
            centered: true
        },
        addAuthor: {
            title: 'Author added',
            content: 'Author added successfully',
            centered: true
        },
        updateAuthor: {
            title: 'Author updated',
            content: 'Author updated successfully',
            centered: true
        },
        deleteAuthor: {
            title: 'Author deleted',
            content: 'Author deleted successfully',
            centered: true
        },
        addGroup: {
            title: 'Group added',
            content: 'Group added successfully',
            centered: true
        },
        updateGroup: {
            title: 'Group updated',
            content: 'Group updated successfully',
            centered: true
        },
        deleteGroup: {
            title: 'Group deleted',
            content: 'Author deleted successfully',
            centered: true
        },
        updateGoal: {
            title: 'Goal updated',
            content: 'Goal updated successfully',
            centered: true
        },
        updateAppConfig: {
            title: 'Initial sessions updated',
            content: 'Initial app sessions updated successfully',
            centered: true
        },
        addSession: {
            title: 'Session added',
            content: 'Session added successfully',
            centered: true
        },
        saveSession: {
            title: 'Session saved',
            content: 'Session saved successfully',
            centered: true
        },
        updateSession: {
            title: 'Session updated',
            content: 'Session updated successfully',
            centered: true
        },
        deleteSession: {
            title: 'Session deleted',
            content: 'Session deleted successfully',
            centered: true
        },
        addPage: {
            title: 'Page added',
            content: 'Page added successfully',
            centered: true
        },
        updatePage: {
            title: 'Page updated',
            content: 'Page updated successfully',
            centered: true
        },
        deletePage: {
            title: 'Page deleted',
            content: 'Page deleted successfully',
            centered: true
        },
        apiBackupEndpoint: {
            title: 'Content backup performed',
            content: 'Content backup performed successfully',
            centered: true
        },
        apiPublishEndpoint: {
            title: 'Content published',
            content: 'Content published successfully',
            centered: true
        },
        updateAppSettings: {
            title: 'Force min version saved',
            content: 'Minimum app version saved successfully',
            centered: true
        },
        feedbackDownload: {
            title: 'Feedback file downloaded',
            content: 'The feedback file downloaded successfully.',
            centered: true
        },
        addingDeepLink: {
            title: 'Deep Link added',
            content: 'Deep Link added successfully.',
            centered: true
        },
        editingDeepLink: {
            title: 'Deep Link updated',
            content: 'Deep Link updated successfully.',
            centered: true
        },
        removingDeepLink: {
            title: 'Deep Link removed',
            content: 'Deep Link removed successfully.',
            centered: true
        },
        surveyDownload: {
            title: 'Survey file downloaded',
            content: 'The survey file downloaded successfully.',
            centered: true
        },
        automatedOffer: {
            title: 'Automated offer added',
            content: 'Automated offer added successfully.',
            centered: true
        }
    }
};

const contentTypes = [
    { id: 'session', name: 'Session' },
    { id: 'story', name: 'Story' }
];

const authorsImages = [
    { id: 'thumbnailImageUrl', name: 'avatar', size: [560, 560], thumb: [100, 100], path: 'authors' }
];

const categoriesImages = [
    { id: 'headerImageUrl', name: 'header', size: [1120, 746], thumb: [112, 75], path: 'categories' },
    { id: 'thumbnailImageUrl', name: 'thumbnail', size: [560, 560], thumb: [100, 100], path: 'categories' },
    { id: 'thumbnailHome', name: 'thumbnailHome', size: [416, 576], thumb: [52, 72], path: 'categories' }
];

const sessionsImages = [
    { id: 'headerImageUrl', name: 'header', size: [1120, 746], thumb: [112, 75], path: 'sessions' },
    { id: 'thumbnailImageUrl', name: 'thumbnail', size: [560, 560], thumb: [100, 100], path: 'sessions' },
    { id: 'thumbnailHome', name: 'thumbnailHome', size: [416, 576], thumb: [52, 72], path: 'sessions' }
];

const episodesImages = [
    { id: 'headerImageUrl', name: 'header', size: [1120, 746], thumb: [112, 75], path: 'episodes' },
    { id: 'thumbnailImageUrl', name: 'thumbnail', size: [560, 560], thumb: [100, 100], path: 'episodes' },
    { id: 'thumbnailHome', name: 'thumbnailHome', size: [416, 576], thumb: [52, 72], path: 'episodes' }
];

const imagesConf = [
    { id: 'wide', name: 'wide', size: [2700, 1520], thumb: [135, 76] },
    { id: 'square', name: 'square', size: [2700, 2700], thumb: [100, 100] }
];

const wildCardImages = [
    {
        id: 'backgroundImageUrl',
        name: 'background',
        size: [1029, 792],
        thumb: [86, 66],
        path: 'appAssets/widgets/wildcards'
    }
];

const businessPartnersLogos = [
    { id: 'logoImageUrl', name: 'logo', size: [380, 55], thumb: [95, 13], path: 'businessPartners' }
];

const sessionTypes = [
    {
        id: 'guided',
        name: 'Guided',
        tags: [
            {
                id: 'toneofvoice',
                name: 'Tone of voice'
            },
            {
                id: 'makeitshorter',
                name: 'Make it shorter'
            },
            {
                id: 'makeitlonger',
                name: 'Make it longer'
            },
            {
                id: 'moreguidance',
                name: 'More guidance'
            },
            {
                id: 'notinthemood',
                name: 'Not in the mood'
            }
        ]
    },

    {
        id: 'foodforthought',
        name: 'Food for thought',
        tags: [
            {
                id: 'toneofvoice',
                name: 'Tone of voice'
            },
            {
                id: 'makeitshorter',
                name: 'Make it shorter'
            },
            {
                id: 'makeitlonger',
                name: 'Make it longer'
            },
            {
                id: 'moreguidance',
                name: 'More guidance'
            },
            {
                id: 'notinthemood',
                name: 'Not in the mood'
            }
        ]
    },
    {
        id: 'theory',
        name: 'Theory',
        tags: [
            {
                id: 'toneofvoice',
                name: 'Tone of voice'
            },
            {
                id: 'lessfacts',
                name: 'Less facts'
            },
            {
                id: 'moreexplanation',
                name: 'More explanation'
            },
            {
                id: 'moreinteresting',
                name: 'More interesting'
            },
            {
                id: 'readsummaryinstead',
                name: 'Read summary instead'
            }
        ]
    },
    {
        id: 'stories', // this is the only place we keep stories as session type, to be legacy compatible
        name: 'Stories',
        tags: [
            {
                id: 'toneofvoice',
                name: 'Tone of voice'
            },
            {
                id: 'changestoryline',
                name: 'Change storyline'
            },
            {
                id: 'addsoundeffects',
                name: 'Add sound effects'
            },
            {
                id: 'morearousal',
                name: 'More arousal'
            }
        ]
    }
];

export {
    notifications,
    sessionTypes,
    contentTypes,
    sessionsImages,
    categoriesImages,
    authorsImages,
    imagesConf,
    wildCardImages,
    episodesImages,
    businessPartnersLogos
};
