import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import { Avatar, Layout, Row, Table } from 'antd';
import { Form } from '@ant-design/compatible';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { QuestionOutlined } from '@ant-design/icons';

import appStore from '@stores/app';
import AuthorEdit from './AuthorEdit';
import notify from '@lib/notifications';
import { DeleteIconButton } from '@components/Buttons';
import { ViewHeader } from '@components/ViewHeader';

@observer
class AuthorView extends Component {
    @observable busy = false;

    onDeleteAuthor = async record => {
        try {
            await appStore.deleteAuthor(record.id);
            notify('success', 'deleteAuthor');
        } catch (error) {
            notify('error', 'deleteAuthor');
        }
    };

    columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 100,
            render: (text, record) => {
                return (
                    <Avatar
                        size={50}
                        icon={<QuestionOutlined />}
                        style={{ marginRight: 10 }}
                        src={record.thumbnailImageUrl}
                    />
                );
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            width: 90,
            key: 'edit',
            render: (text, record) => (
                <Row style={{ display: 'flex' }}>
                    <AuthorEdit table edit author={record} title={'Edit author'} />
                    <DeleteIconButton
                        onClick={() => {
                            this.onDeleteAuthor(record);
                        }}
                    />
                </Row>
            )
        }
    ];

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <ViewHeader>
                    <AuthorEdit title={'Add a new author'} textButton={'Add Author'} />
                </ViewHeader>
                <Table
                    bordered
                    size="small"
                    dataSource={appStore.authors}
                    rowKey={record => record.id}
                    columns={this.columns}
                />
            </Layout>
        );
    }
}

export default Form.create()(AuthorView);
