import React from 'react';

import { Form } from '@ant-design/compatible';
import { observer, useLocalObservable } from 'mobx-react';

import _ from 'lodash';
import ImageCropUpload from '@components/ImageUpload/ImageCropUpload';
import ModalWrapper from '@components/ModalWrapper';
import { EmFormBuilder } from '@components/EmFormBuilder';
import { EmSelect } from '@components/EmSelect';
import { widgetsStore } from '@stores/middleware/widgets';
import { wildCardImages } from '@lib/constants';

import { defaultOptions } from '@lib/widgetsConfig';

const WidgetsEdit = observer(({ record = {}, onSave, closeModal, location }) => {
    const state = useLocalObservable(() => ({
        componentType: record?.componentType || null,
        wildCardImage: null,
        setComponentType(componentType) {
            this.componentType = componentType;
        },
        get initialValues() {
            const data = { ...record.data }; // this will create an empty object if record.data is not defined
            _.each(defaultOptions, ({ key }) => {
                data[key] = record[key];
            });

            return data;
        },
        get fields() {
            const out = _.find(widgetsStore.availableComponents, { componentType: this.componentType });
            return out?.options || [];
        }
    }));

    const onSubmit = values => {
        values = { ...values, ...state.wildCardImage };
        widgetsStore.saveWidget({ ...values, componentType: state.componentType });
        onSave && onSave();
        closeModal();
    };

    if (location) {
        record ? (record.location = location) : (record = { location });
    }
    if (_.isNil(record.order)) {
        record.order = widgetsStore.activeComponents.length;
    }

    const formItemLayout = {
        labelCol: {
            span: 3
        },
        wrapperCol: {
            span: 20
        }
    };

    const setContent = content => {
        state.wildCardImage = content;
        _.assign(record, content);
    };

    return (
        <>
            <div style={{ marginLeft: 220, marginBottom: 30, width: 300, display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 10 }}>Component:</span>
                {record?.componentType ? (
                    record.componentType
                ) : (
                    <EmSelect
                        values={widgetsStore.availableComponentsAdvancedFiltered}
                        placeholder={'Select Widget'}
                        handleChange={state.setComponentType}
                    />
                )}
            </div>
            <EmFormBuilder
                meta={{
                    initialValues: state.initialValues,
                    fields: state.fields
                }}
                initialValues={record}
                bottomComponent={
                    state.componentType === 'WildCard' ? (
                        <>
                            <Form.Item {...formItemLayout} label="Images">
                                <ImageCropUpload imagesFormat={wildCardImages} content={record} onSave={setContent} />
                            </Form.Item>
                        </>
                    ) : null
                }
                onSubmit={onSubmit}
            />
        </>
    );
});

export default ModalWrapper(WidgetsEdit);
