import React, { Component } from 'react';

import 'react-quill/dist/quill.snow.css';
import _ from 'lodash';
import { Avatar, Button, Input, Layout, message, Row, Table, Tag, Tooltip } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { CopyOutlined, ExclamationCircleOutlined, QuestionOutlined, SyncOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import notify from '@lib/notifications';
import SessionsEdit from './SessionsEdit';
import { DeleteIconButton } from '@components/Buttons';
import { ViewHeader } from '@components/ViewHeader';

// We are leaving this commented as template in case we need to add something to modules constant.
// const modulesDefault = {
//     toolbar: [
//         [{ header: [1, 2, false] }],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
//         ['link', 'image'],
//         ['clean']
//     ]
// };

@observer
class SessionsView extends Component {
    mediaUpload = React.createRef();
    @observable uploadProgress = 0;

    @observable busy = false;

    @observable session = {};
    @observable sessionCategories = [];

    @observable searchText = '';

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get filteredData() {
        return this.searchText
            ? _.chain(appStore.sessions)
                  .filter(item => {
                      return item.name.match(new RegExp(this.searchText, 'gi'));
                  })
                  .sortBy('name')
                  .value()
            : _.chain(appStore.sessions).sortBy('name').value();
    }

    @computed get orderArray() {
        return _.fill(Array(appStore.sessions.length), '');
    }

    onDeleteSession = async record => {
        try {
            await appStore.deleteSession(record.id);
            notify('success', 'deleteSession');
        } catch (error) {
            notify('error', 'deleteSession');
        }
    };

    onEditMedia = () => {
        _.assign(this.session, this.props.form.getFieldsValue());
        this.editingMedia = true;
    };

    updateDescription = content => {
        this.session.description = content;
    };

    onCopy = () => {
        message.success('Link successfully copied to clipboard');
    };

    renderNameColumn = (text, record) => {
        const hasAudio = !!record.mediaFileName && !!record.playUrl;
        return (
            <>
                {record.converting && (
                    <Tooltip title="Converting...">
                        <SyncOutlined spin style={{ color: 'orange', marginRight: 10 }} />
                    </Tooltip>
                )}
                {!hasAudio && (
                    <Tooltip title="Missing audio">
                        <ExclamationCircleOutlined style={{ color: '#f00', marginRight: 10 }} />
                    </Tooltip>
                )}
                {record.name}
            </>
        );
    };

    renderCategoriesColumn = (text, record) => {
        if (record.categories) {
            return record.categories.map(item => {
                return <Tag key={item.categoryId}>{item.categoryName}</Tag>;
            });
        }
    };

    renderDeepLinks = (text, record) => {
        return _.map(appStore.deepLinks, item => {
            if (item.id === 'sessions') {
                const deepLinktoString = `${item.link}?deeplink=session/${record.id}`.toString();
                return (
                    <div
                        key={item.key}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 5
                        }}
                    >
                        <div style={{ textAlign: 'center' }}>{deepLinktoString}</div>

                        <CopyToClipboard onCopy={this.onCopy} text={deepLinktoString}>
                            <Button type="normal" size="small" icon={<CopyOutlined />}>
                                Copy
                            </Button>
                        </CopyToClipboard>
                    </div>
                );
            }
        });
    };

    renderRelatedColumn = (text, record) => {
        if (record.related) {
            return record.related.map(relatedId => {
                const relatedSession = appStore.categories.find(item => {
                    return item.id == relatedId;
                });

                return relatedSession ? <Tag key={relatedSession.id}>{relatedSession.name}</Tag> : null;
            });
        }
    };

    renderAuthorsColumn = (text, record) => {
        if (record.authors) {
            return record.authors.map(authorId => {
                const author = appStore.authors.find(item => {
                    return item.id == authorId;
                });

                return author ? <Tag key={author.id}>{author.name}</Tag> : null;
            });
        }
    };

    handleChangeFilter = e => {
        this.searchText = e.target.value;
    };

    columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 100,
            render: (text, record) => {
                return (
                    <Avatar
                        size={50}
                        shape="square"
                        icon={<QuestionOutlined />}
                        style={{ marginRight: 10 }}
                        src={record.thumbnailImageUrl}
                    />
                );
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: this.renderNameColumn
        },
        {
            title: 'Collections',
            dataIndex: 'categories',
            key: 'categories',
            render: this.renderCategoriesColumn
        },
        {
            title: 'Deep link',
            dataIndex: 'link',
            key: 'link',
            render: this.renderDeepLinks
        },
        {
            width: 90,
            key: 'edit',
            render: (text, record) => (
                <Row style={{ display: 'flex' }}>
                    <SessionsEdit table edit content={record} title={'Edit content'} />

                    <DeleteIconButton
                        onClick={() => {
                            this.onDeleteSession(record);
                        }}
                    />
                </Row>
            )
        }
    ];

    render() {
        return (
            <Layout style={{ backgroundColor: '#fff' }}>
                <ViewHeader
                    input={
                        <Input
                            type="text"
                            style={{ width: '44%' }}
                            allowClear
                            name="filter"
                            onChange={this.handleChangeFilter}
                            placeholder="Filter by name..."
                            value={this.searchText}
                        />
                    }
                >
                    <SessionsEdit title={'Add content'} textButton={'New Content'} style={{ paddingLeft: 100 }} />
                </ViewHeader>

                <Table
                    bordered
                    size="small"
                    dataSource={this.filteredData}
                    rowKey={record => record.id}
                    columns={this.columns}
                />
            </Layout>
        );
    }
}

export default SessionsView;
