import React from 'react';

import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { DeleteIconButton } from '@components/Buttons';
import { Layout, Row } from 'antd';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import FeatureFlagsEdit from './FeatureFlagsEdit';
import { deleteFeatureFlag } from '@stores/middleware/featureFlags';
import { EmTable } from '@components/EmTable';
import { ViewHeader } from '@components/ViewHeader';

const { Content } = Layout;

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: 'Active',
        key: 'active',
        render: (text, record) => (
            <Row>
                {record?.active ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                )}
            </Row>
        )
    },
    {
        width: 120,
        title: 'Actions',
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
            <Row style={{ display: 'flex' }}>
                <FeatureFlagsEdit table edit record={record} title={'Edit author'} />
                <DeleteIconButton
                    onClick={() => {
                        deleteFeatureFlag(record);
                    }}
                />
            </Row>
        )
    }
];

const FeatureFlagsView = observer(() => {
    return (
        <Layout style={{ backgroundColor: '#fff' }}>
            <ViewHeader>
                <FeatureFlagsEdit title={'Add a new Feature Flag'} textButton={'Add FeatureFlag'} />
            </ViewHeader>
            <Content>
                <EmTable data={appStore.featureFlags} columns={columns} />
            </Content>
        </Layout>
    );
});

export { FeatureFlagsView };
