import React, { Component, Fragment } from 'react';

import axios from 'axios';

import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Row, Select, Table, Tabs } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { DeleteIconButton, EditIconButton } from '../../../Components/Buttons';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';
import { PlusCircleOutlined } from '@ant-design/icons';

import FormBuilder from '../../../lib/FormBuilder';
import notify from '../../../lib/notifications';

import appStore from '../../../Stores/app';

import _ from 'lodash';

import config from '@lib/config.json';

const { apiManageDeepLink } = config;

const { Option } = Select;
const { TabPane } = Tabs;

const formItemLayout = {
    labelCol: {
        span: 2
    },
    wrapperCol: {
        span: 20
    }
};

@observer
class GrowthScreen extends Component {
    @observable busy = false;
    @observable deepLinkHandler = '';
    @observable newDeepLink = '';
    @observable newDeepLinkType = '';
    @observable oldDeepLink = '';
    @observable oldDeepLinkType = '';

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get deepLinkMeta() {
        const linkToTypes = ['sessions', 'collections'];
        return {
            formItemLayout,

            elements: [
                {
                    key: 'link',
                    label: 'Link',
                    widget: Input,
                    required: true,
                    initialValue: this.oldDeepLink
                },
                {
                    key: 'linktype',
                    label: 'Link Type',
                    widget: Select,
                    widgetProps: {
                        mode: 'default'
                    },
                    required: true,
                    initialValue: this.oldDeepLinkType,
                    children: linkToTypes.map(item => {
                        return (
                            <Option key={item} value={item}>
                                {item}
                            </Option>
                        );
                    })
                }
            ]
        };
    }

    onNewDeepLink = () => {
        this.deepLinkHandler = 'adding';
    };

    addDeepLink = async () => {
        this.props.form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return false;
            }

            this.busy = true;
            const addDeepLinkFormData = this.props.form.getFieldsValue();
            const { link, linktype } = addDeepLinkFormData;

            this.newDeepLink = link;
            this.newDeepLinkType = linktype;

            try {
                _.each(appStore.deepLinks, item => {
                    if (item.id === this.newDeepLinkType) {
                        if (item.link === this.newDeepLink) {
                            throw 'repeated';
                        }
                    }
                });
                const postDeepLink = await axios.post(apiManageDeepLink, {
                    newDeepLink: this.newDeepLink,
                    newDeepLinkType: this.newDeepLinkType,
                    deepLinkHandler: this.deepLinkHandler,
                    oldDeepLink: this.oldDeepLink,
                    oldDeepLinkType: this.oldDeepLinkType
                });

                this.busy = false;
                this.oldDeepLink = '';
                this.oldDeepLinkType = '';

                notify('success', `${this.deepLinkHandler}DeepLink`);
                this.onModalCancel();
            } catch (error) {
                if (error === 'repeated') {
                    notify('error', 'repeatedDeepLink');
                    this.busy = false;
                } else {
                    this.busy = false;
                    notify('error', `${this.deepLinkHandler}DeepLink`);
                }
            }
        });
    };

    onModalCancel = () => {
        this.resetDeepLink();
    };

    resetDeepLink = () => {
        this.busy = false;
        this.newDeepLink = '';
        this.newDeepLinkType = '';
        this.oldDeepLink = '';
        this.oldDeepLinkType = '';
        this.deepLinkHandler = '';
    };

    onEditDeepLink = record => {
        this.oldDeepLinkType = record.id;
        this.oldDeepLink = record.link;
        this.deepLinkHandler = 'editing';
    };

    onDeleteDeepLink = record => {
        this.oldDeepLinkType = record.id;
        this.oldDeepLink = record.link;
        this.deepLinkHandler = 'removing';
        this.addDeepLink();
    };

    renderLinkColumn = (text, record) => {
        return record.link;
    };

    renderLinkTypeColumn = (text, record) => {
        return record.id;
    };

    columnsDeepLinks = [
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: this.renderLinkColumn
        },
        {
            title: 'Link Type',
            dataIndex: 'linktype',
            key: 'linktype',
            render: this.renderLinkTypeColumn
        },
        {
            width: 90,
            key: 'edit',
            render: (text, record) => (
                <Row>
                    <EditIconButton
                        onClick={() => {
                            this.onEditDeepLink(record);
                        }}
                    />

                    <DeleteIconButton
                        onClick={() => {
                            this.onDeleteDeepLink(record);
                        }}
                    />
                </Row>
            )
        }
    ];

    render() {
        return (
            <Fragment>
                <Tabs defaultActiveKey="deeplinking" animated={false} type="card">
                    <TabPane tab="Add Deep Link" key="deeplinking">
                        <Row type="flex" justify="end" style={{ padding: 20 }}>
                            <Button type="primary" icon={<PlusCircleOutlined />} onClick={this.onNewDeepLink}>
                                New Deep Link
                            </Button>
                        </Row>
                        <Table
                            bordered
                            size="small"
                            dataSource={appStore.deepLinks}
                            rowKey={record => record.key}
                            columns={this.columnsDeepLinks}
                        />
                    </TabPane>
                </Tabs>

                <Modal
                    destroyOnClose
                    maskClosable={false}
                    closable={false}
                    width={1000}
                    title={'Deep Link'}
                    visible={this.deepLinkHandler === 'adding' || this.deepLinkHandler === 'editing'}
                    footer={[
                        <Button key="close" loading={this.busy} type="primary" onClick={this.onModalCancel}>
                            Close
                        </Button>
                    ]}
                >
                    <Form>
                        <FormBuilder meta={this.deepLinkMeta} form={this.props.form} />

                        <Form.Item>
                            <Button type="primary" ghost loading={this.busy} onClick={this.addDeepLink}>
                                {this.deepLinkHandler === 'editing' ? 'Save' : 'Add'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default Form.create()(GrowthScreen);
