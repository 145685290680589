import React, { Component } from 'react';

import _ from 'lodash';
import { Button, Divider, Tree } from 'antd';

import appStore from '@stores/app';
import ModalWrapper from '@components/ModalWrapper';
import notify from '@lib/notifications';
import { computed, makeObservable, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

const { TreeNode } = Tree;

@observer
class GoalsSessions extends Component {
    @observable goal = this.props.goal;
    @observable busy = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get goalData() {
        return _.chain(appStore.joins.relationships.orderedCategoriesTag)
            .pickBy({ tagId: 'All' })
            .map(item => {
                const category = toJS(item);
                category.sessions = _.chain(appStore.joins.relationships.orderedSessionsCategory)
                    .pickBy({ categoryId: category.categoryId })
                    .map(item => item)
                    .sortBy('sessionOrder')
                    .value();

                return category;
            })
            .sortBy('categoryOrder')
            .value();
    }

    onModalOk = async () => {
        try {
            this.busy = true;
            await appStore.updateGoal(this.goal);
            this.busy = false;
            this.props.closeModal();
            notify('success', 'updateGoal');
        } catch (error) {
            notify('error', 'updateGoal');
        }
    };

    onCheck = nextTargetSessions => {
        this.goal.sessions = nextTargetSessions.checked;
    };

    render() {
        return (
            <>
                <Tree
                    onCheck={this.onCheck}
                    checkable
                    checkStrictly
                    defaultCheckedKeys={this.props.goal.sessions}
                    defaultExpandAll
                >
                    {_.map(this.goalData, item => {
                        return (
                            <TreeNode
                                title={<b>{item.categoryName}</b>}
                                checkable={false}
                                selectable={false}
                                key={item.categoryId}
                            >
                                {item.sessions.map(session => {
                                    return <TreeNode title={session.sessionName} key={`${session.sessionId}`} />;
                                })}
                            </TreeNode>
                        );
                    })}
                </Tree>
                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" loading={this.busy} onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </>
        );
    }
}

export default ModalWrapper(GoalsSessions);
