import React from 'react';

import _ from 'lodash';
import { observer, useLocalObservable } from 'mobx-react';

import { EmRadio } from '@components/EmRadio';
import { EmSelect } from '@components/EmSelect';
import { groupsStore } from '@stores/middleware/groups';
import { OrderListItemsModal } from '@components/OrderListItems/OrderListItems';

const GroupSelector = observer(({ id, onChange, component, mode, value, noOrder = false, ...rest }) => {
    const state = useLocalObservable(() => {
        return {
            values: groupsStore.getGroupOptions(id),
            selected: value,
            get valuesToOrder() {
                return _.map(this.selected, item => _.find(this.values, { id: item }));
            },
            setSelected(newValues) {
                this.selected = newValues;
            },
            modalOrderVisible: false
        };
    });

    const onValuesChange = values => {
        state.setSelected(values);
        onChange?.(values);
    };

    const valuesToIds = values => {
        const ids = _.map(values, v => v.id);
        onValuesChange(ids);
    };

    switch (component) {
        case 'EmRadio':
            return <EmRadio onSelect={onValuesChange} values={state.values} />;
        default:
            return (
                <>
                    <EmSelect
                        handleChange={onValuesChange}
                        values={state.values}
                        mode={mode}
                        value={state.selected}
                        allowClear
                    />
                    {!noOrder && mode === 'multiple' && _.size(state.selected) > 1 && (
                        <OrderListItemsModal
                            items={state.valuesToOrder}
                            arrayOrder
                            onlyIds
                            onOrderUpdate={valuesToIds}
                        />
                    )}
                </>
            );
    }
});

export { GroupSelector };
