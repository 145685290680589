import React from 'react';

import _ from 'lodash';
import appStore from '@stores/app';
import { Tabs } from 'antd';

import GroupsView from 'Content/Groups/GroupsView';

import { partnersConfig } from '@lib/contentConfig';

const { TabPane } = Tabs;

const PartnersScreen = () => {
    //TODO it seems that initial values don't understand the dependsOn field
    return (
        <Tabs defaultActiveKey="campaigns" animated={false} type="card">
            {_.map(partnersConfig, group => {
                const tab = (
                    <TabPane tab={group.name} key={group.groupType}>
                        <GroupsView {...group} />
                    </TabPane>
                );
                return group.advanced ? appStore.advancedMode && tab : tab;
            })}
        </Tabs>
    );
};

export { PartnersScreen };
