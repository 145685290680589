import React from 'react';

import ReactQuill from 'react-quill';
import { observer } from 'mobx-react';

const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike', 'link'], ['clean']]
};

const TextAreaFormatted = observer(({ id, onChange, component, mode, height, value, contentType, defaultValue }) => {
    return (
        <ReactQuill
            theme="snow"
            modules={modules}
            defaultValue={value}
            onChange={onChange}
            style={{ height: height || 250, marginBottom: 50 }}
        />
    );
});

export { TextAreaFormatted };
