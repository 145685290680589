import _ from 'lodash';
import appStore from '@stores/app';
import {
    deleteDocument,
    deleteFileStorageById,
    newTempDocID,
    saveImageToStorageById,
    setDocument,
    updateDocument
} from '@lib/firebaseHandler';

const collection = 'goals';

export const addGoal = async params => {
    if (_.isNil(params.id)) {
        const tempId = newTempDocID(collection);
        params.id = tempId.id;
    }

    if (params.updatedImage && params.image) {
        delete params.updatedImage;
        const imageUrl = await saveImageToStorageById(params, collection);
        params.image = imageUrl;
    }

    if (!params.order) {
        params.order = appStore.goals.length + 1;
    }

    await setDocument(params, collection);
};

export const deleteGoal = async (params, goals) => {
    await deleteDocument(params, collection);
    if (params.image) {
        await deleteFileStorageById(params, collection);
    }
    const remainingGoals = _.chain(goals)
        .filter(goal => goal.id !== params.id)
        .map((goal, i) => ({ ...goal, order: i }))
        .value();

    saveSortedGoals(remainingGoals);
};

export const saveSortedGoals = async params => {
    for (let i = 0; i < params.length; i++) {
        await updateDocument(params[i], collection);
    }
};
