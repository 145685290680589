import React from 'react';

import { Select } from 'antd';

const { Option } = Select;

function EmSelect(props) {
    const { handleChange, values, ...rest } = props;
    return (
        <Select showSearch style={{ width: 300 }} onChange={handleChange} optionFilterProp={'label'} {...rest}>
            {values?.map(value => (
                <Option
                    label={value.label || value.name}
                    value={value.id}
                    disabled={value.disabled}
                    key={value.id || value.name || value.value}
                >
                    {value.name || value.label || value.id}
                </Option>
            ))}
        </Select>
    );
}

export { EmSelect };
