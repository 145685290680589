import React, { Component } from 'react';

import _ from 'lodash';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import ModalWrapper from '@components/ModalWrapper';
import OrderListItems from '@components/OrderListItems/OrderListItems';
import StoriesTagsEdit from '@screens/Content/Sessions/StoriesTags/StoriesTagsEdit';
import { Button, Col, Divider, Row, Select } from 'antd';

const { Option } = Select;

@observer
class SessionsStoriesTags extends Component {
    @observable storiesTags = this.props.session.storiesTags || [];

    handleListChange = result => {
        this.storiesTags = result.map((item, index) => ({
            id: item,
            order: item.order ? item.order : index
        }));
    };

    handleOrderChange = result => {
        this.storiesTags = result;
    };

    onModalOk = () => {
        const data = this.storiesTags.map(({ id, order }) => ({ id, order }));
        this.props.handleChange({ storiesTags: data });
        this.props.closeModal();
    };

    selectStoriesTags = () => {
        const defaultValues = this.storiesTags.map(item => {
            return item.id ? item.id : item;
        });
        return (
            <>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    defaultValue={defaultValues}
                    onChange={this.handleListChange}
                >
                    {appStore.storiesTags.map(item => {
                        return (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        );
                    })}
                </Select>
            </>
        );
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        const namedStoriesTags = this.storiesTags.map(item => {
            const id = item.id ? item.id : item;
            const itemObject = item.id ? item : {};
            const { name } = _.find(appStore.storiesTags, { id });
            return { ...itemObject, id, name };
        });

        return (
            <>
                <Row gutter={24}>
                    <Col span={16}>
                        <h5>Choose Story Tags</h5>
                        {this.selectStoriesTags()}
                        <h5 style={{ paddingTop: 10 }}>Add new Story Tag</h5>
                        <StoriesTagsEdit
                            textButton={'New Story Tag'}
                            title={'Add New Tag'}
                            style={{ paddingTop: 10 }}
                        />
                    </Col>
                    <Col span={8}>
                        <h5>Order</h5>
                        {this.storiesTags.length > 0 && (
                            <OrderListItems isContent items={namedStoriesTags} onChange={this.handleOrderChange} />
                        )}
                    </Col>
                </Row>
                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" loading={this.busy} onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </>
        );
    }
}

export default ModalWrapper(SessionsStoriesTags);
