import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Input } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';

import _ from 'lodash';
import FormBuilder from '@lib/FormBuilder';
import ImageUpload from '@components/ImageUpload/ImageUpload';
import ModalWrapper from '@components/ModalWrapper';
import { addGoal } from '@stores/middleware/goals';

const formItemLayout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 20
    }
};

@observer
class GoalsEdit extends Component {
    @observable goal = this.props.content || {};
    @observable busy = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get goalsMeta() {
        const disabled = !!this.props.content?.id;
        return {
            formItemLayout,
            elements: [
                {
                    key: 'id',
                    initialValue: this.goal.id,
                    render: () => {
                        return (
                            <Form.Item key={'id'} {...formItemLayout} label="ID" required>
                                <Input
                                    placeholder={'Use initials of Goal Name for id. Ej: "Love Yourself" = "ly"'}
                                    onChange={this.handleId}
                                    defaultValue={this.goal.id}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        );
                    }
                },
                {
                    key: 'name',
                    label: 'Name',
                    widget: Input,
                    widgetProps: { rows: 4 },
                    required: true,
                    initialValue: this.goal.name
                }
            ]
        };
    }

    handleId = e => {
        this.goal.id = e.target.value;
    };

    setImage = image => {
        this.goal.image = image;
        this.goal.updatedImage = true;
    };

    onModalOk = async () => {
        this.props.form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return false;
            }
            _.assign(this.goal, values);
            this.busy = true;
            await addGoal(this.goal);
            this.busy = false;
            this.props.closeModal();
        });
    };

    render() {
        return (
            <>
                <Form>
                    <FormBuilder meta={this.goalsMeta} form={this.props.form} />
                    <Form.Item {...formItemLayout} label="Image">
                        <ImageUpload image={this.goal.image} setImage={this.setImage} />
                        <span>
                            <strong>Remember:</strong> Image size has to be 210x210
                        </span>
                    </Form.Item>
                </Form>
                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" loading={this.busy} onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </>
        );
    }
}

export default ModalWrapper(Form.create()(GoalsEdit));
