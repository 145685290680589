import React from 'react';
import { Divider } from 'antd';

const defaultOptions = [
    {
        label: 'Location',
        key: 'location',
        disabled: true,
        formItemProps: {
            hidden: true //TODO set to true after testing
        }
    },
    {
        label: 'Id',
        key: 'id',
        disabled: true,
        formItemProps: {
            hidden: true //TODO set to true after testing
        }
    },
    {
        label: 'Order',
        key: 'order',
        disabled: true,
        formItemProps: {
            hidden: true //TODO set to true after testing
        }
    },

    {
        label: 'Name',
        key: 'name',
        placeholder: 'Widget name (not visible in app)',
        rules: [{ required: true, message: 'We need a name for this widget' }]
    },
    {
        label: 'Active',
        key: 'active',
        widget: 'switch'
    }
];

const definedComponents = [
    // legacy
    { componentType: 'SurveyTestCards', advanced: true, options: defaultOptions },
    { componentType: 'TodayHabits', advanced: true, options: defaultOptions },
    { componentType: 'ReferralInvitesBanner', advanced: true, options: defaultOptions },
    { componentType: 'TodayStories', advanced: true, options: defaultOptions },
    { componentType: 'TodaySessions', advanced: true, options: defaultOptions },
    { componentType: 'TodayCategories', advanced: true, options: defaultOptions },
    { componentType: 'SessionReferralCardWide', advanced: true, options: defaultOptions },
    { componentType: 'WidgetSeparator', advanced: false, options: defaultOptions },

    //new today screen 21.07.2021
    {
        componentType: 'CustomQuizBanner',
        advanced: false,
        options: [
            ...defaultOptions,
            {
                label: 'Web Quiz ID',
                key: 'webQuizId',
                id: 'webQuizId'
            }
        ]
    },
    { componentType: 'TodayGettingStarted', options: defaultOptions },
    {
        componentType: 'CollectionsCarrousel',
        advanced: false,
        options: [
            ...defaultOptions,
            {
                label: 'Title',
                key: 'title',
                id: 'title'
            },
            {
                label: 'Subtitle',
                key: 'subtitle',
                id: 'subtitle'
            },
            {
                label: 'Collections',
                groupType: 'collections',
                widget: 'groupsSelect',
                widgetProps: { mode: 'multiple' },
                key: 'collections',
                id: 'collections',
                item: 'select'
            },
            {
                label: 'Last Item',
                key: 'lastitemSection',
                render() {
                    return <Divider orientation="left">Last Item Card</Divider>;
                }
            },

            {
                label: 'CTA',
                key: 'lastItem.cta'
            },
            {
                label: 'Text',
                key: 'lastItem.text',
                item: 'textarea'
            }
            // to add lastItem.link (select)
        ]
    },
    {
        componentType: 'TodayHeader',
        options: [
            ...defaultOptions,
            {
                label: 'Messages',
                key: 'messagesSection',
                render() {
                    return <Divider orientation="left">Messages (one will be picked random)</Divider>;
                }
            },

            {
                label: 'New message',
                key: 'messages',
                placeholder: 'Please add your messages, one on a line',
                widget: 'multilineList'
                // rules: [{ required: true, message: 'Please add a least a message' }]
            }
        ]
    },
    {
        componentType: 'StoriesCarousel',
        options: [
            ...defaultOptions,
            {
                label: 'Title',
                key: 'title',
                id: 'title'
            },
            {
                label: 'Subtitle',
                key: 'subtitle',
                id: 'subtitle'
            },
            {
                label: 'Stories',
                key: 'stories', // TODO unify this to story, but we might need to keep the back compatibility
                id: 'stories', // TODO unify this to story, but we might need to keep the back compatibility

                //Should be fixed in mobileapp in
                // const TodayNarrowCarrousel = props => {
                //     const { stories: sessionIds } = props?.data || {};

                widget: 'sessionSelect',
                widgetProps: { mode: 'multiple', contentType: 'allStories' },
                valuesType: 'allStories' // still not decided
            },
            {
                label: 'Last Item',
                key: 'lastitemSection',
                render() {
                    return <Divider orientation="left">Last Item Card</Divider>;
                }
            },

            {
                label: 'CTA',
                key: 'lastItem.cta'
            },
            {
                label: 'Text',
                key: 'lastItem.text',
                item: 'textarea'
            }
            // to add lastItem.link (select)
        ]
    },
    {
        componentType: 'SessionsCarousel',
        options: [
            ...defaultOptions,
            {
                label: 'Sessions',
                key: 'sessions',
                id: 'sessions',
                widget: 'sessionSelect',
                widgetProps: { mode: 'multiple', contentType: 'allWellbeing' },
                valuesType: 'allWellbeing' // still not decided
            }
        ]
    },
    {
        componentType: 'WildCard',
        options: [
            ...defaultOptions,
            {
                required: true,
                label: 'Title',
                key: 'title',
                id: 'title'
            },
            {
                label: 'Subtitle',
                key: 'subtitle',
                id: 'subtitle'
            },
            {
                required: true,
                label: 'CTA',
                key: 'cta',
                id: 'cta'
            },
            {
                required: true,
                label: 'Link',
                key: 'link',
                id: 'link'
            }
        ]
    },
    {
        componentType: 'StaticBottomBanner',
        options: [
            ...defaultOptions,
            {
                label: 'Static',
                key: 'isStatic',
                initialValue: true,
                disabled: true,
                formItemProps: {
                    hidden: true //TODO set to true after testing
                }
            },
            {
                label: 'Show only for free users',
                key: 'freeUsersOnly',
                widget: 'switch'
            },
            {
                label: 'Can be closed',
                key: 'hasClose',
                widget: 'switch'
            },

            {
                required: true,
                label: 'Title',
                key: 'title',
                id: 'title'
            },
            {
                required: true,
                label: 'CTA',
                key: 'cta',
                id: 'cta'
            },
            {
                required: true,
                label: 'Icon',
                key: 'icon',
                id: 'icon',
                item: 'select',
                widget: 'select',
                options: ['unlock', 'rocket', 'discountYellow', 'discountPink']
            },
            {
                required: true,
                label: 'Type',
                key: 'type',
                id: 'type',
                item: 'select',
                widget: 'select',
                options: [
                    { label: 'External Link', value: 'link' },
                    {
                        label: 'InApp Screen',
                        value: 'deeplink'
                    }
                ]
            },
            {
                required: true,
                label: 'External Link',
                key: 'link',
                id: 'link',
                rules: [
                    {
                        validator: (rule, value, callback) => {
                            return new Promise((resolve, reject) => {
                                if (!value.match(/^(?:https?:\/\/)/)) {
                                    reject(new Error('Please add a valid link (it should start with "https://")'));
                                } else {
                                    resolve();
                                }
                            });
                        }
                    }
                ],

                dependsOn: { key: 'type', value: 'link' }
            },
            {
                required: true,
                label: 'InApp Deeplink',
                key: 'deeplink',
                id: 'deeplink',
                widget: 'select',
                options: [
                    '/offer/yearly3499intro1749',
                    '/offer/yearly3499intro2449',
                    '/offer/yearly3499intro2799',
                    '/offer/yearly3499intro2949',
                    '/offer/yearly3499intro3149'
                ],
                dependsOn: { key: 'type', value: 'deeplink' }
            }
        ]
    },
    {
        componentType: 'ExpertChatCard',
        options: [
            ...defaultOptions,
            {
                required: true,
                label: 'Title',
                key: 'title',
                id: 'title'
            },
            {
                required: true,
                label: 'Subtitle',
                key: 'subtitle',
                id: 'subtitle'
            },
            {
                required: true,
                label: 'CTA',
                key: 'cta',
                id: 'cta'
            }
        ]
    },
    {
        componentType: 'SearchGlobal',
        options: [
            ...defaultOptions,
            {
                required: true,
                label: 'CTA',
                key: 'cta',
                id: 'cta'
            }
        ]
    }
];

export { definedComponents, defaultOptions };
