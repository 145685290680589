import _ from 'lodash';
import appStore from '@stores/app';
import { deleteDocument, newTempDocID, setDocument, updateDocument } from '@lib/firebaseHandler';

const collection = 'storiesFilters';

const deleteStoriesFilters = async params => {
    await deleteDocument(params, collection);
};

const addStoriesFilters = async params => {
    const tempId = newTempDocID(collection);

    if (_.isNil(params.id)) {
        params.id = tempId.id;
    }

    await setDocument(params, collection);
};

const updateOrderStoriesFilters = async storiesFilters => {
    for (let i = 0; i < storiesFilters.length; i++) {
        await updateDocument(storiesFilters[i], collection);
    }
};

export const updateStoriesFilters = (filters, session) => {
    _.forEach(filters, async id => {
        const filterData = _.find(appStore.storiesFilters, { id });
        filterData.sessions = _.uniq([...filterData.sessions, session.id]);
        await updateDocument(filterData, collection);
    });
};

const removeSessionFromStoriesFilter = async (id, session) => {
    const filterData = _.find(appStore.storiesFilters, { id });
    filterData.sessions = _.filter(filterData.sessions, sessions => sessions !== session.id);
    await updateDocument(filterData, collection);
};

export { deleteStoriesFilters, addStoriesFilters, updateOrderStoriesFilters, removeSessionFromStoriesFilter };
