import React, { Component } from 'react';

import {
    BarsOutlined,
    BranchesOutlined,
    ControlOutlined,
    DatabaseOutlined,
    FileOutlined,
    FireOutlined,
    GlobalOutlined,
    LinkOutlined,
    LogoutOutlined,
    MobileOutlined,
    NotificationOutlined,
    RocketOutlined,
    SettingOutlined,
    SkinOutlined,
    SmileOutlined,
    SolutionOutlined,
    SoundOutlined,
    TeamOutlined,
    UserOutlined,
    WomanOutlined
} from '@ant-design/icons';
import { Button, Empty, Layout, Menu } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import _ from 'lodash';

import { Link, Route } from 'react-router-dom';

import appStore from '../Stores/app';

import AppSettingsScreen from '../Screens/Content/Admin';
import CategoriesScreen from '../Screens/Content/Categories';
import config from '../lib/config.json';
import ExtraInfoScreen from '../Screens/Content/ExtraInfo';
import GrowthScreen from '../Screens/Content/Growth';
import PublishScreen from '../Screens/Content/Publish';
import SessionsScreen from '../Screens/Content/Sessions';
import UsersScreen from '../Screens/Content/Users';
import { ContentConfigScreen } from './Content/ContentScreens/config';
import { DynamicContentView } from '../Screens/Content/App/DynamicContent';
import { EmSwitch } from '@components/EmSwitch';
import { FeatureFlagsView } from './Content/App/FeatureFlags/FeatureFlagsView';
import { PartnersScreen } from './Content/Growth/Partners';
import { ReferralsScreen } from './Content/Growth/Referrals';
import { StoriesScreen } from './Content/ContentScreens/stories';
import { WellbeingScreen } from './Content/ContentScreens/wellbeing';

const { target } = config;

const headerColor = target === 'release' ? '#fa7268' : '#e4e6ea';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

@observer
class ContentView extends Component {
    @observable selected;
    handleChange = value => {
        this.selected = value;
    };

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        return <Empty />;
        // return (
        //     <Fragment>
        //         <Select
        //             placeholder="Select one or more"
        //             style={{ width: 400 }}
        //             onChange={this.handleChange}
        //             mode="multiple"
        //         >
        //             <OptGroup label="Manager">
        //                 <Option value="jack">Jack</Option>
        //                 <Option value="lucy">Lucy</Option>
        //             </OptGroup>
        //             <OptGroup label="Engineer">
        //                 <Option value="Yiminghe">Yiminghe</Option>
        //             </OptGroup>
        //         </Select>
        //         <p>{this.selected}</p>
        //         <pre>{JSON.stringify(this.props, null, 2)}</pre>
        //     </Fragment>
        // );
    }
}

const routesConfig = [
    // { // OLD admin, to remove once we are sure all works ok
    //     group: 'Content',
    //     title: 'Structure',
    //     icon: <BarsOutlined />,
    //     pathname: '/main/categories',
    //     component: CategoriesScreen
    // },
    {
        group: 'Content',
        title: 'Wellbeing',
        icon: <WomanOutlined />,
        pathname: '/main/wellbeing',
        component: WellbeingScreen
    },
    {
        group: 'Content',
        title: 'Stories',
        icon: <FireOutlined />,
        pathname: '/main/stories',
        component: StoriesScreen
    },
    // {// OLD admin, to remove once we are sure all works ok
    //     group: 'Content',
    //     title: 'Sessions',
    //     icon: <SoundOutlined />,
    //     pathname: '/main/sessions',
    //     component: SessionsScreen
    // },
    {
        group: 'Content',
        title: 'Dynamic Content',
        icon: <DatabaseOutlined />,
        pathname: '/main/dynamicContent',
        component: DynamicContentView
    },
    {
        group: 'Content',
        title: 'Content Config',
        icon: <ControlOutlined />,
        pathname: '/main/contentconfig',
        component: ContentConfigScreen
    },
    {
        group: 'Content',
        title: 'Publish',
        icon: <RocketOutlined />,
        pathname: '/main/publish',
        component: PublishScreen
    },
    // // { group: 'Content', title: 'Tips', icon: 'exclamation-circle', pathname: '/main/tips', component: ContentView },
    { group: 'Users', title: 'All users', icon: <UserOutlined />, pathname: '/main/users', component: UsersScreen },
    // {
    //     group: 'Users',
    //     title: 'Notifications',
    //     icon: <NotificationOutlined />,
    //     pathname: '/main/notifications',
    //     component: ContentView
    // },
    {
        group: 'Growth',
        title: 'Deep Linking',
        icon: <LinkOutlined />,
        pathname: '/main/deeplinking',
        component: GrowthScreen
    },
    {
        group: 'Growth',
        title: 'Referrals',
        icon: <BranchesOutlined />,
        pathname: '/main/referrals',
        component: ReferralsScreen
    },
    {
        group: 'Growth',
        title: 'Partners',
        icon: <SkinOutlined />,
        pathname: '/main/partners',
        component: PartnersScreen
    },
    // {
    //     group: 'Web',
    //     title: 'Landing pages',
    //     icon: <GlobalOutlined />,
    //     pathname: '/main/landingPages',
    //     component: ContentView
    // },
    {
        group: 'App',
        title: 'Feature Flags',
        icon: <RocketOutlined />,
        pathname: '/main/features',
        component: FeatureFlagsView
    },
    {
        group: 'App',
        title: 'App Settings',
        icon: <MobileOutlined />,
        pathname: '/main/appSettings',
        component: AppSettingsScreen
    }
    // {
    //     group: 'App',//Moved in App Settigns, to remove once we are sure all works ok
    //     title: 'Dynamic Content',
    //     icon: <DatabaseOutlined />,
    //     pathname: '/main/dynamicContent',
    //     component: DynamicContentView
    // },
    // {
    //     group: 'App',// Moved in App Settings, to remove once we are sure all works ok
    //     title: 'Extra pages',
    //     icon: <FileOutlined />,
    //     pathname: '/main/extrainfo',
    //     component: ExtraInfoScreen
    // }

    // { group: 'Admin', title: 'Team', icon: <TeamOutlined />, pathname: '/main/team', component: ContentView },
    // {
    //     group: 'Admin',
    //     title: 'Control Panel',
    //     icon: <SettingOutlined />,
    //     pathname: '/main/controlPanel',
    //     component: ContentView
    // },
    // {
    //     group: 'Groups',// Moved up in Content, to remove once we are sure all works ok
    //     title: 'Wellbeing',
    //     icon: <WomanOutlined />,
    //     pathname: '/main/wellbeing',
    //     component: WellbeingScreen
    // },
    // {
    //     group: 'Groups',// Moved up in Content, to remove once we are sure all works ok
    //     title: 'Stories',
    //     icon: <FireOutlined />,
    //     pathname: '/main/stories',
    //     component: StoriesScreen
    // },
    // {
    //     group: 'Groups',// Moved up in Content, to remove once we are sure all works ok
    //     title: 'Content Config',
    //     icon: <DatabaseOutlined />,
    //     pathname: '/main/contentconfig',
    //     component: ContentConfigScreen
    // }
];

class SideMenu extends Component {
    render() {
        const selectedKeys = [this.props.location?.pathname];
        const currentRoute = _.find(routesConfig, { pathname: this.props.location?.pathname });
        const openKeys = currentRoute ? [currentRoute.group] : [];

        const routesAdvanced = appStore.advancedMode ? routesConfig : _.filter(routesConfig, route => !route.advanced);
        const menuItems = _.groupBy(routesAdvanced, 'group');

        return (
            <Layout style={{ height: '100vh' }}>
                <Header style={{ backgroundColor: `${headerColor}`, paddingLeft: 16 }}>
                    <Route
                        render={({ history }) => {
                            return (
                                <Button
                                    ghost
                                    size="large"
                                    type="danger"
                                    icon={<LogoutOutlined />}
                                    onClick={() => {
                                        appStore.logOut();
                                        history.push('/login');
                                    }}
                                >
                                    Log out
                                </Button>
                            );
                        }}
                    />
                </Header>
                <Content style={{ backgroundColor: '#fff' }}>
                    <Menu
                        style={{ height: '100%' }}
                        selectedKeys={selectedKeys}
                        defaultOpenKeys={openKeys}
                        mode="inline"
                        // theme="dark"
                    >
                        {_.map(menuItems, (item, key) => {
                            return (
                                <SubMenu key={key} title={key}>
                                    {item.map(subitem => {
                                        return (
                                            <Menu.Item key={subitem.pathname}>
                                                <Link to={subitem.pathname}>
                                                    {subitem.icon}
                                                    <span>{subitem.title}</span>
                                                </Link>
                                            </Menu.Item>
                                        );
                                    })}
                                </SubMenu>
                            );
                        })}
                    </Menu>
                </Content>
                {/*
                <Footer style={{ paddingLeft: 16, backgroundColor: '#fff' }}>
                    <Route
                        render={({ history }) => (
                            <Button
                                ghost
                                size="large"
                                type="danger"
                                icon="logout"
                                onClick={() => {
                                    history.push('/login');
                                }}
                            >
                                Log out
                            </Button>
                        )}
                    />
                </Footer>
                */}
            </Layout>
        );
    }
}

@observer
class Main extends Component {
    @observable headerTitle = '';
    @observable siderCollapsed = false;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {}

    render() {
        const routes = appStore.advancedMode ? routesConfig : _.filter(routesConfig, route => !route.advanced);

        return (
            <Layout style={{ height: '100vh' }}>
                <Sider width={250}>
                    <Layout style={{ height: '100vh' }}>
                        <SideMenu location={this.props.location} />
                    </Layout>
                </Sider>

                <Layout>
                    <Header
                        style={{
                            backgroundColor: `${headerColor}`,
                            paddingLeft: 16,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Route
                            render={({ location }) => {
                                const currentRoute = _.find(routes, { pathname: location.pathname });
                                return <h3>{currentRoute.title}</h3>;
                            }}
                        />
                        <div>
                            <span style={{ paddingRight: 10 }}>Advanced Mode:</span>
                            <EmSwitch
                                onChange={value => (appStore.advancedMode = value)}
                                checked={appStore.advancedMode}
                            />
                        </div>
                    </Header>
                    <Content style={{ padding: 20, backgroundColor: '#fff' }}>
                        <Layout style={{ backgroundColor: '#fff' }}>
                            {routesConfig.map(route => {
                                const Comp = route.component;
                                return (
                                    <Route
                                        key={route.pathname}
                                        path={route.pathname}
                                        render={props => {
                                            return <Comp {...props} data={route} />;
                                        }}
                                    />
                                );
                            })}
                        </Layout>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Main;
