import React, { Component } from 'react';

import '@ant-design/compatible/assets/index.css';
import _ from 'lodash';
import { Button, Divider, Input } from 'antd';
import { computed, makeObservable, observable } from 'mobx';
import { Form } from '@ant-design/compatible';
import { observer } from 'mobx-react';

import appStore from '@stores/app';
import FormBuilder from '@lib/FormBuilder';
import ImageCropUpload from '@components/ImageUpload/ImageCropUpload';
import ModalWrapper from '@components/ModalWrapper';
import notify from '@lib/notifications';
import { authorsImages } from '@lib/constants';
import { newTempDocID } from '@lib/firebaseHandler';

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 20
    }
};

@observer
class AuthorEdit extends Component {
    @observable busy = false;
    @observable author = this.props.author || {};

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed get authorMeta() {
        return {
            formItemLayout,

            elements: [
                {
                    key: 'name',
                    label: 'Name',
                    widget: Input,
                    required: true,
                    initialValue: this.author.name,
                    rules: [
                        {
                            validator: (r, value, cb) => {
                                const nodupe = _.isEmpty(
                                    _.pickBy(appStore.authors, item => {
                                        return item.name === value && this.author.id !== item.id;
                                    })
                                );

                                cb(nodupe ? undefined : false);
                            },
                            message: 'Duplicate name'
                        }
                    ]
                },
                {
                    key: 'description',
                    label: 'Description',
                    widget: TextArea,
                    widgetProps: { rows: 4 },
                    initialValue: this.author.description
                },
                {
                    key: 'title',
                    label: 'Title',
                    widget: Input,
                    required: true,
                    initialValue: this.author.title
                },
                {
                    key: 'link',
                    label: 'Link',
                    widget: Input,
                    initialValue: this.author.link
                }
            ]
        };
    }

    componentDidMount() {
        if (!this.author.id) {
            const newSession = newTempDocID('authors');
            this.author.id = newSession.id;
        }
    }

    onModalOk = async () => {
        this.busy = true;
        this.props.form.validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return false;
            }

            _.assign(this.author, values);

            this.author.orderName = _.chain(this.author.name).lowerCase().replace(/\s/gi, '').value();

            if (!this.props.edit) {
                try {
                    this.adding = false;
                    await appStore.addAuthor(this.author);
                    this.props.closeModal();
                    notify('success', 'addAuthor');
                } catch (error) {
                    notify('error', 'addAuthor');
                }
            } else {
                try {
                    this.editing = false;
                    await appStore.updateAuthor(this.author);
                    this.props.closeModal();
                    notify('success', 'updateAuthor');
                } catch (error) {
                    notify('error', 'updateAuthor');
                }
            }
        });
        this.busy = false;
    };

    setContent = content => {
        _.assign(this.author, content);
    };

    render() {
        return (
            <>
                <Form>
                    <FormBuilder meta={this.authorMeta} form={this.props.form} />

                    <Form.Item {...formItemLayout} label="Image">
                        <ImageCropUpload imagesFormat={authorsImages} content={this.author} onSave={this.setContent} />
                    </Form.Item>
                </Form>
                <div style={{ textAlign: 'right' }}>
                    <Divider />
                    <Button onClick={this.props.closeModal}>Cancel</Button>
                    <Button type="primary" loading={this.busy} onClick={this.onModalOk} style={{ margin: '0 10px' }}>
                        Save
                    </Button>
                </div>
            </>
        );
    }
}

export default ModalWrapper(Form.create()(AuthorEdit));
