import React from 'react';
import { Button, Divider } from 'antd';
import { observer, useLocalObservable } from 'mobx-react';

import ModalWrapper from '@components/ModalWrapper';
import { SimpleSortList } from '@components/OrderListItems/SimpleSortList';

import { widgetsStore } from '@stores/middleware/widgets';

const WidgetsOrder = observer(({ busy, widgets = [], closeModal }) => {
    const state = useLocalObservable(() => ({
        widgets: [...widgets],
        sortedWidgets: [...widgets]
    }));

    const onModalOk = async () => {
        widgetsStore.updateOrder(state.sortedWidgets);
        closeModal();
    };

    const onChange = result => {
        state.sortedWidgets = result;
    };

    return (
        <>
            <SimpleSortList items={state.widgets} onChange={onChange} orderTag={'order'} />
            <div style={{ textAlign: 'right' }}>
                <Divider />
                <Button onClick={closeModal}>Cancel</Button>
                <Button type="primary" loading={busy} onClick={onModalOk} style={{ margin: '0 10px' }}>
                    Save
                </Button>
            </div>
        </>
    );
});

export default ModalWrapper(WidgetsOrder);
