import _ from 'lodash';
import appStore from '@stores/app';
import { deleteDocument, setDocument, updateDocument } from '@lib/firebaseHandler';
import { transformToID } from '@lib/utils';

const referralCodesCollection = 'referralCodes';
const referralCampaingsCollection = 'referralCampaings';
const referralProductsCollection = 'referralProducts';
const referralEventsCollection = 'referralEvents';

// const referralCampaigns = [
//     {
//         id: 'referral_collaborators_code',
//         name: 'Collaborator (14 days FT)'
//     },
//     {
//         id: 'influencer_ft14',
//         name: 'Influencer (14 days FT)'
//     },
//     {
//         id: 'influencer_iap1999_2999',
//         name: 'Influencer (19,99/29.99€ Offer)'
//     }
// ];

const referralSubscriptionCategories = [
    {
        id: 'subscription',
        name: 'Subscription'
    },
    {
        id: 'friend',
        name: 'Subscription from Friend (user to user)'
    },
    {
        id: 'collaborator',
        name: 'Subscription from Collaborator'
    },
    {
        id: 'influencer',
        name: 'Subscription from Influencer'
    }
];

const userTypes = [
    { id: 'referrer', name: 'Referrer (Owner of the code)' },
    { id: 'referee', name: 'Referee (User who redeems the code)' }
];

//const referralCampaignsIds = referralCampaigns.map(ref => ref.id);

const createReferral = async params => {
    const codeInfo = _.find(appStore.referralCampaings, { referralType: params.referralType });
    const codeData = { ...codeInfo, ...params };
    await setDocument(codeData, referralCodesCollection, params.code);
};

const editReferral = async params => {
    await updateDocument(params, referralCodesCollection, params.code);
};

const referralSwitchActive = async params => {
    await updateDocument({ active: !params.active }, referralCodesCollection, params.code);
};

const createCampaign = async params => {
    if (!params.id) {
        params.id = transformToID(params.name);
    }
    await setDocument(params, referralCampaingsCollection);
};

const editCampaign = async params => {
    await updateDocument(params, referralCampaingsCollection);
};

const deleteCampaign = async params => {
    await deleteDocument(params, referralCampaingsCollection);
};

const createProduct = async params => {
    await setDocument(params, referralProductsCollection);
};

const editProduct = async params => {
    await updateDocument(params, referralProductsCollection);
};

const deleteProduct = async params => {
    await deleteDocument(params, referralProductsCollection);
};

const createEvent = async params => {
    await setDocument(params, referralEventsCollection);
};

const editEvent = async params => {
    await updateDocument(params, referralEventsCollection);
};

const deleteEvent = async params => {
    await deleteDocument(params, referralEventsCollection);
};

export {
    referralSwitchActive,
    createReferral,
    editReferral,
    createProduct,
    editProduct,
    createEvent,
    editEvent,
    referralSubscriptionCategories,
    createCampaign,
    editCampaign,
    userTypes,
    deleteCampaign,
    deleteProduct,
    deleteEvent
};
